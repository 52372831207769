
import React from "react";
import { Button, Title, Paragraph, StackingLayout } from "@nutanix-ui/prism-reactjs";
import Warning from "../../../components/Warning";
import AppUtil from "../../../tools/AppUtil";


const resourceBundle = AppUtil.getI18nJSONResourceBundle();

class VMActionsWarningConfirmationWindow extends React.Component {

  enabelAzureTestWarning = (isAzureSource) => {
    let azure_test_warning = "";
    if (isAzureSource) {
      azure_test_warning = `- WARNING: ${resourceBundle.Warnings.azure_test_migration_warning}`;
    }
    return azure_test_warning;
  }

  render() {
    const {
      showWarningType,
      warningMessage,
      sourceType = "",
      targetType = "",
      selectedSourceTypes = [],
      selectedTargetTypes = []
    } = this.props;
    const isAzureSource = resourceBundle.azure_providers_type.indexOf(sourceType) > -1;
    const isAHVtoAHV = resourceBundle.aos_providers_type.indexOf(sourceType) > -1 && resourceBundle.aos_providers_type.indexOf(targetType) > -1;
    const PossbileDelayMessage = <Paragraph type="primary" className="-no-center"> Please note that triggered operation may take a while to update VMs state on UI.</Paragraph>;
    let enabledVirtualNicHelpTextOption = false;

    if (sourceType !== "") {
      enabledVirtualNicHelpTextOption = resourceBundle.sources_with_virtual_nic_disconnect_option.indexOf(sourceType) === -1;
    }
    if (isAHVtoAHV) {
      enabledVirtualNicHelpTextOption = true;
    }

    selectedSourceTypes.forEach((selectedSourceType, index) => {
      if (resourceBundle.sources_with_virtual_nic_disconnect_option.indexOf(selectedSourceType) === -1) {
        enabledVirtualNicHelpTextOption = true;
      }
      if (resourceBundle.aos_providers_type.indexOf(selectedSourceType) > -1 && resourceBundle.aos_providers_type.indexOf(selectedTargetTypes[index]) > -1) {
        enabledVirtualNicHelpTextOption = true;
      }
    });
    return (
      <div>
        <Warning show={ showWarningType === "Cutover" }>
          <div className="warning-content -no-center">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to cutover selected VMs?</Title>
              <Paragraph type="secondary">
                Once you proceed:
              </Paragraph>
              <Paragraph type="secondary"> - Source VM(s) will shutdown.</Paragraph>
              {
                enabledVirtualNicHelpTextOption
                  ? <Paragraph type="secondary"> - Virtual NICs of Source VMs will be disconnected.</Paragraph>
                  : ""
              }
              <Paragraph type="secondary"> - Each source VM will have a note added with details of the migration.</Paragraph>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() } buttonText="Don't Continue"> Don't Continue</Button>
            <Button id="_uiauto-cutover-continue-button" type="primary" onClick={ () => this.props.performAction("cutover") }> Continue</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Test" }>
          <div className="warning-content -no-center">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to create Test VM(s)?</Title>
              <Paragraph type="secondary"> Once you proceed:</Paragraph>
              <Paragraph type="secondary"> - Source VM(s) will have no impact and will
                not be altered.
              </Paragraph>
              <Paragraph type="secondary"> - On Target, crash consistent latest image of
                source VM(s) will be deployed with name {"<sourcevm> -MoveTest"}.
              </Paragraph>
              <Paragraph type="secondary">{ this.enabelAzureTestWarning(isAzureSource)}</Paragraph>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() } buttonText="Don't Continue"> Don't Continue</Button>
            <Button type="primary" onClick={ () => this.props.performAction("test") }> Continue</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Re-Test" }>
          <div className="warning-content -no-center">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to Recreate Test VM(s)?</Title>
              <Paragraph type="secondary"> Once you proceed:</Paragraph>
              <Paragraph type="secondary"> - Source VM(s) will have no impact and will
                not be altered.</Paragraph>
              <Paragraph type="secondary"> - Test VM(s) already present in the target will
                be removed.</Paragraph>
              <Paragraph type="secondary"> - On Target, crash consistent latest image of
                source VM(s) will be deployed with name {"<sourcevm> -MoveTest"}.
              </Paragraph>
              <Paragraph type="secondary">{ this.enabelAzureTestWarning(isAzureSource)}</Paragraph>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() } buttonText="Don't Continue"> Don't Continue</Button>
            <Button type="primary" onClick={ () => this.props.performAction("retest") }> Continue</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Abort" }>
          <div className="warning-content">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to cancel selected VM migration(s)?</Title>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() } buttonText="Don't Continue">Don't Continue</Button>
            <Button type="destructive" onClick={ () => this.props.performAction("abort") } buttonText="Continue">Continue</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Retry" }>
          <div className="warning-content">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to retry selected VM migration(s)?</Title>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() } buttonText="Don't Continue">Don't Continue</Button>
            <Button type="primary" onClick={ () => this.props.performAction("retry") } buttonText="Continue">Continue</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Remove" }>
          <div className="warning-content">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to remove Test VM(s)?</Title>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() } buttonText="Don't Continue">Don't Continue</Button>
            <Button type="destructive" onClick={ () => this.props.performAction("undotest") } buttonText="Continue">Continue</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Discard" }>
          <div className="warning-content">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to discard selected VM migration(s)?</Title>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() }>Don't Continue</Button>
            <Button type="destructive" onClick={ () => this.props.performAction("discard") } >Continue</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Fail" }
          warningText={ warningMessage }
        >
          <div className="warning-buttons">
            <Button onClick={ () => this.props.closeWarning() }
              type="primary" className="ok-button">OK</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Pause" }>
          <div className="warning-content">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to pause selected VM migration(s)?</Title>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() }>Don't Continue</Button>
            <Button type="destructive" onClick={ () => this.props.performAction("suspend") } >Continue</Button>
          </div>
        </Warning>

        <Warning show={ showWarningType === "Resume" }>
          <div className="warning-content">
            <StackingLayout itemSpacing="15px">
              <Title size="h3">Are you sure you want to resume selected VM migration(s)?</Title>
              { PossbileDelayMessage }
            </StackingLayout>
          </div>
          <div className="warning-buttons">
            <Button type="secondary" onClick={ () => this.props.closeWarning() }>Don't Continue</Button>
            <Button type="destructive" onClick={ () => this.props.performAction("resume") } >Continue</Button>
          </div>
        </Warning>
      </div>
    );
  }

}

export default VMActionsWarningConfirmationWindow;
