import React from "react";
import { FullPageModal, Button, StackingLayout, FlexLayout,
  Input, Alert, StatusIcon, Select, FormItemProvider,
  MultiSelect, Checkbox } from "@nutanix-ui/prism-reactjs";
import TimezoneSelector from "../timezone-selector";
import AppUtil from "../../../tools/AppUtil";
import "./mac-address-and-bypass-settings.less";

const resourceBundle = AppUtil.getI18nJSONResourceBundle();
const SelectWithLabel = FormItemProvider(Select);

class MacAddressAndBypassSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      sourceVMs: props.sourceVMs,
      target: props.target,
      tableSourceVms: props.sourceVMs,
      VMTimeZone: props.VMTimeZone,
      VMDiskCategory: props.DiskCategory,
      VMPriority: props.VMPriority
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      sourceVMs: nextProps.sourceVMs,
      tableSourceVms: nextProps.sourceVMs,
      showRetainMacOption: nextProps.showRetainMacOption,
      VMTimeZone: nextProps.VMTimeZone,
      searchText: nextProps.searchText,
      VMDiskCategory: nextProps.VMDiskCategory,
      VMPriority: nextProps.VMPriority
    });
  }


  onSearch = (e) => {
    const searchText = e.target.value;
    this.searchText = searchText;
    const reg = new RegExp(searchText, "gi");
    const tableSourceVms = this.state.sourceVMs.map((record) => {
      const match = record.VMName.match(reg);
      if (!match) {
        return null;
      }
      return {
        ...record,
        VMName: (
          <span>
            {record.VMName.split(reg).map((text, i) => (
              i > 0 ? [<span className="highlight">{match[0]}</span>, text] : text
            ))}
          </span>
        )
      };
    }).filter(record => !!record);
    this.setState({
      tableSourceVms,
      searchText
    });
  }


  onAdvInputChange = (VMUUID, type, inputValue) => {
    const vms = this.state.sourceVMs.map((vm) => {
      if (vm.UUID === VMUUID) {
        if (["VMPriority", "DiskCategory", "TimeZone"].indexOf(type) > -1) {
          vm[type] = inputValue.value;
        } else if (["VMSize", "InstanceType"].indexOf(type) > -1) {
          vm[type] = inputValue.target.value;
        } else if (["VMPrismCategory"].indexOf(type) > -1) {
          vm[type] = inputValue;
        } else if (["inputProps"].indexOf(type) > -1) {
          vm.inputProps = vm.inputProps || {};
          const { value } = inputValue.currentTarget;
          vm.inputProps.value = value;
        } else {
          vm[type] = !vm[type];
        }
      }
      return vm;
    });
    if (this.searchText && this.searchText !== "") {
      const reg = new RegExp(this.searchText, "gi");
      const tableSourceVms = this.state.sourceVMs.map((record) => {
        const match = record.VMName.match(reg);
        if (!match) {
          return null;
        }
        return {
          ...record,
          VMName: (
            <span>
              {record.VMName.split(reg).map((text, i) => (
                i > 0 ? [<span className="highlight">{match[0]}</span>, text] : text
              ))}
            </span>
          )
        };
      }).filter(record => !!record);
      this.setState({
        tableSourceVms,
        sourceVMs: vms
      });
    } else {
      this.setState({
        tableSourceVms: this.state.sourceVMs,
        sourceVMs: vms
      });
    }
  }

  // Copied from VMPreparation. TODO: Consolidate code later. eg: Move method
  // outside of the component and import it.
  // import MACAddressWarningForESXiTarget from "../../../components/containers/vm-preparation/VMPreparation.jsx"
  MACAddressWarningForESXiTarget = (isESXiTarget) => {
    let warningHTML = "";
    if (isESXiTarget) {
      const titleMsg = resourceBundle.Warnings.esxi_target_retain_mac_warning;
      warningHTML = (
        <StatusIcon
          className="status-tootltip"
          type="warning"
          tooltipProps={ {
            content: titleMsg,
            popupPlacement: "right",
            contentProps: {
              style: {
                maxWidth: 300
              }
            }
          } }
        />
      );
    }

    return warningHTML;
  }


  tableHeaderHelpInfo = (help_info) => {
    return (
      <StatusIcon
        type="question"
        className="-pre"
        tooltipProps={ {
          content: help_info,
          contentProps: {
            style: {
              maxWidth: 300
            }
          }
        } }
      />
    );
  }


  renderFooter() {
    return (
      <Button
        key="submit"
        type="primary"
        size="large"
        onClick={ () => this.props.closeMacAndBypassSettingsModal(
          "save", this.state.sourceVMs) }
      >
        Close
      </Button>
    );
  }

  getFormattedVMPriority = (vmPriority) => {
    return resourceBundle.vm_priority_options.find((priority) => {
      return priority.value === vmPriority;
    });
  }


  render() {
    const {
      visible,
      showRetainMacOption,
      showTimeZoneSettings,
      showInstanceTypeOption,
      showSkipCDRom,
      showMemoryOvercommit,
      showVMSizeOption,
      showDiskCategoryOption,
      disableMemoryOvercommit,
      enableVMPrioritySettings,
      showPCCategorySettings,
      categoriesList,
      categorySelectFilter,
      enableRetainUserDataOption
    } = this.props;
    const DiskCategoryOptions = [
      {
        value: "Premium_SSD",
        title: "Premium SSD",
        key: "1",
        label: "Premium SSD"
      },
      {
        value: "Standard_HDD",
        title: "Standard HDD",
        key: "2",
        label: "Standard HDD"
      },
      {
        value: "Standard_SSD",
        title: "Standard SSD",
        key: "3",
        label: "Standard SSD"
      },
      {
        value: "Ultra_SSD",
        title: "Ultra SSD",
        key: "4",
        label: "Ultra SSD"
      }
    ];

    if (!visible) {
      return null;
    }

    const {
      enable_memory_overcommit_label,
      skip_cdrom_on_target_vm
    } = resourceBundle.VM_Preparation;
    const {
      enable_memory_overcommit_message,
      skip_cdrom_on_target_vm_help_info
    } = resourceBundle.Information;
    const isESXiTarget = this.props.checkESXiTarget();

    return (
      <FullPageModal
        visible={ visible }
        title={ resourceBundle.VM_Preparation.manage_settings_for_individual_vms }
        className="manage-vm-preparation"
        maskClosable={ false }
        onClose={ () => this.props.closeMacAndBypassSettingsModal("cancel") }
        keyboard={ false }
      >
        <FlexLayout>
          <StackingLayout>
            {
              showInstanceTypeOption
                ? <Alert
                  type="warning"
                  showCloseIcon={ false }
                  message={ resourceBundle.Information.instanceType_optional_field_help_info }
                /> : ""
            }
            <StackingLayout padding="20px">
              <div className="search-input">
                <Input
                  value={ this.state.searchText }
                  onChange={ this.onSearch }
                  placeholder="Search by VM Name, e.g. VM Apache"
                />
              </div>
              <div className="ntnx ntnx-table" data-border="true">
                <div className="table-main-section">
                  <div className="table-header">
                    <table>
                      <thead>
                        <tr>
                          <th className="small-column-vm-name">
                            VM Name
                          </th>

                          { showRetainMacOption &&
                            <th className="small-column-vm-name">
                              { resourceBundle.Common.retain_mac_address }
                              { this.MACAddressWarningForESXiTarget(isESXiTarget) }
                            </th>
                          }

                          { enableRetainUserDataOption &&
                            <th className="small-column-vm-name">
                              { resourceBundle.Information.retain_user_data_label }
                              { this.tableHeaderHelpInfo(resourceBundle.Information.retain_user_data_helptext) }
                            </th>
                          }

                          { showMemoryOvercommit &&
                            <th className="small-column-vm-name">
                              { enable_memory_overcommit_label }
                              { this.tableHeaderHelpInfo(enable_memory_overcommit_message) }
                            </th>
                          }
                          { showSkipCDRom &&
                            <th className="small-column-vm-name">
                              { skip_cdrom_on_target_vm }
                              { this.tableHeaderHelpInfo(
                                skip_cdrom_on_target_vm_help_info
                              )}
                            </th>
                          }

                          { showInstanceTypeOption &&
                            <th className="small-column-vm-name">
                              Instance Type
                            </th>
                          }

                          { showVMSizeOption &&
                            <th className="small-column-vm-name">
                              VM Size
                            </th>
                          }

                          { showDiskCategoryOption &&
                            <th className="small-column-vm-name">
                              Disk Category
                            </th>
                          }

                          { enableVMPrioritySettings &&
                            <th className="small-column-vm-name">
                              VM Priority
                            </th>
                          }

                          {
                            showTimeZoneSettings
                              ? <th className="small-column-vm-name">
                              Timezone Settings
                              </th> : ""
                          }

                          {
                            showPCCategorySettings
                              ? <th className="small-column-vm-name">
                              Category Settings
                              </th> : ""
                          }

                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="table-body">
                    <table>
                      <tbody>
                        { this.state.tableSourceVms.map((vm, index) => {
                          const {
                            TimeZone,
                            DiskCategory,
                            VMPriority,
                            VMPrismCategory,
                            inputProps = {}
                          } = vm;
                          if (showTimeZoneSettings) {
                            vm.inputProps = {
                              value: inputProps.value || "",
                              appearance: "borderless",
                              onChange: (event) => this.onAdvInputChange(vm.UUID, "inputProps", event)
                            };
                          }
                          const VMLevelTimeZone = TimeZone;
                          const VMLevelDiskCategory = DiskCategory !== "Premium_SSD" ? DiskCategory : this.state.VMDiskCategory;
                          let selectedDiskCategory = {};
                          if (showDiskCategoryOption && vm.DiskCategory) {
                            selectedDiskCategory = DiskCategoryOptions.find(disk => disk.value === vm.DiskCategory);
                          }
                          const VMLevelPriority = VMPriority !== "Medium" ? VMPriority : this.state.VMPriority;
                          const defaultVMLevelPriority = this.getFormattedVMPriority(VMLevelPriority);
                          let selectedVMPriority = null;
                          if (vm.VMPriority) {
                            selectedVMPriority = this.getFormattedVMPriority(vm.VMPriority);
                          }
                          return (
                            <tr data-id={ vm.UUID } key={ index }>
                              <td className="title">
                                <span title={ vm.VMName }>
                                  { vm.VMName }
                                </span>
                              </td>

                              { showRetainMacOption &&
                                <td>
                                  <Checkbox
                                    className={ `_uiauto-settings-vm-retain-mac-${vm.VMName}` }
                                    onChange={ () => this.onAdvInputChange(vm.UUID, "RetainMacAddress") }
                                    checked={ vm.RetainMacAddress }
                                  />
                                </td>
                              }
                              { enableRetainUserDataOption &&
                                <td>
                                  <Checkbox
                                    className={ `_uiauto-settings-vm-retain-user-data-${vm.VMName}` }
                                    onChange={ () => this.onAdvInputChange(vm.UUID, "RetainUserData") }
                                    checked={ vm.RetainUserData }
                                  />
                                </td>
                              }

                              { showMemoryOvercommit &&
                                <td>
                                  <Checkbox
                                    disabled={ disableMemoryOvercommit }
                                    className={ `_uiauto-settings-vm-memory-overcommit-${vm.VMName}` }
                                    onChange={ () => this.onAdvInputChange(vm.UUID, "EnableMemoryOvercommit") }
                                    checked={ vm.EnableMemoryOvercommit }
                                  />
                                </td>
                              }

                              { showSkipCDRom &&
                                <td>
                                  <Checkbox
                                    className={ `_uiauto-settings-vm-skip-cdrom-${vm.VMName}` }
                                    onChange={ () => this.onAdvInputChange(vm.UUID, "SkipCdrom") }
                                    checked={ vm.SkipCdrom }
                                  />
                                </td>
                              }

                              {
                                showInstanceTypeOption &&
                                  <td className="-editable">
                                    <Input
                                      className={ `_uiauto-settings-vm-instance-type-${vm.VMName}` }
                                      placeholder={ resourceBundle.Common.instance_type_placeholder }
                                      value={ vm.InstanceType || "" }
                                      onChange={ (event) => this.onAdvInputChange(vm.UUID, "InstanceType", event) }
                                    />
                                  </td>
                              }

                              {
                                showVMSizeOption &&
                                  <td className="-editable">
                                    <Input
                                      className={ `_uiauto-settings-vm-vm-size-${vm.VMName}` }
                                      placeholder={ resourceBundle.Common.vm_size_placeholder }
                                      value={ vm.VMSize || "" }
                                      onChange={ (event) => this.onAdvInputChange(vm.UUID, "VMSize", event) }
                                    />
                                  </td>
                              }

                              {
                                showDiskCategoryOption &&
                                  <td className="diskcategory-row -editable">
                                    <SelectWithLabel
                                      id={ `_uiauto-settings-vm-disk-category-${vm.VMName}` }
                                      selectedRow={ selectedDiskCategory }
                                      onSelectedChange={ (event) => this.onAdvInputChange(vm.UUID, "DiskCategory", event) }
                                      rowsData={ DiskCategoryOptions }
                                      placeholder={ VMLevelDiskCategory }
                                      type="borderless"
                                    />
                                  </td>
                              }

                              {
                                enableVMPrioritySettings &&
                                  <td className="-editable">
                                    <SelectWithLabel
                                      id={ `_uiauto-settings-vm-priority-${vm.VMName}` }
                                      rowsData={ resourceBundle.vm_priority_options }
                                      onSelectedChange={ (event) => this.onAdvInputChange(vm.UUID, "VMPriority", event) }
                                      defaultValue={ defaultVMLevelPriority }
                                      selectedRow={ selectedVMPriority }
                                      placeholder="Select Priority"
                                      type="borderless"
                                    />
                                  </td>
                              }

                              {
                                showTimeZoneSettings
                                  ? <td className="timezone-row -editable">
                                    <TimezoneSelector
                                      className={ `_uiauto-settings-vm-timezone-${vm.VMName}` }
                                      onSelectedChange={ (event) => this.onAdvInputChange(vm.UUID, "TimeZone", event) }
                                      selectedVMTimeZone={ VMLevelTimeZone }
                                      value={ vm.TimeZone }
                                      timeZoneSearchText={ vm.inputProps.value }
                                      searchable={ true }
                                      inputProps={ vm.inputProps }
                                      type="borderless"
                                    />
                                  </td> : ""
                              }


                              { showPCCategorySettings
                                ? <td className="timezone-row -editable">
                                  <MultiSelect
                                    id={ `_uiauto-prism-category-${vm.VMName}` }
                                    rowsData={ categoriesList }
                                    onSelectedChange={ (event) => this.onAdvInputChange(vm.UUID, "VMPrismCategory", event) }
                                    selectedRows={ VMPrismCategory }
                                    helpText={ resourceBundle.Information.prism_category_help_info }
                                    strictParsing={ true }
                                    rowFilterer={ categorySelectFilter }
                                    appearance="borderless"
                                    overflowLimit={ 1 }
                                  />
                                </td> : ""

                              }
                            </tr>
                          );
                        })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="table-padding"></div>
            </StackingLayout>
          </StackingLayout>
          <div className="migration-plan-footer">
            <FlexLayout justifyContent="flex-end">
              <Button
                style={ { "margin-right": "20px" } }
                id="_uiauto-close-indivdual-settings"
                onClick={ () => this.props.closeMacAndBypassSettingsModal("cancel") }
              >
                Save
              </Button>
            </FlexLayout>
          </div>
        </FlexLayout>
      </FullPageModal>
    );
  }

}

export default MacAddressAndBypassSettings;
