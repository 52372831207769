import React from "react";
import { FormItemInput, Link, FlexLayout } from "@nutanix-ui/prism-reactjs";
import AzurePermissionPolicyJson from "../permission-policy-json/AzurePermissionPolicyJson.js";

import "../add-update-aws-source/_add-update-aws-source.less";

class AddUpdateAzureProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      togglePasswordLabel: "Show"
    };
  }

  togglePasswordField = () => {
    let labelValue = "";

    if (this.state.togglePasswordLabel === "Show") {
      labelValue = "Hide";
    } else {
      labelValue = "Show";
    }
    this.setState({ togglePasswordLabel: labelValue });
  }

  componentDidMount() {
    this.setState({
      togglePasswordLabel: "Show"
    });
  }


  showAzurePermissionPolicy = () => {
    this.setState({
      showAzurePermissionPolicy: true
    });
  }

  closeAzurePermissionPolicy = () => {
    this.setState({
      showAzurePermissionPolicy: false
    });
  }


  render() {
    const { formBody } = this.props;

    return (
      <div className="azure-form-body">
        <FlexLayout>
          <Link
            id="_uiauto-azure-create-azure-client-id-popup"
            onClick={ this.showAzurePermissionPolicy }
            className="permission-policy-link pull-right">
            Create Azure Client ID/Secret?
          </Link>
        </FlexLayout>

        {
          formBody.map((formItem, index) => {
            let formInputType = formBody.inputType;
            let suffix = null;

            if (formItem.allowPasswordToggle) {
              suffix = (
                <Link
                  id="_uiauto-toggle-azure-client-secret-key"
                  className="toggle-password-suffix"
                  onClick={ this.togglePasswordField }
                >
                  { this.state.togglePasswordLabel }
                </Link>
              );

              if (this.state.togglePasswordLabel === "Show") {
                formInputType = "password";
              } else {
                formInputType = "text";
              }
            }

            return (
              <div
              >
                <FormItemInput
                  id={ formItem.key }
                  label={ formItem.inputTitle }
                  autoFocus={ !index }
                  placeholder={ formItem.placeholder }
                  type={ formInputType }
                  suffix={ suffix }
                  value={ formItem.value }
                  onChange={ (e) => this.props.handleOnInputValueChange(e, index, formBody) }
                />
              </div>
            );
          })
        }

        <AzurePermissionPolicyJson
          visible={ this.state.showAzurePermissionPolicy }
          onCancel={ this.closeAzurePermissionPolicy }
        />
      </div>
    );
  }

}

export default AddUpdateAzureProvider;
