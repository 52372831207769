
import React from "react";
import { Modal, Button, StackingLayout, Tabs,
  Link, Alert, NotificationTrigger } from "@nutanix-ui/prism-reactjs";
import copy from "copy-to-clipboard";
import "./PermissionPolicyJson.less";


const CommonPolicyJson = {
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "VisualEditor0",
      Effect: "Allow",
      Action: [
        "iam:SimulatePrincipalPolicy",
        "iam:GetUser",
        "ec2:*Describe*",
        "ssm:DescribeInstanceInformation",
        "ssm:SendCommand",
        "ssm:GetCommandInvocation",
        "ec2:CreateSnapshots",
        "ec2:DeleteSnapshot",
        "ec2:StopInstances",
        "ec2:StartInstances",
        "ec2:CreateTags",
        "ec2:DeleteTags",
        "ebs:ListSnapshotBlocks",
        "ebs:ListChangedBlocks",
        "ebs:GetSnapshotBlock",
        "ec2:*KeyPair*",
        "ec2:RunInstances",
        "ec2:TerminateInstances",
        "ec2:CreateSecurityGroup",
        "ec2:AuthorizeSecurityGroupEgress",
        "ec2:AuthorizeSecurityGroupIngress",
        "ec2:CreateVolume",
        "ec2:DeleteVolume",
        "ec2:AttachVolume",
        "ec2:DetachVolume",
        "ec2:ModifyInstanceAttribute",
        "ebs:StartSnapshot",
        "ebs:PutSnapshotBlock",
        "ebs:CompleteSnapshot",
        "ec2:RegisterImage",
        "ec2:DeregisterImage"
      ],
      Resource: "*"
    }
  ]
};

const SourcePermissionsJson = {
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "VisualEditor0",
      Effect: "Allow",
      Action: [
        "iam:SimulatePrincipalPolicy",
        "iam:GetUser",
        "ec2:*Describe*",
        "ssm:DescribeInstanceInformation",
        "ssm:SendCommand",
        "ssm:GetCommandInvocation",
        "ec2:CreateSnapshots",
        "ec2:DeleteSnapshot",
        "ec2:StopInstances",
        "ec2:StartInstances",
        "ec2:CreateTags",
        "ec2:DeleteTags",
        "ebs:ListSnapshotBlocks",
        "ebs:ListChangedBlocks",
        "ebs:GetSnapshotBlock"
      ],
      Resource: "*"
    }
  ]
};

const TargetPermissionsJson = {
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "VisualEditor0",
      Effect: "Allow",
      Action: [
        "iam:SimulatePrincipalPolicy",
        "iam:GetUser",
        "ec2:*Describe*",
        "ssm:DescribeInstanceInformation",
        "ec2:*KeyPair*",
        "ec2:RunInstances",
        "ec2:TerminateInstances",
        "ec2:StopInstances",
        "ec2:CreateSecurityGroup",
        "ec2:AuthorizeSecurityGroupEgress",
        "ec2:AuthorizeSecurityGroupIngress",
        "ec2:StartInstances",
        "ec2:CreateVolume",
        "ec2:DeleteVolume",
        "ec2:AttachVolume",
        "ec2:DetachVolume",
        "ec2:CreateTags",
        "ec2:DeleteTags",
        "ec2:ModifyInstanceAttribute",
        "ebs:ListSnapshotBlocks",
        "ebs:StartSnapshot",
        "ebs:PutSnapshotBlock",
        "ebs:CompleteSnapshot",
        "ec2:DeleteSnapshot",
        "ec2:RegisterImage",
        "ec2:DeregisterImage"
      ],
      Resource: "*"
    }
  ]
};

const TabsTitleData = [
  {
    title: "Both",
    key: 1
  },
  {
    title: "Source",
    key: 2
  },
  {
    title: "Target",
    key: 3
  }
];


class AWSPermissionPolicyJson extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1
    };
  }

  copyPolicyJSON = (policyJson) => {
    copy(JSON.stringify(policyJson));
    NotificationTrigger.add({
      type: "success",
      message: "JSON copied to clipboard.",
      autoDismissDelaySecs: 5
    });
  }

  permissionPolicyJsonBlock = (policyJson) => {
    return (
      <StackingLayout padding="20px">
        <pre id="_uiauto-aws-permission-policy-json-text" className="ntnx-dark-example">
          <Link
            id="_uiauto-aws-permission-policy-copy-json"
            onClick={ () => this.copyPolicyJSON(policyJson) }
            className="pull-right">
            Copy JSON
          </Link>
          { JSON.stringify(policyJson, null, 2) }
        </pre>
      </StackingLayout>
    );
  }


  renderTabsContent = () => {
    let TabHTML = "";
    switch (this.state.activeTab) {
      case 1:
        TabHTML = this.permissionPolicyJsonBlock(CommonPolicyJson);
        break;
      case 2:
        TabHTML = this.permissionPolicyJsonBlock(SourcePermissionsJson);
        break;
      case 3:
        TabHTML = this.permissionPolicyJsonBlock(TargetPermissionsJson);
        break;
      default:
        return;
    }

    return TabHTML;
  }


  handleTabClick = (tabKey) => {
    this.setState({ activeTab: tabKey });
  }

  render() {
    return (
      <Modal
        visible={ this.props.visible }
        title="AWS Permission Policy"
        onClose={ this.props.onCancel }
        onOk={ this.props.onCancel }
        width={ 500 }
        className="aws-permission-policy-json"
        footer={
          <div>
            <Button
              id="_uiauto-aws-permission-policy-close"
              key="submit"
              type="primary"
              className="btn-close"
              onClick={ this.props.onCancel }>
              Close
            </Button>
          </div>
        }
      >
        <StackingLayout itemSpacing="10px">
          <Alert
            type="info"
            showCloseIcon={ false }
            message="Following permission policy (JSON format) can be directly added in
            AWS IAM console for the AWS user being provided. This is required
            for successful migration of EC2 instances. Incase of explicit deny then
            you can follow user guide to check the required permission policy"
          />
          <Tabs
            data={ TabsTitleData }
            defaultActiveKey={ 1 }
            onTabClick={ this.handleTabClick }
          />
          { this.renderTabsContent() }
        </StackingLayout>
      </Modal>
    );
  }

}

export default AWSPermissionPolicyJson;
