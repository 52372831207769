import React from "react";
import { Modal, Button, FlexLayout, Separator, VerticalSeparator, Paragraph,
  CloseIcon, Form, Alert, StackingLayout } from "@nutanix-ui/prism-reactjs";
import NTNXFileServerForm from "../ntnx-file-server-form";
import OtherFileServerForm from "../other-file-server-form";
import LoaderModal from "../../presentational/loader-modal/";
import SOURCE from "../../../RestAPI/source";
import AppUtil from "../../../tools/AppUtil";
import AppConfig from "../../../app-config/AppConfig";
import ProviderAPI from "../../../RestAPI/Provider.js";
import "../select-source-type/select-source-type.less";


const rb = AppUtil.getI18nJSONResourceBundle();
const { Source, Provider, Information } = rb;

class AddUpdateFileServer extends React.Component {

  componentWillMount() {
    const {
      selectedFileServerType,
      modalInEditMode,
      selectedEnvUUID
    } = this.props;

    const appSettings = AppConfig.get();
    const filteredSelectEnvOption = Provider.files_type_menu_items;
    const serverBody = this.updateFormItems(this.props);

    this.setState({
      showLoader: false,
      modalInEditMode,
      selectedFileServerType,
      appSettings,
      filteredSelectEnvOption,
      serverBody,
      selectedEnvUUID
    });
  }

  // Clear the Interval if in progress
  componentWillUnmount() {
    clearInterval(this.state.inventorDiscoverInterval);
  }

  updateFormItems = (props) => {
    const { serverPopupBody = {}, selectedFileServerType } = props;
    const { name = "", username = "", ip = "" } = serverPopupBody;
    const popUpFormItems = JSON.parse(
      JSON.stringify(
        selectedFileServerType !== "NTNX_FILES"
          ? rb.Provider.OtherFileFormItems
          : rb.Provider.NTNXFileFormItems
      )
    );

    popUpFormItems[0].value = name;
    popUpFormItems[1].value = ip;
    popUpFormItems[2].value = username;

    return popUpFormItems;
  };


  componentWillReceiveProps(nextProps) {
    const serverBody = this.updateFormItems(nextProps);
    const {
      modalInEditMode,
      selectedFileServerType,
      serverPopupBody,
      selectedEnvUUID
    } = nextProps;

    this.setState({
      modalInEditMode,
      selectedFileServerType,
      serverPopupBody,
      serverBody,
      selectedEnvUUID
    });
  }


  saveFormRef = (Form) => {
    this.form = Form;
  }


  onSelectSourceType = (selectedSource) => {
    this.setState({
      selectedFileServerType: selectedSource.value,
      showWarning: false
    });
  }


  /**
   * Handles the addition or update of a file server based on the provided parameters.
   *
   * @param {Event} e - The event object (click or submit event).
   * @param {boolean} enableAddEnvButton - A boolean flag indicating whether the "Add Environment" button is enabled.
   * @returns {void}
  */
  addUpdateFileServerApi = (e, enableAddEnvButton) => {
    e.preventDefault();
    const {
      showLoader,
      modalInEditMode,
      selectedEnvUUID
    } = this.state;

    if (enableAddEnvButton && !showLoader) {
      let loaderText = "";
      let addUpdateFileServerApiPromise;

      const envFormBody = this.prepareFileServerFormBody();
      if (modalInEditMode && selectedEnvUUID) {
        loaderText = Source.source_update_message;
        envFormBody.UUID = selectedEnvUUID;
        addUpdateFileServerApiPromise = SOURCE.updateSource(envFormBody,
          selectedEnvUUID);
      } else {
        loaderText = Source.source_addition_message;
        addUpdateFileServerApiPromise = SOURCE.addSource(envFormBody);
      }

      // setting submitting form to true this is to avoid multiple clicks
      this.setState({
        showLoader: true,
        loaderText,
        warningText: "",
        showWarning: ""
      });

      addUpdateFileServerApiPromise.then((response) => {
        this.handleAddUpdateEnvSuccess(response);
      })
        .catch((response) => {
          this.handleAddUpdateEnvFailure(response);
        });
    }
  }


  prepareFileServerFormBody = () => {
    const { serverBody, selectedFileServerType } = this.state;
    const { selectedTargetConfig } = this.props;
    const { UUID, TargetUUID } = selectedTargetConfig;

    const providerFormBody = {
      Spec: {
        Name: serverBody[0].value,
        Type: selectedFileServerType === "OTHER_FILES" ? "OTHER_FILES" : "NTNX_FILES",
        FilesAccessInfo: {
          IPorFQDN: serverBody[1].value.trim(),
          Username: serverBody[2].value,
          Password: serverBody[3].value
        },
        OtherFilesAccessInfo: {
          IPorFQDN: serverBody[1].value.trim(),
          Username: serverBody[2].value,
          Password: serverBody[3].value,
          TargetUUID: UUID || TargetUUID
        }
      }
    };

    if (selectedFileServerType === "OTHER_FILES") {
      delete providerFormBody.Spec.FilesAccessInfo;
    } else {
      delete providerFormBody.Spec.OtherFilesAccessInfo;
    }

    return providerFormBody;
  };


  handleAddUpdateEnvSuccess = (response) => {
    if (this.state.modalInEditMode) {
      this.closeEnvironmentModal();
      this.props.refreshSelectedInventory(this.state.selectedEnvUUID);
    } else {
      this.checkInventoryStatus(response.MetaData.UUID);
    }
  }


  checkInventoryStatus = (providerID) => {
    this.setState({
      loaderText: rb.Common.generic_wait_msg
    });

    const checkStatusAndHandle = () => {
      ProviderAPI.getProviderDetails(providerID).then((response) => {
        if (response.MetaData.IsInventoryLoading && !this.state.inventorDiscoverInterval) {
          this.setState({
            inventorDiscoverInterval: setInterval(checkStatusAndHandle, 3000)
          });
        } else {
          this.closeEnvironmentModal();
        }
      });
    };

    checkStatusAndHandle();
  }


  closeEnvironmentModal = () => {
    this.setState({
      showLoader: false,
      loaderText: "",
      closeEnvModal: true
    });
    this.props.onCancel(this.state.Role);
    clearInterval(this.state.inventorDiscoverInterval);
  }


  handleAddUpdateEnvFailure = (errorResponse) => {
    this.setState({
      warningText: errorResponse.Message,
      showLoader: false,
      showWarning: true,
      loaderText: ""
    });
  }


  updateFormData = (key, value) => {
    const { serverBody } = this.state;
    serverBody[key].value = value;

    this.setState({
      serverBody
    });
  }


  showFileServerFormBody = () => {
    const {
      selectedFileServerType,
      serverBody,
      modalInEditMode
    } = this.state;

    if (!selectedFileServerType) {
      return false;
    }

    switch (true) {
      case rb.ntnx_file_server_providers_type.indexOf(selectedFileServerType) > -1:
        return (
          <NTNXFileServerForm
            serverBody={ serverBody }
            modalInEditMode={ modalInEditMode }
            updateFormData={ this.updateFormData }
            openMoveHelpPortal={ this.openMoveHelpPortal }
          />
        );
      case rb.other_file_server_providers_type.indexOf(selectedFileServerType) > -1:
        return (
          <OtherFileServerForm
            serverBody={ serverBody }
            modalInEditMode={ modalInEditMode }
            updateFormData={ this.updateFormData }
          />
        );
      default:
        return false;
    }
  }


  openMoveHelpPortal = () => {
    const selectedFileServerType = this.state.selectedFileServerType;
    let prism_id = rb.help_prism_ids.move_default;
    if (rb.vmware_providers_type.indexOf(selectedFileServerType) > -1) {
      prism_id = rb.help_prism_ids.add_esxi_provider;
    }
    const url = rb.help_url_pre + rb.move_version + rb.help_url_mid + prism_id;
    const win = window.open(url, "_blank");
    win.focus();
  }

  getModalheader = () => {
    const header =
      <Separator separator={ <VerticalSeparator /> }>
        <div onClick={ this.props.onCancel }><CloseIcon /></div>
      </Separator>;
    return header;
  }

  enableAddEnvButton = () => {
    const {
      showLoader = false,
      serverBody = []
    } = this.state;

    const disableAddButton = showLoader && !serverBody.length;
    if (disableAddButton) {
      return disableAddButton;
    }

    const optionalFieldsData = [];
    let enableAddButton = true;
    let isOptionalFieldsHasValue = false;

    for (let i = 0; i < serverBody.length; i++) {
      const { value, required, key } = serverBody[i];

      // Check if the username or password field has some value
      if ((key === "other_file_server_user_name" || key === "other_file_server_password") && value !== "") {
        isOptionalFieldsHasValue = true;
        // Push username or password details in list
        optionalFieldsData.push(value);
      }

      if (required && value === "") {
        enableAddButton = false;
        break;
      }
    }

    if (isOptionalFieldsHasValue && enableAddButton) {
      return optionalFieldsData.length === 2;
    }

    return enableAddButton;
  }

  render() {
    const {
      selectedFileServerType,
      showLoader,
      warningText,
      loaderText,
      showWarning,
      closeEnvModal,
      modalInEditMode
    } = this.state;

    const newEditPrefix = modalInEditMode ? "Edit" : "New";
    let modalTitle = `${newEditPrefix} Target Nutanix File Server`;
    let fileServerHelpText = Information.nx_file_server_help_alert;
    const nxFileServer = selectedFileServerType === "NTNX_FILES";
    if (!nxFileServer) {
      modalTitle = `${newEditPrefix} Source File Server`;
      fileServerHelpText = Information.other_file_server_help_alert;
    }


    const enableAddEnvButton = this.enableAddEnvButton();

    const Footer = (
      <FlexLayout itemSpacing="10px" justifyContent="flex-end">
        <Button
          id="_uiauto-add-environment-cancel-button"
          type="secondary"
          onClick={ this.props.onCancel }
          disabled={ showLoader }>
          Cancel
        </Button>
        <Button
          id="_uiauto-add-environment-add-button"
          key="submit"
          type="primary"
          onClick={ (event) => this.addUpdateFileServerApi(event, enableAddEnvButton) }
          disabled={ !enableAddEnvButton }>
          { modalInEditMode ? "Save" : "Add" }
        </Button>
      </FlexLayout>
    );

    return (
      <div>
        { !closeEnvModal
          ? <Modal
            visible={ true }
            afterClose={ this.props.onCancel }
            title={ modalTitle }
            footer={ Footer }
            contentClassName="ntnx-modal-content"
            maskClosable={ false }
            className="add-source-modal -file-server"
            width={ 550 }
            headerActions={ this.getModalheader() }
          >
            { showWarning &&
              <Alert
                type="error"
                className="error-message _uiauto-add-provider-error-message"
                message={ warningText }
                showCloseIcon={ false }
              />
            }
            <StackingLayout
              padding="20px">
              { !showWarning &&
                <Paragraph>
                  { fileServerHelpText }
                </Paragraph>
              }
              <Form
                itemSpacing="20px"
                onSubmit={ (event) => this.addUpdateFileServerApi(event, enableAddEnvButton) }>
                <div className="esxi-form-body">
                  { this.showFileServerFormBody() }
                </div>
                <input type="submit" className="hidden-submit-button"/>
              </Form>
            </StackingLayout>

            <LoaderModal
              visible={ showLoader }
              loaderDescription={ loaderText }
            />
          </Modal> : ""
        }
      </div>
    );
  }

}

export default AddUpdateFileServer;
