import React from "react";
import { Modal, Button, FormItemInput, FormItemProvider,
  Select, StackingLayout, FlexLayout, TimePicker,
  EditIcon, Title, RemoveIcon, TextLabel, PlusIcon, Link,
  ChevronDownIcon, Dropdown, ButtonGroup, Menu, MenuItem,
  Alert, MultiSelect, Tooltip, QuestionIcon, CloseIcon } from "@nutanix-ui/prism-reactjs";
import moment from "moment";
import MOVE_APPLICATION from "../../../RestAPI/MOVE_APPLICATION.js";
import AppUtil from "../../../tools/AppUtil";
import AppConfig from "../../../app-config/AppConfig";
import "./_CreateEditBandwidthPolicyModal.less";

const MultiSelectWithLabel = FormItemProvider(MultiSelect);
const SelectWithLabel = FormItemProvider(Select);
const resourceBundle = AppUtil.getI18nJSONResourceBundle();
const MaxBWLimit = 100000;
const InvalidDate = "Invalid date";
const DaysCountInWeek = 7;
const FrequencyScheduleTypes = [
  {
    value: "all",
    label: "All Days",
    key: "1"
  },
  {
    value: "custom",
    label: "Specific Duration",
    key: "2"
  }
];
const WeekDays = [
  {
    value: "SUN",
    label: "Sunday",
    id: "1"
  },
  {
    value: "MON",
    label: "Monday",
    id: "2"
  },
  {
    value: "TUE",
    label: "Tuesday",
    id: "3"
  },
  {
    value: "WED",
    label: "Wednesday",
    id: "4"
  },
  {
    value: "THU",
    label: "Thursday",
    id: "5"
  },
  {
    value: "FRI",
    label: "Friday",
    id: "6"
  },
  {
    value: "SAT",
    label: "Saturday",
    id: "7"
  }
];
const PolicyActiveKeyMap = {
  1: true,
  2: false
};

class CreateEditBandwidthPolicyModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingInventory: false,
      showBandWidthModalCurrentStep: "input",
      WindowSchedules:[{
        DaysOfTheWeek: [],
        BwcapMbps: "",
        StartTime: "00:00",
        EndTime: "00:00"
      }],
      policyName: "",
      mainBandwidthValue: "",
      selectedEnvironment: {},
      enableCustomScheduleBlock: false,
      policyDetails: props.policyDetails,
      availableProvidersForSelection: props.availableProvidersForSelection,
      providersInventory: props.availableProvidersForSelection,
      appSettings: AppConfig.get()
    };
  }


  componentWillReceiveProps = (nextProps) => {
    this.setState({
      policyDetails: nextProps.policyDetails,
      providersInventory: nextProps.availableProvidersForSelection,
      availableProvidersForSelection: nextProps.availableProvidersForSelection
    });
  }


  componentDidMount() {
    this.preparePolicyAndEnvironmentInventory();
  }

  getFormattedDaysOfTheWeek = (WindowSchedules) => {
    WindowSchedules.forEach((windowSchedule) => {
      const DaysOfTheWeek = [];
      windowSchedule.DaysOfTheWeek.forEach((day) => {
        WeekDays.forEach((weekDay) => {
          if (weekDay.value === day) {
            DaysOfTheWeek.push(weekDay);
          }
        });
      });
      windowSchedule.DaysOfTheWeek = DaysOfTheWeek;
    });

    return WindowSchedules;
  }

  preparePolicyAndEnvironmentInventory = () => {
    const {
      policyDetails,
      availableProvidersForSelection,
      clearLoaderModal
    } = this.props;

    let WindowSchedules = [{
      DaysOfTheWeek: [],
      BwcapMbps: "",
      StartTime: "00:00",
      EndTime: "00:00"
    }];

    // check for edit policy
    let policyName = "";
    let selectedEnvironment = {};
    let scheduleType;

    if (policyDetails) {
      policyName = policyDetails.policyName;
      selectedEnvironment = availableProvidersForSelection.find((provider) => {
        return policyDetails.sourceUUID === provider.UUID;
      });
      const formattedWindowSchedules = this.getFormattedDaysOfTheWeek(
        policyDetails.WindowSchedules);
      WindowSchedules = Object.assign([], formattedWindowSchedules);
      const totalSchedules = WindowSchedules.length;
      const firstSchedule = WindowSchedules[0];
      const {
        DaysOfTheWeek,
        StartTime,
        EndTime
      } = firstSchedule;

      if (totalSchedules === 1 && (DaysOfTheWeek.length === DaysCountInWeek) &&
        (StartTime === "00:00" && EndTime === "00:00")) {
        scheduleType = "all";
      } else {
        scheduleType = "custom";
      }
    }

    this.setState({
      FailedLoadInventoryMessage: "",
      policyName,
      selectedEnvironment,
      WindowSchedules,
      scheduleType
    }, () => {
      clearLoaderModal();
    });
  }


  createUpdateBandwidthPolicy = (policyJson) => {
    const {
      clearLoaderModal,
      displayLoaderModal,
      closeCreateEditBandwidthPolicyModal,
      policyDetails
    } = this.props;

    const {
      creating_bandwidth_policy,
      updating_bandwidth_policy
    } = resourceBundle.Common;

    let loaderModalMessage = creating_bandwidth_policy;
    if (policyDetails) {
      loaderModalMessage = updating_bandwidth_policy;
    }

    this.setState({
      showPolicyErrorMessage: ""
    }, () => {
      displayLoaderModal(loaderModalMessage);
    });

    let createUpdateBandwidthPolicyPromise;

    if (!policyDetails) {
      createUpdateBandwidthPolicyPromise = MOVE_APPLICATION.createBandwidthPolicy(policyJson);
    } else {
      createUpdateBandwidthPolicyPromise = MOVE_APPLICATION.updateBandwidthPolicy(policyJson, policyDetails.UUID);
    }

    createUpdateBandwidthPolicyPromise.then((response) => {
      clearLoaderModal();
      closeCreateEditBandwidthPolicyModal();
    })
      .catch((response) => {
        this.setState({
          showPolicyErrorMessage: response.Message
        }, () => {
          clearLoaderModal();
        });
      });
  }


  nextStep = (event) => {
    const {
      showBandWidthModalCurrentStep
    } = this.state;

    if (showBandWidthModalCurrentStep === "review") {
      const formattedPolicyData = this.getFormattedPolicyData(event.key);
      this.createUpdateBandwidthPolicy(formattedPolicyData);
    } else {
      this.setState({
        showBandWidthModalCurrentStep: "review"
      });
    }
  }

  getFormatedDaysOfTheWeek = (DaysOfTheWeek) => {
    const formattedDaysOfTheWeek = [];
    DaysOfTheWeek.forEach((day) => {
      formattedDaysOfTheWeek.push(day.value.toUpperCase());
    });

    return formattedDaysOfTheWeek;
  }


  getFormattedWindowSchedules = () => {
    const {
      WindowSchedules
    } = this.state;

    const formattedWindowSchedules = WindowSchedules.map((windowSchedule) => {
      const startTimeSplit = windowSchedule.StartTime.split(":");
      const endTimeSplit = windowSchedule.EndTime.split(":");
      const formatedDaysOfTheWeek = this.getFormatedDaysOfTheWeek(
        windowSchedule.DaysOfTheWeek);
      return {
        StartTimeHours: parseInt(startTimeSplit[0], 10),
        StartTimeMins: parseInt(startTimeSplit[1], 10),
        EndTimeHours: parseInt(endTimeSplit[0], 10),
        EndTimeMins: parseInt(endTimeSplit[1], 10),
        DaysOfTheWeek: formatedDaysOfTheWeek,
        BwcapMbps: parseInt(windowSchedule.BwcapMbps, 10)
      };
    });

    return formattedWindowSchedules;
  }


  getFormattedPolicyData = (key) => {
    const {
      policyName,
      selectedEnvironment
    } = this.state;

    const formattedWindowSchedules = this.getFormattedWindowSchedules();
    const sourceUUID = selectedEnvironment.UUID;

    const formData = {
      Name: policyName.trim(),
      MatchExpression: `SourceUuid=="${sourceUUID}"`,
      IsActive: PolicyActiveKeyMap[key],
      WindowSchedules: formattedWindowSchedules,
      Schedules: []
    };

    return formData;
  }


  backToPreviousStep = () => {
    this.setState({
      showBandWidthModalCurrentStep: "input"
    });
  }


  enableNextButton = () => {
    let disableNextButtonFlag = true;
    const {
      showBandWidthModalCurrentStep,
      policyName,
      selectedEnvironment = {},
      scheduleType,
      WindowSchedules
    } = this.state;

    if (!scheduleType) {
      return disableNextButtonFlag;
    }

    const {
      DaysOfTheWeek,
      StartTime,
      EndTime,
      BwcapMbps
    } = WindowSchedules[0];

    const validBWCapInMbps = BwcapMbps.toString() && parseInt(BwcapMbps, 10) <= MaxBWLimit;

    if (showBandWidthModalCurrentStep === "input" &&
      policyName.trim() && validBWCapInMbps && selectedEnvironment.value) {
      if (scheduleType === "all") {
        disableNextButtonFlag = false;
      } else {
        disableNextButtonFlag = !([StartTime, EndTime].indexOf(InvalidDate) === -1 && (DaysOfTheWeek.length > 0));
      }
    }

    if (showBandWidthModalCurrentStep === "review") {
      disableNextButtonFlag = false;
    }

    return disableNextButtonFlag;
  }


  renderFooter() {
    const {
      showBandWidthModalCurrentStep,
      enableCustomScheduleBlock,
      policyDetails
    } = this.state;

    const cancelButton = (
      <Button
        type="secondary"
        onClick={ this.props.closeCreateEditBandwidthPolicyModal }
      >
        Cancel
      </Button>
    );
    let backButtonHTML = "";
    let saveAndContinueButtonHTML = "";
    const emptyReviewFields = this.enableNextButton();
    const saveOrUpdateText = policyDetails ? "Update" : "Save";
    let nextButtonHTML = (
      <Button
        type="primary"
        onClick={ this.nextStep }
        disabled={ emptyReviewFields || enableCustomScheduleBlock }
      >
        Next
      </Button>
    );
    if (showBandWidthModalCurrentStep === "review") {
      nextButtonHTML = "";
      backButtonHTML = (
        <Button
          type="secondary"
          onClick={ this.backToPreviousStep }
          disabled={ enableCustomScheduleBlock }
        >
          Back
        </Button>
      );

      const buttonMenu = (
        <Menu
          oldMenu={ false }
          className="ntnx-actions-menu"
          onClick={ this.nextStep }
        >
          <MenuItem key="1">{`${saveOrUpdateText} and Activate`}</MenuItem>
          <MenuItem key="2">{`${saveOrUpdateText} Only`}</MenuItem>
        </Menu>
      );

      saveAndContinueButtonHTML = (
        <ButtonGroup
          className="save-and-continue-button-group">
          <Button
            type="primary"
            onClick={ () => this.nextStep({ key:"1" }) }
            disabled={ enableCustomScheduleBlock }>{`${saveOrUpdateText} and Activate`}</Button>
          <Dropdown
            disabled={ enableCustomScheduleBlock }
            popup={ buttonMenu }
          >
            <Button
              type="primary"
              disabled={ enableCustomScheduleBlock }>
              <ChevronDownIcon />
            </Button>
          </Dropdown>
        </ButtonGroup>
      );
    }

    return (<FlexLayout justifyContent="space-between">
      <span>
        { backButtonHTML }
      </span>
      <FlexLayout itemSpacing="10px">
        { cancelButton }
        { nextButtonHTML }
        { saveAndContinueButtonHTML }
      </FlexLayout>
    </FlexLayout>);
  }


  onSetInputValue = (value, property, scheduleIndex) => {
    const {
      WindowSchedules
    } = this.state;

    if (scheduleIndex) {
      WindowSchedules[scheduleIndex][property] = value;
      this.setState({
        WindowSchedules
      });
    } else {
      this.setState({
        [property]: value
      });
    }
  }


  setSourceForPolicy = (selectedEnvironment) => {
    this.setState({
      selectedEnvironment
    });
  }


  setFrequencyScheduleType = (schedule) => {
    const {
      WindowSchedules
    } = this.state;

    const emptySchedule = {
      DaysOfTheWeek: [],
      BwcapMbps: "",
      StartTime: "00:00",
      EndTime: "00:00"
    };

    WindowSchedules[0] = Object.assign({}, emptySchedule);

    if (schedule.value === "all") {
      WindowSchedules.length = 1;
      WindowSchedules[0].DaysOfTheWeek = WeekDays;
    }

    this.setState({
      scheduleType: schedule.value,
      WindowSchedules
    });
  }


  onSelectFrequencyDay = (frequencyDays, index) => {
    const {
      WindowSchedules
    } = this.state;

    WindowSchedules[index].DaysOfTheWeek = frequencyDays;

    this.setState({
      WindowSchedules
    });
  }


  setScheduleTime = (time, timeProperty) => {
    const formattedTime = moment(time, "HH:mm").format("HH:mm");
    this.setState({
      [timeProperty]: formattedTime
    });
  }

  allowNumbersOnly = (e) => {
    const code = e.which || e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }

  getTime(momentObj) {
    return momentObj.format("h:mm A");
  }

  renderInputValue = (timeObj) => {
    const momentObj = moment().set({
      hour: timeObj.hours,
      minute: timeObj.minutes
    });
    return this.getTime(momentObj);
  }


  showCustomFrequencyBlock = () => {
    const {
      scheduleType,
      WindowSchedules
    } = this.state;

    let customFrequencyBlockHTML = "";
    if (scheduleType === "custom") {
      const {
        StartTime,
        EndTime,
        DaysOfTheWeek
      } = WindowSchedules[0];
      customFrequencyBlockHTML = (
        <StackingLayout>
          <FlexLayout itemFlexBasis="100pc"
            className="timePicker-block">
            <TimePicker
              inputProps={ {
                label: "From",
                readOnly: true
              } }
              allowEmpty={ false }
              format12Hours={ true }
              value={ this.getTime(moment(StartTime, "H:mm")) }
              renderInputValue={ this.renderInputValue }
              onChange={ (e) => this.setCustomScheduleTime(e, "StartTime", "0") }
              getPopupContainer={ triggerNode => triggerNode.parentNode }
            />

            <TimePicker
              inputProps={ {
                label: "To",
                readOnly: true
              } }
              allowEmpty={ false }
              format12Hours={ true }
              value={ this.getTime(moment(EndTime, "H:mm")) }
              renderInputValue={ this.renderInputValue }
              onChange={ (e) => this.setCustomScheduleTime(e, "EndTime", "0") }
              getPopupContainer={ triggerNode => triggerNode.parentNode }
            />
          </FlexLayout>
          <FlexLayout itemFlexBasis="100pc">
            <MultiSelectWithLabel
              label="On"
              secondaryLabel="Days of the week"
              placeholder="Select Schedule"
              rowsData={ WeekDays }
              onSelectedChange={ (e) => this.onSelectFrequencyDay(e, "0") }
              selectedRows={ DaysOfTheWeek }
            />
          </FlexLayout>
        </StackingLayout>
      );
    }
    return customFrequencyBlockHTML;
  }


  getBandWidthErrorSuffixData = (bandwidthValue) => {
    return bandwidthValue > MaxBWLimit;
  }


  showInputBlock = (policyErrorMessage) => {
    const {
      providersInventory,
      selectedEnvironment = null,
      scheduleType,
      policyName,
      WindowSchedules,
      appSettings
    } = this.state;

    const BWLimitInMbps = parseInt(WindowSchedules[0].BwcapMbps, 10);
    const bandWidthWithErrorSuffix = this.getBandWidthErrorSuffixData(BWLimitInMbps);
    const selectSourceProps = {
      rowsData:  providersInventory,
      label: "Select Source",
      rowRenderer: (row) => {
        return (
          <FlexLayout
            alignItems="center"
            justifyContent="space-between"
          >
            <div
              className="source-target-name-label"
              title={ row.label }>
              { row.label }
            </div>
            <TextLabel className="source-target-name-sub-title">{ row.subTitle } </TextLabel>
          </FlexLayout>
        );
      }
    };

    let selectedScheduleType = null;
    if (scheduleType) {
      selectedScheduleType = FrequencyScheduleTypes.find((schedule) => {
        return schedule.value === scheduleType;
      });
    }

    const inputBlockHTML = (
      <StackingLayout itemSpacing="20px">
        { policyErrorMessage }
        <FormItemInput
          label="Policy Name"
          name="planName"
          placeholder="Enter Policy Name"
          value={ policyName }
          onChange={ (e) =>
            this.onSetInputValue(e.target.value, "policyName")
          }
          autoFocus
        />
        <SelectWithLabel
          { ...selectSourceProps }
          placeholder="Select Source"
          onSelectedChange={ this.setSourceForPolicy }
          selectedRow={ selectedEnvironment }
        />

        <SelectWithLabel
          label="Frequency"
          selectedRow={ selectedScheduleType }
          placeholder="Select Schedule"
          rowsData={ FrequencyScheduleTypes }
          onSelectedChange={ this.setFrequencyScheduleType }
          helpText={
            `Schedules use the local time zone of the Move Appliance.
            (${appSettings.TimezoneAbbr}, ${appSettings.UTCOffsetHHMM})`
          }
        />

        { this.showCustomFrequencyBlock() }

        <FormItemInput
          type="number"
          onKeyPress={ this.allowNumbersOnly }
          label="Bandwidth Limit"
          placeholder="0"
          value={ WindowSchedules[0].BwcapMbps }
          helpText={ resourceBundle.Information.policy_max_bandwidth_message }
          min="0"
          max={ MaxBWLimit }
          error={ bandWidthWithErrorSuffix }
          onChange={ (e) =>
            this.onSetInputValue(e.target.value, "BwcapMbps", "0")
          }
          suffix={ bandWidthWithErrorSuffix }
        />
      </StackingLayout>
    );

    return inputBlockHTML;
  }


  showReviewBlock = (policyErrorMessage) => {
    const {
      policyName,
      selectedEnvironment,
      editOldSchedule,
      enableCustomScheduleBlock
    } = this.state;

    let newUpdatecustomScheduleBlock = "";

    if (!editOldSchedule && enableCustomScheduleBlock) {
      newUpdatecustomScheduleBlock = this.displayNewUpdateCustomScheduleBlock();
    }

    const reviewBlockStepHTML = (
      <StackingLayout>
        { policyErrorMessage }
        <FlexLayout className="policy-env-tile" itemFlexBasis="100pc">
          <div className="-no-header ntnx ntnx-table">
            <div className="table-main-section">
              <div className="table-body">
                <table>
                  <tbody>
                    <tr>
                      <td><TextLabel> Policy Name </TextLabel></td>
                      <td>
                        { policyName }
                      </td>
                    </tr>
                    <tr>
                      <td><TextLabel> Source </TextLabel></td>
                      <td>
                        { selectedEnvironment.title }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </FlexLayout>

        <div className="schedules-block">
          <Title size="h3">Bandwidth Throttling Schedule(s)</Title>
          <StackingLayout itemSpacing="20px">
            { this.showWindowsSchedule() }
          </StackingLayout>
        </div>
        { newUpdatecustomScheduleBlock }
        { this.showAddNewScheduleBlock() }
      </StackingLayout>
    );

    return reviewBlockStepHTML;
  }


  enableNewUpdateCustomScheduleBlock = (index) => {
    const {
      WindowSchedules
    } = this.state;

    let activeScheduleIndex = "";
    let editOldSchedule = false;
    if (index) {
      activeScheduleIndex = index;
      editOldSchedule = true;
    } else {
      const emptySchedule = {
        DaysOfTheWeek: [],
        BwcapMbps: "",
        StartTime: "00:00",
        EndTime: "00:00"
      };
      activeScheduleIndex = WindowSchedules.length;
      WindowSchedules.push(emptySchedule);
    }

    this.setState({
      enableCustomScheduleBlock: true,
      WindowSchedules,
      activeScheduleIndex,
      editOldSchedule
    });
  }


  showAddNewScheduleBlock = () => {
    const {
      scheduleType,
      enableCustomScheduleBlock
    } = this.state;

    let addNewScheduleBlockHTML = "";

    if (scheduleType !== "all" && !enableCustomScheduleBlock) {
      addNewScheduleBlockHTML = (
        <FlexLayout>
          <Link
            onClick={ () => this.enableNewUpdateCustomScheduleBlock(false) }
            type="with-icon"
            icon={ <PlusIcon size="small"/> }>
          Add New Schedule
          </Link>
        </FlexLayout>
      );
    }

    return addNewScheduleBlockHTML;
  }


  cancelNewUpdateSchedule = () => {
    const {
      WindowSchedules,
      activeScheduleIndex,
      editOldSchedule
    } = this.state;

    if (!editOldSchedule) {
      WindowSchedules.splice(activeScheduleIndex, 1);
    }

    this.setState({
      editOldSchedule: false,
      activeScheduleIndex: "",
      enableCustomScheduleBlock: false
    });
  }


  setCustomScheduleTime = (time, property, scheduleIndex) => {
    const formattedTime = moment(time, "HH:mm A").format("HH:mm A");
    const {
      WindowSchedules
    } = this.state;

    WindowSchedules[scheduleIndex][property] = formattedTime;
    this.setState({
      WindowSchedules
    });
  }


  checkForEnabledNewUpdateCustomSchedule = (scheduleIndex) => {
    const {
      WindowSchedules
    } = this.state;

    const selectedWindowSchedule = WindowSchedules[scheduleIndex];
    const {
      DaysOfTheWeek,
      BwcapMbps,
      StartTime,
      EndTime
    } = selectedWindowSchedule;

    const validBWCapInMbps = BwcapMbps.toString() && parseInt(BwcapMbps, 10) <= MaxBWLimit;

    return DaysOfTheWeek.length && validBWCapInMbps && StartTime && EndTime;
  }


  saveNewUpdateCustomScheduleBlockHTML = () => {
    this.setState({
      activeScheduleIndex: "",
      editOldSchedule: "",
      enableCustomScheduleBlock: false
    });
  }


  displayNewUpdateCustomScheduleBlock = () => {
    let newCustomScheduleBlockHTML = "";
    const {
      activeScheduleIndex,
      WindowSchedules
    } = this.state;

    if (!activeScheduleIndex) {
      return newCustomScheduleBlockHTML;
    }


    const selectedWindowSchedule = WindowSchedules[activeScheduleIndex];
    const enableCustomScheduleSave = this.checkForEnabledNewUpdateCustomSchedule(activeScheduleIndex);
    const bandWidthWithErrorSuffix = this.getBandWidthErrorSuffixData(
      parseInt(selectedWindowSchedule.BwcapMbps, 10)
    );

    newCustomScheduleBlockHTML = (
      <StackingLayout
        className="new-custom-schedule-block"
      >
        <FlexLayout
          itemFlexBasis="100pc"
          className="timePicker-block"
        >
          <TimePicker
            inputProps={ {
              label: "From",
              readOnly: true
            } }
            allowEmpty={ false }
            format12Hours={ true }
            value={ this.getTime(moment(selectedWindowSchedule.StartTime, "H:mm")) }
            renderInputValue={ this.renderInputValue }
            onChange={ (e) => this.setCustomScheduleTime(e, "StartTime", activeScheduleIndex) }
            getPopupContainer={ triggerNode => triggerNode.parentNode }
          />
          <TimePicker
            inputProps={ {
              label: "To",
              readOnly: true
            } }
            allowEmpty={ false }
            format12Hours={ true }
            renderInputValue={ this.renderInputValue }
            value={ this.getTime(moment(selectedWindowSchedule.EndTime, "H:mm")) }
            onChange={ (e) => this.setCustomScheduleTime(e, "EndTime", activeScheduleIndex) }
            getPopupContainer={ triggerNode => triggerNode.parentNode }
          />
        </FlexLayout>
        <FlexLayout itemFlexBasis="100pc">
          <MultiSelectWithLabel
            label="On"
            secondaryLabel="Days of the week"
            placeholder="Select Schedule"
            rowsData={ WeekDays }
            onSelectedChange={ (e) => this.onSelectFrequencyDay(e, activeScheduleIndex) }
            selectedRows={ selectedWindowSchedule.DaysOfTheWeek }
          />
        </FlexLayout>

        <FlexLayout itemFlexBasis="100pc">
          <FormItemInput
            type="number"
            onKeyPress={ this.allowNumbersOnly }
            label="Bandwidth Limit"
            placeholder="0"
            value={ selectedWindowSchedule.BwcapMbps }
            helpText={ resourceBundle.Information.policy_max_bandwidth_message }
            min="0"
            max={ MaxBWLimit }
            onChange={ (e) =>
              this.onSetInputValue(e.target.value, "BwcapMbps", activeScheduleIndex)
            }
            error={ bandWidthWithErrorSuffix }
            suffix={ bandWidthWithErrorSuffix }
          />
        </FlexLayout>
        <FlexLayout itemFlexBasis="100pc">
          <div>
            <Button
              key="submit"
              type="primary"
              disabled={ !enableCustomScheduleSave }
              onClick={ this.saveNewUpdateCustomScheduleBlockHTML }
              className="pull-right save-update-custom-policy-button"
            >
              Save
            </Button>

            <Button
              key="back"
              type="secondary"
              className="pull-right"
              onClick={ this.cancelNewUpdateSchedule }
            >
              Cancel
            </Button>
          </div>
        </FlexLayout>
      </StackingLayout>
    );
    return newCustomScheduleBlockHTML;
  }


  removeSchedule = (scheduleIndex) => {
    const {
      WindowSchedules
    } = this.state;

    WindowSchedules.splice(scheduleIndex, 1);

    this.setState({
      WindowSchedules
    });
  }


  showWindowsSchedule = () => {
    const {
      WindowSchedules,
      scheduleType,
      editOldSchedule,
      activeScheduleIndex
    } = this.state;

    const formattedActiveScheduleIndex = parseInt(activeScheduleIndex, 10);
    const disablDeletePolicy = activeScheduleIndex || WindowSchedules.length === 1;

    return WindowSchedules.map((bandwidth_data, index) => {
      const filteredDaysOfTheWeek = [];
      bandwidth_data.DaysOfTheWeek.forEach((weekDay) => {
        filteredDaysOfTheWeek.push(weekDay.value);
      });

      const FormattedDaysOfTheWeek = AppUtil.transformTitleCase(
        filteredDaysOfTheWeek.join(", ").toLowerCase()
      );
      const momentStartTime = moment(bandwidth_data.StartTime, "HH:mm");
      const momentEndTime = moment(bandwidth_data.EndTime, "HH:mm");
      const displayStartTime = momentStartTime.format("h:mm A");
      const displayEndTime = momentEndTime.format("h:mm A");
      const isNextDayTimeFrame = momentStartTime.diff(momentEndTime) >= 0 ? "(Next Day)" : "";
      const allDaysSchedule = scheduleType === "all";
      const formattedScheduleText = allDaysSchedule ? "All Duration"
        : `${displayStartTime} - ${displayEndTime} ${isNextDayTimeFrame}`;

      if (editOldSchedule && formattedActiveScheduleIndex === index) {
        return this.displayNewUpdateCustomScheduleBlock();
      } else if (!(formattedActiveScheduleIndex === index)) {
        return (
          <FlexLayout
            key={ index }
            itemFlexBasis="100pc"
            itemSpacing="20px">
            <div
              className="ntnx ntnx-table window-schdeule"
              data-border="true"
              data-fixed-header="false">
              <div className="table-main-section">
                <div className="table-header">
                  <table>
                    <thead>
                      <tr>
                        <th className="title -bandwidth-column small-column-vm-name">
                          <span>Bandwidth</span>
                        </th>
                        <th className="title -week-column small-column-vm-name">
                          <span>On</span>
                        </th>
                        <th className="title -duration-column small-column-vm-name">
                          <span>Duration</span>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="table-body">
                  <table>
                    <tbody className="">
                      <tr className="">
                        <td className="title -bandwidth-column">
                          <span title={ `${bandwidth_data.BwcapMbps} MBps` }>
                            { bandwidth_data.BwcapMbps } MBps
                          </span>
                        </td>
                        <td className="title -week-column">
                          <span title={ FormattedDaysOfTheWeek }>
                            { allDaysSchedule ? "All Days" : FormattedDaysOfTheWeek }
                          </span>
                        </td>
                        <td className="title -duration-column">
                          <FlexLayout
                            justifyContent="space-between"
                            itemSpacing="0px">
                            <span
                              className="extended-time"
                              title={ formattedScheduleText }>
                              { formattedScheduleText }
                            </span>
                            <span className="policy-action-link">
                              <TextLabel>
                                { !disablDeletePolicy
                                  ? <span>
                                    <Tooltip content="Edit Policy">
                                      <EditIcon
                                        onClick={ () => this.enableNewUpdateCustomScheduleBlock(index.toString()) }
                                      />
                                    </Tooltip>
                                    <Tooltip content="Remove Policy">
                                      <RemoveIcon onClick={ () => this.removeSchedule(index) }/>
                                    </Tooltip>
                                  </span> : ""
                                }
                              </TextLabel>
                            </span>
                          </FlexLayout>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </FlexLayout>
        );
      }
    });
  }

  getMoveHelpURL = () => {
    const prism_id = resourceBundle.help_prism_ids.bandwidth_cap;
    const url = resourceBundle.help_url_pre + resourceBundle.move_version + resourceBundle.help_url_mid + prism_id;
    const win = window.open(url, "_blank");
    win.focus();
  }

  render() {
    const {
      showBandWidthModalCurrentStep,
      showPolicyErrorMessage,
      policyDetails
    } = this.state;

    const {
      closeCreateEditBandwidthPolicyModal
    } = this.props;

    let modalBody = "";
    let policyErrorMessage = "";
    let modalHeading = "Create";

    if (showPolicyErrorMessage) {
      policyErrorMessage = (
        <Alert
          className="policy-error-message"
          type="error"
          message={ showPolicyErrorMessage }
        />
      );
    }

    if (showBandWidthModalCurrentStep === "input") {
      modalBody = this.showInputBlock(policyErrorMessage);
    } else {
      modalBody = this.showReviewBlock(policyErrorMessage);
    }

    if (policyDetails) {
      modalHeading = "Update";
    }

    return (
      <Modal
        afterClose={ closeCreateEditBandwidthPolicyModal }
        visible={ true }
        onClose={ closeCreateEditBandwidthPolicyModal }
        title={ `${modalHeading} Bandwidth Throttling Policy` }
        footer={ this.renderFooter() }
        className="create-edit-bandwidth-policy-modal"
        width={ 550 }
        maskClosable={ false }
        keyboard={ false }
        autoFocus={ false }
        headerActions={ [
          <QuestionIcon key="question" onClick={ () => this.getMoveHelpURL() }/>,
          <CloseIcon key="close" onClick={ () => closeCreateEditBandwidthPolicyModal() } />
        ] }
      >
        <StackingLayout padding="20px">
          { modalBody }
        </StackingLayout>
      </Modal>
    );
  }

}

export default CreateEditBandwidthPolicyModal;
