// import { Provider as Provider_API } from "../data/DataURLConstants";
import FetchUtil from "../tools/FetchUtil";
import Request from "../api/Request-Interface/Request.js";

const Provider = {
  getAllProvidersList(providerType = "VM") {
    const formData = {
      EntityType: providerType
    };

    return Request.post("/move/v2/providers/list", formData, FetchUtil.headers());
  },

  refreshSourceInventory(id, files = false) {
    const providerListFilterData = {
      RefreshInventory: true
    };

    if (!files) {
      providerListFilterData.Filter = {};
      providerListFilterData.showVMs = "all";
    }

    return Request.post(`/move/v2/providers/${id}/workloads/list`, providerListFilterData, FetchUtil.headers());
  },

  refreshTargetInventory(id) {
    const providerListFilterData = {
      RefreshInventory: true,
      UUIDs: [id]
    };
    return Request.post("/move/v2/providers/list", providerListFilterData, FetchUtil.headers());
  },

  refreshProviderInventory(id, files = false) {
    const providerListFilterData = {
      Filter: {},
      RefreshInventory: true
    };

    if (!files) {
      providerListFilterData.showVMs = "all";
    }

    return Request.post(`/move/v2/providers/${id}/workloads/list`, providerListFilterData, FetchUtil.headers());
  },

  removeProvider(providerID) {
    return Request.delete(`/move/v2/providers/${providerID}`, null, FetchUtil.headers());
  },

  validateInventory(providerID) {
    return Request.post(`/move/v2/providers/${providerID}/validate`, {}, FetchUtil.headers());
  },

  getProviderDetails(providerID) {
    return Request.get(`/move/v2/providers/${providerID}`, FetchUtil.headers());
  },

  getProviderInventory(providerID) {
    return Request.post(`/move/v2/providers/${providerID}/workloads/list`, {}, FetchUtil.headers());
  }

};

export default Provider;
