import React from "react";
import { FormSection, FormItemInput, FlexLayout,
  FormItemProvider, InputPassword } from "@nutanix-ui/prism-reactjs";

const PasswordInputPlusLabel = FormItemProvider(InputPassword);

class NTNXFileServerForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      togglePasswordLabel: "Show"
    };
  }

  render() {
    const { updateFormData, serverBody, modalInEditMode } = this.props;

    return (
      <FormSection itemSpacing="0px" className="-file-server-section">
        <FormItemInput
          id={ serverBody[0].key }
          label={ serverBody[0].inputTitle }
          placeholder={ serverBody[0].placeholder }
          value={ serverBody[0].value }
          onChange={ (e) => updateFormData(0, e.currentTarget.value) }
        />
        <FormItemInput
          id={ serverBody[1].key }
          label={ serverBody[1].inputTitle }
          placeholder={ serverBody[1].placeholder }
          value={ serverBody[1].value }
          onChange={ (e) => updateFormData(1, e.currentTarget.value) }
          disabled={ modalInEditMode }
        />
        <FlexLayout itemFlexBasis="100pc" className="file-split-creds-block">
          <FormItemInput
            id={ serverBody[2].key }
            label={ serverBody[2].inputTitle }
            placeholder={ serverBody[2].placeholder }
            value={ serverBody[2].value }
            onChange={ (e) => updateFormData(2, e.currentTarget.value) }
            helpText="Please provide REST API Credentials."
          />
          <PasswordInputPlusLabel
            id={ serverBody[3].key }
            label={ serverBody[3].inputTitle }
            placeholder={ serverBody[3].placeholder }
            value={ serverBody[3].value }
            onChange={ (e) => updateFormData(3, e.currentTarget.value) }
          />
        </FlexLayout>
      </FormSection>
    );
  }

}

export default NTNXFileServerForm;
