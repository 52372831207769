/* global window */
let appLocalConfig = {};

class AppConfig {

  static get() {
    return appLocalConfig;
  }

  static set(data) {
    Object.assign(appLocalConfig, data);
  }

  static clear() {
    appLocalConfig = {};
  }

}

export default AppConfig;
