
import React from "react";
import { Modal, Button, Title, StackingLayout,
  FlexLayout, UnorderedList } from "@nutanix-ui/prism-reactjs";
import "./SplashScreenModel.less";

const ANNOUNCEMENT_ICON = require("../../../assets/img/cloud-xtract-img/loud-speaker-icon.svg");


class SplashScreenModel extends React.Component {

  render() {
    const announcementItem = [
      "Nutanix Move can now migrate shares from File Servers(SMB/NFS) to Nutanix Files."
    ];
    const {
      visible,
      onOk
    } = this.props;

    return (
      <Modal
        visible={ visible }
        onClose={ onOk }
        onOk={ onOk }
        width={ 500 }
        className="splash-screen-modal"
        title="What's New"
        footer={
          <FlexLayout itemSpacing="10px" justifyContent="flex-end">
            <Button
              id="_uiauto-first-time-continue"
              type="primary"
              onClick={ onOk }>
              Ok, Got It
            </Button>
          </FlexLayout>
        }
      >
        <StackingLayout className="main-container" padding="20px" itemSpacing="20px">
          <StackingLayout>
            <FlexLayout justifyContent="space-between">
              <img
                src={ ANNOUNCEMENT_ICON }
                className="presentation-img"
                role="presentation"
              />
              <Title size="h1">
                Introducing Files Migration with Nutanix Move!
              </Title>
            </FlexLayout>
          </StackingLayout>
          <StackingLayout itemSpacing="10px">
            <Title size="h3">
              Files Migration
            </Title>
            <UnorderedList
              data={ announcementItem }
            />
          </StackingLayout>
        </StackingLayout>
      </Modal>
    );
  }

}

export default SplashScreenModel;
