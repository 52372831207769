
import React from "react";
import {
  FlexLayout,
  StackingLayout,
  Title,
  StatusIcon,
  FormItemProvider,
  TextLabel,
  Alert,
  Checkbox,
  FileInput,
  Link,
  Input
} from "@nutanix-ui/prism-reactjs";
import AppConfig from "../../../app-config/AppConfig";
import AppUtil from "../../../tools/AppUtil";

const InputPlusLabel = FormItemProvider(Input);

const resourceBundle = AppUtil.getI18nJSONResourceBundle();

class AutoVMPreprationBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      windowsLogin: "",
      windowsPassword: "",
      linuxLogin: "",
      linuxPassword: "",
      fileList: [],
      usePEMFile: false,
      showPasswordInputList: []
    };
    this.pemUpload = this.pemUpload.bind(this);
    this.handleOpenSelectFile = this.handleOpenSelectFile.bind(this);
    this.handleRemoveSelectedFile = this.handleRemoveSelectedFile.bind(this);
    this.inputRef = React.createRef();
  }

  componentWillMount() {
    this.setState({
      windowsLogin: this.props.windowsLogin,
      windowsPassword: this.props.windowsPassword,
      linuxLogin: this.props.linuxLogin,
      linuxPassword: this.props.linuxPassword,
      fileList: this.props.fileList,
      usePEMFile: this.props.usePEMFile,
      PemFile: this.props.PemFile,
      PemFileOnEdit: this.props.PemFileOnEdit
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      windowsLogin: nextProps.windowsLogin,
      windowsPassword: nextProps.windowsPassword,
      linuxLogin: nextProps.linuxLogin,
      linuxPassword: nextProps.linuxPassword,
      fileList: nextProps.fileList,
      usePEMFile: nextProps.usePEMFile,
      PemFile: nextProps.PemFile,
      PemFileOnEdit: nextProps.PemFileOnEdit
    });
  }


  togglePEMFileOption = (clearPemFile) => {
    let {
      usePEMFile,
      PemFile,
      PemFileOnEdit,
      fileList
    } = this.state;

    usePEMFile = !usePEMFile;
    this.setState({
      usePEMFile
    }, () => {
      if (clearPemFile || !usePEMFile) {
        fileList = [];
        PemFile = "";
        PemFileOnEdit = false;
      }
      this.props.updateParentState({
        fileList,
        usePEMFile,
        PemFile,
        PemFileOnEdit
      });
    });
  }


  pemUpload = (file) => {
    const uploadedFile = file[0];
    this.setState({
      fileList: file
    }, () => {
      return AppUtil.getFileBase64(uploadedFile).then((uFile) => {
        const PemFile = btoa(uFile.currentTarget.result);
        this.props.updateParentState({
          PemFile,
          fileList: this.state.fileList
        });
        return false;
      });
    });

    return false;
  }

  toggleInputType = (fieldName) => {
    const { showPasswordInputList } = this.state;
    const selectedFieldIndex = showPasswordInputList.indexOf(fieldName);
    if (selectedFieldIndex > -1) {
      showPasswordInputList.splice(selectedFieldIndex, 1);
    } else {
      showPasswordInputList.push(fieldName);
    }

    this.setState({
      showPasswordInputList
    });
  }

  showPEMCheckbox = () => {
    if ((resourceBundle.hyperv_providers_type.indexOf(this.props.sourceType) > -1) ||
    (resourceBundle.aos_providers_type.indexOf(this.props.sourceType) > -1)) {
      return null;
    }
    return (
      <Checkbox
        id="pemCheckBox"
        label="Use Private (.PEM) file to authenticate"
        onChange={ e => { this.togglePEMFileOption(); } }
        checked={ this.state.usePEMFile }
      />
    );
  }


  showUserGuide = () => {
    const appConfig = AppConfig.get();
    const win = window.open(appConfig.helpPortalUrl, "_blank");
    win.focus();
  }


  showPreRequisites = () => {
    let preRequisitesBody = "";

    if (resourceBundle.enabled_prerequisites_providers.indexOf(this.props.sourceType) > -1) {
      preRequisitesBody = (
        <StackingLayout>
          <Title size="h3">
            Pre requisites
          </Title>
          <StackingLayout className="step-box">
            <StackingLayout className="-step-container">
              <TextLabel className="documentation-link">
                Ensure that Windows Remote Management(Ports 5985/5986) and SSH(Port 22) are enabled
                on the source VMs for Guest Operations.
                Please refer the <Link onClick={ this.showUserGuide }> user guide </Link> for detailed information.
              </TextLabel>
            </StackingLayout>
          </StackingLayout>
        </StackingLayout>
      );
    }
    return preRequisitesBody;
  }

  handleOpenSelectFile = () => {
    // This will open the file dialog
    this.inputRef.current.click();
  }

  handleRemoveSelectedFile = () => {
    this.setState({
      fileList: []
    }, () => {
      this.props.updateParentState({
        fileList: [],
        PemFile: ""
      });
    });
  }

  showPEMFileBlock = () => {
    let PEMFileBlockHTML = "";
    const {
      fileList,
      usePEMFile
    } = this.state;

    if (!usePEMFile) {
      return PEMFileBlockHTML;
    }

    if (fileList.length) {
      PEMFileBlockHTML = <Input label="File Name" value={ fileList[0].name }
        suffix={ <Link onClick={ this.handleRemoveSelectedFile }>Remove</Link> } />;
    } else {
      PEMFileBlockHTML = (
        <FileInput
          className="fileupload"
          enableDragDrop={ false }
          onFileChange={ this.pemUpload }
          enableFileSelect={ false }
          inputRef={ this.inputRef }
          accept=".pem"
          content={ <Input readOnly={ true } accept=".pem" label="File Name" placeholder="Select file(.PEM) to upload"
            suffix={ <Link onClick={ this.handleOpenSelectFile }>Upload</Link> } /> }
        />
      );
    }

    return PEMFileBlockHTML;
  }

  render() {
    const {
      showPasswordInputList,
      fileList,
      usePEMFile,
      linuxLogin
    } = this.state;
    const showLinuxPassword = showPasswordInputList.indexOf("linuxPassword") > -1;
    const showWindowsPassword = showPasswordInputList.indexOf("windowsPassword") > -1;

    const isAWSOrAzureSource = resourceBundle.providers_with_regions.indexOf(this.props.sourceType) > -1;
    if (isAWSOrAzureSource) {
      return null;
    }

    return (
      <StackingLayout>
        { this.showPreRequisites() }
        <StackingLayout>
          <Title size="h3">
            Credentials for Source VMs
            <span className="to">
              <StatusIcon
                type="question"
                tooltipProps={ {
                  content: "This is a common username & password used to login to instances.",
                  popupPlacement: "right"
                } }
              />
            </span>
          </Title>
          <StackingLayout className="step-box" itemSpacing="0px">
            <StackingLayout className="-step-container -with-border" itemSpacing="0px">
            </StackingLayout>
            <StackingLayout className="creds-box">
              <Title size="h3">
                Windows VMs
              </Title>
              <TextLabel className="-creds-text">
                Provide user credentials with Administrator privileges.
              </TextLabel>
              <FlexLayout itemFlexBasis="100pc">
                <InputPlusLabel
                  id="_uiauto-windows-vm-username"
                  label="User Name"
                  placeholder="user name"
                  className="vm-credential-input"
                  value={ this.state.windowsLogin }
                  onChange={ (event) => this.props.genericOnChange(event, "windowsLogin") }
                />
                <InputPlusLabel
                  id="_uiauto-windows-vm-password"
                  label="Password"
                  placeholder="password"
                  type={
                    showWindowsPassword ? "text" : "password"
                  }
                  className="vm-credential-input"
                  value={ this.state.windowsPassword }
                  onChange={ (event) => this.props.genericOnChange(event, "windowsPassword") }
                  suffix={
                    <Link onClick={ () => this.toggleInputType("windowsPassword") }>
                      { showWindowsPassword ? "Hide" : "Show"}
                    </Link>
                  }
                />
              </FlexLayout>
            </StackingLayout>

            <StackingLayout>
              <Title size="h3">
                Linux VMs
              </Title>
              <TextLabel className="-creds-text">
                Provide user credentials with root privileges.
              </TextLabel>
              <FlexLayout itemFlexBasis="100pc">
                <InputPlusLabel
                  id="_uiauto-linux-vm-username"
                  label="User Name"
                  placeholder="user name"
                  className="vm-credential-input"
                  value={ linuxLogin }
                  onChange={ (event) => this.props.genericOnChange(event, "linuxLogin") }
                  error={ usePEMFile && fileList.length && !linuxLogin }
                />
                <InputPlusLabel
                  id="_uiauto-linux-vm-password"
                  label="Password"
                  placeholder="password"
                  type={
                    showLinuxPassword ? "text" : "password"
                  }
                  className="vm-credential-input"
                  value={ this.state.linuxPassword }
                  disabled={ usePEMFile || this.props.PemFileOnEdit }
                  onChange={ (event) => this.props.genericOnChange(event, "linuxPassword") }
                  suffix={
                    <Link onClick={ () => this.toggleInputType("linuxPassword") }>
                      { showLinuxPassword ? "Hide" : "Show"}
                    </Link>
                  }
                />
              </FlexLayout>
              { this.props.PemFileOnEdit
                ? <Alert message="PEM file was uploaded successfully."
                  onClose={ e => { this.togglePEMFileOption("clearPemFile"); } }
                />
                : this.showPEMCheckbox()
              }
              { this.showPEMFileBlock() }
            </StackingLayout>
          </StackingLayout>
        </StackingLayout>
      </StackingLayout>
    );
  }

}

export default AutoVMPreprationBody;
