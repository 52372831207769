import React from "react";
import { Modal, Alert, Button, StackingLayout,
  TextLabel, FlexLayout } from "@nutanix-ui/prism-reactjs";
import classNames from "classnames";
import "./os-credentials-modal.less";
import AppUtil from "../../../tools/AppUtil";
import SOURCE from "../../../RestAPI/source";

const resourceBundle = AppUtil.getI18nJSONResourceBundle();
const UVMPREPTITLE = "UVM Preparation";
const OSCREDENTIALSTITLE = "OS Credentials";

class OSCredentialsModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showWarningBody: true
    };

    this.COLUMNS = [
      {
        title: "VM Name",
        dataIndex: "VMName",
        key: "VMName",
        width: 420
      }
    ];
  }

  showIndividualVMPrepPopup = () => {
    this.props.updateParentState({
      showIndividualVMPrepPopup: true
    });
    this.props.closeOSCredentialsModal();
  }

  secondaryButtonOnClick = () => {
    this.setState({
      showWarningBody: true
    });

    this.props.closeOSCredentialsModal();
  }

  renderFooter() {
    return (
      <div>
        <Button
          key="back"
          type="secondary"
          className="pull-left"
          onClick={ this.secondaryButtonOnClick }>
          Don&apos;t Proceed
        </Button>
        <Button
          key="submit"
          type="primary"
          onClick={ this.props.onOk }
        >
          Continue
        </Button>
      </div>
    );
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    const osCredentialsBody = classNames("os-credentials-body", {
      "show-block": this.state.showWarningBody
    });

    const PopupTitle = this.props.GuestPrepMode === "manual" ? UVMPREPTITLE : OSCREDENTIALSTITLE;

    return (
      <Modal
        onClose={ this.props.afterClose }
        visible={ true }
        title={ PopupTitle }
        footer={ this.renderFooter() }
        className="os-credentials-os-modal ntnx-modal-content"
        maskClosable={ false }
      >
        <FlexLayout className="os-credentials">
          { SOURCE.isSourceEsx(this.props.sourceType) && this.props.GuestPrepMode === "manual"
            ? <StackingLayout padding="20px">
              <TextLabel>
              Please ensure you have run User VM preparation script on all the
              virtual machines selected in this migration plan. If not, Move
              will only migrate VM data and will not start VM in the target
              environment. All OS configuration operations will be bypassed as
              well.
              </TextLabel>
            </StackingLayout>
            : <div>
              <Alert
                message={ `${this.props.osCredentialsVMs.length}
                  Guest VM(s) do not have operating system credentials.` }
                type="warning"
              />
              <StackingLayout padding="20px">
                <div className={ osCredentialsBody }>
                  <TextLabel>
                    { resourceBundle.VM_Preparation.auto_prepare_without_creds_message }
                  </TextLabel>
                </div>
              </StackingLayout>
            </div>
          }
        </FlexLayout>
      </Modal>
    );
  }

}

export default OSCredentialsModal;
