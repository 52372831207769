export const URL_PREFIX = {
  dev: "http://localhost:3004/",
  prod: "https://10.4.88.175/",
  loc: "localhost/"
};

export const LOGIN = {
  url: "/v1/users/login",
  loginV2: "/move/v2/users/login"
};

export const SOURCES = {
  url: "/v1/sources",
  v2Url: "/move/v2/providers/list"
};

export const TARGET = {
  url: "/v1/targets",
  inventory: "/v1/targets/inventory"
};

export const MIGRATION_PLAN = {
  url: "/v1/migrationplans",
  plansListV2: "/move/v2/plans/list",
  plansV2: "/move/v2/plans",
  validate: "/v1/validatetgtcapacity",
  workloadAction: "/move/v2/plans/workloads/action"
};

export const NETWORK_INVENTORY = {
  url: "/v1/sourcetargetnetworks"
};

export const EULA = {
  url: "/v1/eula"
};

export const APPINFO = {
  url: "/move/v2/appinfo"
};

export const CONFIGURE = {
  url: "/move/v2/configure"
};

export const SUPPORT_BUNDLE_STATUS = {
  url: "/move/v2/supportbundle/status"
};

export const START_SUPPORT_BUNDLE_DOWNLOAD = {
  url: "/move/v2/supportbundle"
};

export const LOGOUT = {
  url: "/move/v2/token/revoke"
};

export const PROVIDERS = {
  list: "/move/v2/providers/list",
  providers: "/move/v2/providers"
};

export const MOVE_EVENTS = {
  download: "/move/v2/events/download",
  download_status: "/move/v2/events/download/status",
  download_cancel: "/move/v2/events/download/cancel"
};
