import { browserHistory } from "react-router";
import queryString from "query-string";
import { NotificationTrigger } from "@nutanix-ui/prism-reactjs";
import $http from "../../../api/Request-Interface/Request";
import { APPINFO } from "../../../data/DataURLConstants";
import AppInfoData from "../../../app-config/AppInfoData";
import { SetDefaultToken } from "../../../app-config/SetDefaultToken";
import MOVE_APPLICATION from "../../../RestAPI/MOVE_APPLICATION.js";

const LOCALSTORAGE = window.localStorage;

export function CheckEULA(nextState, replace, cb) {
  $http.get(APPINFO.url)
    .then((response) => {
      const { code } = queryString.parse(window.location.search);
      const IAMUUID = LOCALSTORAGE.getItem("iamUUID");
      AppInfoData.set(response.Status);
      const responseStatus = (response && response.Status) || {};
      // TODO need to move this logic to store(Redux)
      if (!responseStatus.EulaAccepted && !IAMUUID) {
        // check if password is already set
        if (!responseStatus.State) {
          // if password is not set get token with default credentials
          SetDefaultToken();
          LOCALSTORAGE.setItem("showSplashScreen", true);
          browserHistory.push("/eula");
        } else if (sessionStorage.getItem("token")) {
          LOCALSTORAGE.setItem("showSplashScreen", true);
          browserHistory.push("/eula");
        } else {
          // if password(State) is set redirect to login page to get token with new password
          browserHistory.push("/");
        }
      } else if (IAMUUID && code && !sessionStorage.getItem("token")) {
        const payload = {
          id: IAMUUID,
          grantType: "AUTHORIZATION_CODE",
          authCode: code
        };
        const registerTokenPayload = new URLSearchParams(payload).toString();

        return MOVE_APPLICATION.generateToken(registerTokenPayload, IAMUUID)
          .then((response) => {
            const { AccessToken, RefreshToken, Username, Expiry } = response;
            if (AccessToken) {
              sessionStorage.setItem("token", AccessToken);
              MOVE_APPLICATION.checkAuthorizedToken()
                .then(() => {
                  sessionStorage.setItem("Username", Username);
                  sessionStorage.setItem("RefreshToken", RefreshToken);
                  sessionStorage.setItem("TokenExpiry", Expiry);
                  if (!responseStatus.EulaAccepted) {
                    LOCALSTORAGE.setItem("showSplashScreen", true);
                    browserHistory.push("/eula");
                  } else {
                    browserHistory.push("/dashboard");
                  }
                })
                .catch(() => {
                  sessionStorage.removeItem("token");
                  browserHistory.push("/");
                });
            } else {
              NotificationTrigger.add({
                type: "error",
                message: response.Message || "",
                minMessageLineCount: 3,
                autoDismissDelaySecs: 10
              });
            }
          })
          .catch(() => {
            browserHistory.push("/");
          });
      } else if (sessionStorage.getItem("token")) {
        if (!responseStatus.EulaAccepted) {
          LOCALSTORAGE.setItem("showSplashScreen", true);
          browserHistory.push("/eula");
        } else {
          browserHistory.push("/dashboard");
        }
      } else {
        browserHistory.push("/");
      }
    }).catch((error) => {
      // Todo: need to asses what we can do if check eula api fails for now it
      // will redirect to root
      browserHistory.push("/");
    });

  cb();
}
