import { MOVE_EVENTS } from "../data/DataURLConstants";
import FetchUtil from "../tools/FetchUtil";
import Request from "../api/Request-Interface/Request";

const EVENTS = {
  startCSVDownload(body) {
    return Request.post(MOVE_EVENTS.download, body, FetchUtil.headers());
  },

  getCSVDownloadStatus() {
    return Request.get(MOVE_EVENTS.download_status, FetchUtil.headers());
  },

  cancelCSVDownload() {
    return Request.get(MOVE_EVENTS.download_cancel, FetchUtil.headers());
  }
};

export default EVENTS;
