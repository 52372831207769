import React from "react";
import { FormItemInput, Link } from "@nutanix-ui/prism-reactjs";
import classNames from "classnames";

class AddUpdateHyperVSource extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      togglePasswordLabel: "Show"
    };
  }

  togglePasswordField = () => {
    let labelValue = "";

    if (this.state.togglePasswordLabel === "Show") {
      labelValue = "Hide";
    } else {
      labelValue = "Show";
    }
    this.setState({ togglePasswordLabel: labelValue });
  }

  componentDidMount() {
    this.setState({
      togglePasswordLabel: "Show"
    });
  }


  render() {
    const { formBody } = this.props;

    return (
      <div className="hyperv-form-body">
        {
          formBody.map((formItem, index) => {
            let formInputType = formBody.inputType;
            let suffix = null;

            if (formItem.allowPasswordToggle) {
              suffix = (
                <Link
                  id="_uiauto-toggle-hyper-v-password"
                  className="toggle-password-suffix"
                  onClick={ this.togglePasswordField }
                >
                  { this.state.togglePasswordLabel }
                </Link>
              );

              if (this.state.togglePasswordLabel === "Show") {
                formInputType = "password";
              } else {
                formInputType = "text";
              }
            }

            return (
              <div
                className={ classNames({
                  "split-input": [2, 3].indexOf(index) > -1
                }) }
              >
                <FormItemInput
                  id={ formItem.key }
                  label={ formItem.inputTitle }
                  autoFocus={ !index }
                  placeholder={ formItem.placeholder }
                  type={ formInputType }
                  suffix={ suffix }
                  value={ formItem.value }
                  onChange={ (e) => this.props.handleOnInputValueChange(e, index, formBody) }
                />
              </div>
            );
          })
        }
      </div>
    );
  }

}

export default AddUpdateHyperVSource;
