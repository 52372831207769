
import React from "react";
import { Modal, Button, Separator, VerticalSeparator,
  QuestionIcon, CloseIcon } from "@nutanix-ui/prism-reactjs";
import AppUtil from "../../../tools/AppUtil";
import "./AboutNutanixModal.less";

const rb = AppUtil.getI18nJSONResourceBundle();
const MOVE_LOGO = require("../../../assets/img/cloud-xtract-img/xtract.svg");

class AboutNutanixModal extends React.Component {

  openMoveHelpPortal = () => {
    const prism_id = rb.help_prism_ids.about_move;
    const url = rb.help_url_pre + rb.move_version + rb.help_url_mid + prism_id;
    const win = window.open(url, "_blank");
    win.focus();
  }

  getModalheader = () => {
    const header =
      <Separator separator={ <VerticalSeparator /> }>
        <div onClick={ () => this.openMoveHelpPortal() }><QuestionIcon id="_uiauto-move-help-icon"/></div>
        <div onClick={ this.props.onOk }><CloseIcon /></div>
      </Separator>;
    return header;
  }

  render() {
    return (
      <Modal
        visible={ this.props.visible }
        title="Move"
        onOk={ this.props.onOk }
        width={ 400 }
        className="about-nx-modal"
        headerActions={ this.getModalheader() }
        footer={
          <div>
            <p className="product-copyright">
              Move is a product of Nutanix, Inc. Copyright 2024. All rights reserved.
            </p>
            <Button
              key="submit"
              type="primary"
              className="btn-close"
              onClick={ this.props.onOk }>
              OK
            </Button>
          </div>
        }
      >

        <div className="nx-product-logo">
          <img src={ MOVE_LOGO } alt="Move" />
        </div>
        <div className="nx-product-version">
          Version { this.props.version }
        </div>
      </Modal>
    );
  }

}

export default AboutNutanixModal;
