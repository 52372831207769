
import React from "react";
import { Modal, Button, StackingLayout, TextLabel,
  Link, FlexLayout, Divider, Paragraph, NotificationTrigger } from "@nutanix-ui/prism-reactjs";
import copy from "copy-to-clipboard";
import AppUtil from "../../../tools/AppUtil";
import "./PermissionPolicyJson.less";


const PolicyScript = "create-azure-app";
const resourceBundle = AppUtil.getI18nJSONResourceBundle();

class AzurePermissionPolicyJson extends React.Component {

  copyPolicyScript = () => {
    copy(PolicyScript.replace(/['"]+/g, ""));
    NotificationTrigger.add({
      type: "success",
      message: "Script copied to clipboard.",
      autoDismissDelaySecs: 5
    });
  }

  render() {
    const {
      visible,
      onCancel
    } = this.props;

    return (
      <Modal
        visible={ visible }
        title={ resourceBundle.Information.create_azure_client_id_secret }
        onClose={ onCancel }
        onOk={ onCancel }
        width={ 450 }
        className="azure-permission-policy-json"
        footer={
          <div>
            <Button
              id="_uiauto-azure-create-client-close-button"
              key="submit"
              type="secondary"
              className="btn-close"
              onClick={ onCancel }>
              { resourceBundle.Common.close }
            </Button>
          </div>
        }
      >
        <StackingLayout itemSpacing="20px" padding="20px">
          <TextLabel>
            { resourceBundle.Information.azure_create_client_secred_id_help }
            <Link
              id="_uiauto-azure-create-client-click-here-url"
              className="-pre"
              target="_blank"
              href={ resourceBundle.azure_premission_policy_url }>
              { resourceBundle.Common.click_here }
            </Link>
          </TextLabel>
          <StackingLayout>
            <FlexLayout alignItems="center">
              <Divider/>
              <Paragraph className="no-wrap" type="secondary">or</Paragraph>
              <Divider/>
            </FlexLayout>
            <TextLabel>
              { resourceBundle.Information.azure_create_client_secred_id_using_ssh_help }
            </TextLabel>
            <Link
              id="_uiauto-azure-create-client-copy-script"
              onClick={ this.copyPolicyScript }
              className="pull-right -script-link">
              { resourceBundle.Common.copy_script }
            </Link>
            <pre
              id="_uiauto-azure-create-client-script-text"
              className="ntnx-dark-example -dark-bg-script-box">
              > { PolicyScript }
            </pre>
          </StackingLayout>
        </StackingLayout>
      </Modal>
    );
  }

}

export default AzurePermissionPolicyJson;
