
import React from "react";
import { FormItemProvider, Select, TextLabel, StatusIcon } from "@nutanix-ui/prism-reactjs";
import AppUtil from "../../../tools/AppUtil";


const SelectWithLabel = FormItemProvider(Select);
const resourceBundle = AppUtil.getI18nJSONResourceBundle();
const PreparationModeHelpText = {
  auto: resourceBundle.VM_Preparation.move_auto_prepare_with_credentials_desc,
  manual: resourceBundle.VM_Preparation.move_manual_prepare_desc,
  custom: resourceBundle.VM_Preparation.move_mixed_prepare_desc
};

const PreparationModeOptions = [
  {
    value: "auto",
    label: "Automatic",
    key: "1"
  },
  {
    value: "manual",
    label: "Manual",
    key: "2"
  },
  {
    value: "custom",
    label: "Mixed",
    key: "3",
    disabled: true
  }
];

class PreparationModeSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      GuestPrepMode: props.GuestPrepMode
    };
  }

  changeGuestPrepMode = (prepMode) => {
    this.setState({
      GuestPrepMode: prepMode.value
    });

    this.props.changeGuestPrepMode(prepMode.value);
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      GuestPrepMode: nextProps.GuestPrepMode
    });
  }

  getPrepModeHelpText = () => {
    const {
      GuestPrepMode
    } = this.state;

    let helpText = PreparationModeHelpText[GuestPrepMode];
    const isAWSorAzureSource = resourceBundle.providers_with_regions.indexOf(this.props.sourceType) > -1;

    if (GuestPrepMode === "auto" && isAWSorAzureSource) {
      let subHelpText = resourceBundle.VM_Preparation.aws_auto_prepare_desc;
      const isAzureSource = resourceBundle.azure_providers_type.indexOf(this.props.sourceType) > -1;

      if (isAzureSource) {
        subHelpText = resourceBundle.VM_Preparation.azure_auto_prepare_desc;
      }

      helpText = this.props.getSSMManageHelp(
        subHelpText,
        "prephelp-link",
        isAzureSource
      );
    }

    return helpText;
  }

  render() {
    const {
      isUnmanagedVMs,
      disableModeSelection,
      sourceType,
      targetType,
      GuestPrepMode,
      ngtInstalled
    } = this.props;

    const selectOptions = JSON.parse(JSON.stringify(PreparationModeOptions));
    const getSelectedPrepMode = selectOptions.find(mode => mode.value === GuestPrepMode);
    const isAzureSource = resourceBundle.azure_providers_type.indexOf(sourceType) > -1;
    const isAHVtoAHV = resourceBundle.aos_providers_type.indexOf(sourceType) > -1 &&
    resourceBundle.aos_providers_type.indexOf(targetType) > -1;

    if (!ngtInstalled && isAHVtoAHV) {
      selectOptions[0].disabled = true;
      const warningMsg = resourceBundle.Warnings.ahv_ahv_disabled_auto_prep_warning;
      selectOptions[0].label = (
        <span>
          { resourceBundle.Common.automatic }
          <span className="status-icon -pre">
            <StatusIcon
              tooltipProps={ {
                content: warningMsg,
                popupPlacement: "right",
                contentProps: {
                  style: {
                    maxWidth: 400
                  }
                }
              } }
              type="warning"
            />
          </span>
        </span>
      );
    }

    if (isUnmanagedVMs) {
      selectOptions[0].disabled = true;
      let warningsMsg = resourceBundle.Warnings.multiple_aws_unmanged_vms_warning;
      if (isAzureSource) {
        warningsMsg = resourceBundle.Warnings.multiple_azure_unmanged_vms_warning;
      }

      selectOptions[0].label = (
        <span>
          { resourceBundle.Common.automatic }
          <span className="status-icon -pre">
            <StatusIcon
              tooltipProps={ {
                content: warningsMsg,
                popupPlacement: "right",
                contentProps: {
                  style: {
                    maxWidth: 400
                  }
                }
              } }
              type="warning"
            />
          </span>
        </span>
      );
    }

    const preparationModeHelpText = this.getPrepModeHelpText();

    return (
      <div>
        <SelectWithLabel
          selectedRow={ getSelectedPrepMode }
          rowsData={ selectOptions }
          onSelectedChange={ this.changeGuestPrepMode }
          disabled={ disableModeSelection }
          id="_uiauto-vm-preparation-page"
          label="Preparation Mode"
          helpText={
            <TextLabel display="block" size="small">
              { preparationModeHelpText }
            </TextLabel>
          }
        />
      </div>
    );
  }

}

export default PreparationModeSettings;
