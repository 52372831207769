import { browserHistory } from "react-router";
import FetchURLConstants from "../data/FetchURLConstants";

const FetchUtil = {
  // Check if token exists
  checkAuthentication(func) {
    if (sessionStorage.getItem("token")) {
      return func;
    }
    browserHistory.push("/");
  },

  // Check if token expired
  checkToken(msg) {
    if (msg === FetchURLConstants.expire_token || msg === FetchURLConstants.invalid_token) {
      sessionStorage.removeItem("token");
      localStorage.removeItem("token");
      browserHistory.push("/");
    }
  },

  checkResponseOk(response) {
    const ok = response.ok;
    if (!ok) {
      const responseObj = response.clone();
      // TODO: If applicable, add in additional default error handling.
      try {
        return responseObj.json().then(responseData => this.checkToken(responseData.message));
        // Do your JSON handling here
      } catch (err) {
        // It is text, do you text handling here
      }
    }
    return ok;
  },

  headers() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("token")
    };
    return headers;
  },

  POST(url, body) {
    return fetch(url, {
      headers: this.headers(),
      method: "POST",
      credentials: "same-origin",
      body:JSON.stringify(body)
    });
  },

  PUT(url, body) {
    return fetch(url, {
      headers: this.headers(),
      method: "PUT",
      credentials: "same-origin",
      body: JSON.stringify(body)
    });
  }
};

export default FetchUtil;
