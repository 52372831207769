import { browserHistory } from "react-router";
import { NotificationTrigger } from "@nutanix-ui/prism-reactjs";
import moment from "moment";
import AppConfig from "../../app-config/AppConfig";
import MOVE_APPLICATION from "../../RestAPI/MOVE_APPLICATION.js";

/* global window */
const FETCH = window.fetch;
const PROMISE = window.Promise;
const SESSIONSTORAGE = window.sessionStorage;
const LOCALSTORAGE = window.localStorage;
const GENERIC_ERROR_MSG = "Encountered an error, please check your network connectivity.";
let ErrorNotifications = 0;
let newAuth = false;

class Request {

  static authorizeApiCall(message) {
    AppConfig.clear();
    SESSIONSTORAGE.removeItem("token");
    LOCALSTORAGE.removeItem("token");
    SESSIONSTORAGE.removeItem("Username");
    SESSIONSTORAGE.removeItem("RefreshToken");
    SESSIONSTORAGE.removeItem("TokenExpiry");
    LOCALSTORAGE.removeItem("iamUUID");
    // LOCALSTORAGE.removeItem("iamUUID");
    browserHistory.push("/");
    if (!ErrorNotifications) {
      NotificationTrigger.add({
        type: "error",
        message: message || GENERIC_ERROR_MSG,
        minMessageLineCount: 3,
        autoDismissDelaySecs: 10
      });
      ErrorNotifications += 1;
      newAuth = false;
    }
  }

  static async refreshIAMToken(IAMID, refreshToken) {
    const payload = {
      id: IAMID,
      grantType: "REFRESH_TOKEN",
      refreshToken
    };
    const refreshTokenPayload = new URLSearchParams(payload).toString();
    return await MOVE_APPLICATION.generateToken(refreshTokenPayload, IAMID)
      .then((response) => {
        const { AccessToken, RefreshToken, Username, Expiry } = response;
        if (AccessToken && RefreshToken && Username) {
          SESSIONSTORAGE.setItem("token", AccessToken);
          LOCALSTORAGE.setItem("token", AccessToken);
          SESSIONSTORAGE.setItem("Username", Username);
          SESSIONSTORAGE.setItem("RefreshToken", RefreshToken);
          SESSIONSTORAGE.setItem("TokenExpiry", Expiry);
        } else {
          Request.authorizeApiCall(response.message || response.Message);
          return PROMISE.reject(response);
        }
      })
      .catch((response) => {
        Request.authorizeApiCall(response.message || response.Message);
        return PROMISE.reject(response);
      });
  }


  static status(response) {
    if (!response.ok) {
      const IAMUUID = LOCALSTORAGE.getItem("iamUUID");
      const refreshToken = SESSIONSTORAGE.getItem("RefreshToken");
      // call only if 401 to check whether session info is expired
      // its logged out
      const { code, status } = response;
      const unauthReq = status === 401 || code === 401;
      const requestedApi = response.url.split("/");
      const isLoginApi = requestedApi[requestedApi.length - 1] === "login";
      if (unauthReq && !isLoginApi) {
        Request.parseJSON(response)
          .then((msg) => {
            const expiredToken = (msg.message || msg.Message).toLowerCase() === "token is expired";
            if (IAMUUID && refreshToken && expiredToken) {
              newAuth = true;
              return Request.refreshIAMToken(IAMUUID, refreshToken);
            }
            Request.authorizeApiCall(msg.message || msg.Message);
          });
      }
      return PROMISE.reject(response);
    }
    newAuth = false;
    ErrorNotifications = 0;
    return response;
  }

  static parseBlob(response) {
    // this is for error case of blob
    // in error case of blob json returened
    if (
      response.headers.get("Content-type") &&
        response.headers.get("Content-type").indexOf("application/json") > -1
    ) {
      return Request.parseJSON(response);
    }
    return response.blob().then((blobData) => {
      return PROMISE.resolve({
        blobData,
        response
      });
    }).catch(() => {
      return PROMISE.reject(response);
    });
  }

  static parseJSON(response) {
    return response.text().then((text) => {
      return text ? JSON.parse(text) : {};
    }).catch(() => {
      return PROMISE.reject(response);
    });
  }

  static getConfig(method) {
    return {
      method,
      credentials: "include",
      headers: {}
    };
  }

  static async isTokenExpired() {
    const currentTime = moment().unix();
    const IAMUUID = LOCALSTORAGE.getItem("iamUUID");
    const refreshToken = SESSIONSTORAGE.getItem("RefreshToken");
    const TokenExpiry = SESSIONSTORAGE.getItem("TokenExpiry");
    return IAMUUID && refreshToken && TokenExpiry && currentTime >= parseInt(TokenExpiry, 10);
  }


  static async requestHandler(method, url, payload, headers, noStringify, blob) {
    const IAMUUID = LOCALSTORAGE.getItem("iamUUID");
    const refreshToken = SESSIONSTORAGE.getItem("RefreshToken");
    if (await Request.isTokenExpired()) {
      await Request.refreshIAMToken(IAMUUID, refreshToken);
    }

    let config = Object.assign({}, Request.getConfig(method));

    if (payload !== null && payload !== undefined) {
      config = Object.assign(config, { body: noStringify ? payload : JSON.stringify(payload) });
    }

    if (headers !== null && headers !== undefined) {
      if (headers.Authorization && IAMUUID && refreshToken) {
        headers.Authorization = sessionStorage.getItem("token");
      }
      config = Object.assign(config, { headers });
    }

    return FETCH(url, config)
      .then(Request.status)
      .then(blob ? Request.parseBlob : Request.parseJSON)
      .catch((error) => {
        let json = {};
        if (newAuth) {
          config.headers.Authorization = SESSIONSTORAGE.getItem("token");
          return FETCH(url, config).then(blob ? Request.parseBlob : Request.parseJSON);
        }
        if (error) {
          try {
            return error.text().then((text) => {
              try {
                json = JSON.parse(text);
              } catch (e) {
                json = {
                  code: 410,
                  message: GENERIC_ERROR_MSG
                };
                Request.redirectToLogin();
              }
              Request.checkTokenExpiry(json);
              return PROMISE.reject(json);
            });
          } catch (e) {
            Request.redirectToLogin();
          }
        }
        Request.redirectToLogin();
      });
  }

  static checkTokenExpiry(response) {
    const isUpdateInProgress = LOCALSTORAGE["move.upgrade"];
    if (response && response.code === 401 &&
      !isUpdateInProgress) {
      SESSIONSTORAGE.removeItem("token");
      LOCALSTORAGE.removeItem("token");
      browserHistory.push("/");
      AppConfig.clear();
    }
  }

  static get(url, headers) {
    return Request.requestHandler("GET", url, null, headers);
  }

  static post(url, payload, headers, noStringify, blob) {
    return Request.requestHandler("POST", url, payload, headers, noStringify, blob);
  }

  static put(url, payload, headers) {
    return Request.requestHandler("PUT", url, payload, headers);
  }

  static delete(url, payload, headers) {
    return Request.requestHandler("DELETE", url, payload, headers);
  }

  static patch(url, payload, headers) {
    return Request.requestHandler("PATCH", url, payload, headers);
  }

  static async redirectToLogin() {
    const IAMUUID = LOCALSTORAGE.getItem("iamUUID");
    const refreshToken = SESSIONSTORAGE.getItem("RefreshToken");
    if (IAMUUID && refreshToken) {
      return await Request.refreshIAMToken(IAMUUID, refreshToken);
    }
    const isUpdateInProgress = LOCALSTORAGE["move.upgrade"];
    if (!ErrorNotifications && !isUpdateInProgress) {
      SESSIONSTORAGE.removeItem("token");
      LOCALSTORAGE.removeItem("token");
      browserHistory.push("/");
      NotificationTrigger.add({
        type: "error",
        message: GENERIC_ERROR_MSG,
        minMessageLineCount: 3,
        autoDismissDelaySecs: 10
      });
      ErrorNotifications += 1;
      AppConfig.clear();
    }
  }

}

export default Request;
