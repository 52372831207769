import React from "react";
import { Modal, Alert, Button, Tabs, UnorderedList, Paragraph,
  TextLabel, Badge, Link, CheckMarkIcon, StackingLayout } from "@nutanix-ui/prism-reactjs";
import "./PermissionRequiredModal.less";

const TabsTitledata = [
  {
    title: "Source",
    key: 1,
    id:"_uiauto-missing-permissions-source-tab"
  },
  {
    title: "Target",
    key: 2,
    id:"_uiauto-missing-permissions-target-tab"
  }
];

class PermissionRequiredModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: ""
    };
  }

  renderFooter() {
    return (
      <div>
        <Button
          id="_uiauto-close-missing-permissions-popup"
          key="submit"
          type="primary"
          onClick={ this.props.onClose }
        >
          Close
        </Button>
      </div>
    );
  }

  displayTabContent = (key, permissionType, permissionTypeErrors) => {
    const {
      Roles
    } = this.props;
    let tabContentHTML = "";

    if (permissionTypeErrors || Roles.indexOf(permissionType) > -1) {
      const permissionErrorLength = permissionTypeErrors && permissionTypeErrors.length;
      tabContentHTML = (
        <StackingLayout
          padding="20px"
          tab={
            <TextLabel>{ permissionType } { permissionErrorLength
              ? <Badge type="tag" count={ permissionErrorLength }/> : ""}
            </TextLabel> } key={ key }
        >
          <UnorderedList data={ permissionTypeErrors || [] }/>
          { !permissionTypeErrors
            ? <TextLabel type="primary">
              <CheckMarkIcon color="green" className="-permission-available"/>
              All the required { permissionType } permissions are present
            </TextLabel> : ""
          }
        </StackingLayout>
      );
    }

    return tabContentHTML;
  }


  handleTabClick = (tabKey) => {
    this.setState({ activeTab: tabKey });
  }

  renderTabContent = (defaultActiveKeyPath, permissionError) => {
    let tabContnet = "";
    const { activeTab } = this.state;
    const enabledTab = activeTab || defaultActiveKeyPath;
    switch (enabledTab) {
      case 1:
        tabContnet = this.displayTabContent("1", "Source", permissionError.Source);
        break;
      case 2:
        tabContnet = this.displayTabContent("2", "Target", permissionError.Target);
        break;
      default:
    }

    return tabContnet;
  }


  showSourceTargetErrors = (permissionError) => {
    const defaultActiveKeyPath = permissionError.Source &&
      permissionError.Source.length ? 1 : 2;
    return (
      <div>
        <Tabs
          data={ TabsTitledata }
          onTabClick={ this.handleTabClick }
          defaultActiveKey={ defaultActiveKeyPath }>
        </Tabs>
        { this.renderTabContent(defaultActiveKeyPath, permissionError)}
      </div>
    );
  }

  validateInventory = () => {
    this.props.onEnvActionSelection({ key:"4" });
    this.props.onClose();
  }


  render() {
    const {
      permissionError
    } = this.props;

    return (
      <Modal
        onCancel={ this.props.onClose }
        onClose={ this.props.onClose }
        visible={ true }
        title="Missing Permissions"
        footer={ this.renderFooter() }
        className="missing-permission-modal"
        contentClassName="ntnx-modal-content"
        maskClosable={ false }
        width={ 550 }
      >
        <div id="_uiauto-missing-permissions-popup">
          <Alert
            showCloseIcon={ false }
            type="warning"
            message={ permissionError.ErrorMsg }
          />
          { this.showSourceTargetErrors(permissionError) }
          <Alert
            showCloseIcon={ false }
            type="supplementary"
            padding="15px"
            message={
              <Paragraph type="supplementary">
                Note: Once you resolve these issues Validate it
                <Link onClick={ this.validateInventory }>here </Link>
                or go to dashboard and click on 3 dots icon.
              </Paragraph>
            }
          />
        </div>
      </Modal>
    );
  }

}

export default PermissionRequiredModal;
