
import React from "react";
import autoscroll from "autoscroll-react";
import AppUtil from "../../../tools/AppUtil";


const LOGCOLORSCATEGORIES = {
  httpserver: "color1",
  srcagent: "color2",
  mgmtserver: "color8",
  diskreader: "color4",
  diskwriter: "color8",
  tgtagent: "color10",
  updater: "color12",
  postgres: "color13",
  etcd: "color14",
  eventmanager: "color3",
  diskreader_vddk601: "color5",
  filesagent: "color6"
};


class List extends React.Component {

  render() {
    const { items, selectedContainers } = this.props;
    const filterObject = {
      containerName: selectedContainers
    };

    const filteredLogs = AppUtil.filterPlainArrayWithMultipleProps(items, filterObject);

    return (
      <div className="messages body-block" { ...this.props }>
        <div className="msg">
          {
            filteredLogs.map((log) => {
              const containerName = log.containerName;
              const logClassName = LOGCOLORSCATEGORIES[containerName];
              return (
                <p key={ log.key }>
                  <span className={ `stream ${logClassName} color3` }>
                    {containerName}
                  </span>
                  <span className="message">
                    {log.Data}
                  </span>
                </p>
              );
            })
          }
        </div>
      </div>
    );
  }

}


export default autoscroll(List);
