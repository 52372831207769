import React from "react";
import { Modal, Button, StackingLayout, FlexLayout,
  TextLabel, Title, Alert, Loader, ContainerLayout, EditIcon,
  FormItemSelect, RefreshIcon, Input, Select, AlertLayout,
  UnorderedList, Tooltip } from "@nutanix-ui/prism-reactjs";
import classNames from "classnames";
import MIGRATION from "../../../RestAPI/migration";
import conversion from "../../../tools/conversion.js";
// import SOURCE from "../../../RestAPI/source";
import AppUtil from "../../../tools/AppUtil";
import LoaderModal from "../../presentational/loader-modal/";
import "./VMConfigModal.less";

const resourceBundle = AppUtil.getI18nJSONResourceBundle();
const FormattedEntityValues = {
  PoweredOn: "On",
  PoweredOff: "Off"
};
const PowerStateOptions = [
  {
    key: 1,
    label: "Off",
    value: "PoweredOff"
  },
  {
    key: 2,
    label: "On",
    value: "PoweredOn"
  }
];

const CustomizationTypeData = [
  {
    key: 1,
    label: "Configure Target VM Properties",
    value: "static"
  },
  {
    key: 2,
    label: "Retain Source VM Properties",
    value: "replicate"
  }
];

class VMConfigModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      vmProperties: null,
      showLoader: true,
      refreshingVMProperties: false,
      errorMessage: "",
      updatedVMConfig:{},
      vmConfig: {},
      VMCustomizationType: {
        value: "static"
      },
      showErrorMessage: false
    };
  }

  componentWillMount() {
    this.setState({
      vmConfig: this.props.vmConfig
    }, () => {
      this.fetchVMProperties();
    });
  }


  componentWillReceiveProps(nextProps) {
    this.setState({
      vmConfig: nextProps.vmConfig
    });
  }

  /**
  * This function takes in an object called localVMData and formats it for display.
  */
  getFormattedVMConfig = (localVMData) => {
    let formattedEntityData = null;

    if (localVMData.CustomProperties) {
      const { not_available } = resourceBundle.Common;
      formattedEntityData = localVMData.CustomProperties;
      formattedEntityData.displayData = [];
      const entityType = formattedEntityData.Type;
      const entityInfo = formattedEntityData[entityType];
      const sourceEntity = entityInfo.Source;
      const targetEntity = entityInfo.Target;
      const targetCustomizableProperties = targetEntity.CustomizableProperties || [];

      entityInfo.OrderOfProperties.forEach((entity, index) => {
        const sourceProperties = sourceEntity.GenericVmCustomProperties;
        const targetProperties = targetEntity.GenericVmCustomProperties;
        const formattedSourceEntityName = FormattedEntityValues[sourceProperties[entity.SourceName]] ||
            sourceProperties[entity.SourceName];
        const formattedTargetEntityName = FormattedEntityValues[targetProperties[entity.TargetName]] ||
            targetProperties[entity.TargetName];
        let sourceValue = formattedSourceEntityName
          ? `${formattedSourceEntityName}` : not_available;
        let targetValue = formattedTargetEntityName
          ? `${formattedTargetEntityName}` : not_available;

        if ([entity.SourceName, entity.TargetName].indexOf("Memory") > -1) {
          if (sourceValue !== not_available) {
            sourceValue = conversion.bytesToGiB(sourceValue);
          }
          if (targetValue !== not_available) {
            targetValue = conversion.bytesToGiB(targetValue);
          }
        }

        entity.sourceValue = sourceValue;
        entity.targetValue = targetValue;
        entity.nonFormattedTargetValue = targetProperties[entity.TargetName];
        entity.isEditable = targetCustomizableProperties.indexOf(entity.TargetName) > -1;

        formattedEntityData.displayData.push(entity);
      });
    }

    return formattedEntityData;
  }

  /* following function validates the input field list and tests
    * if field type is "Name" then it validates its valid string on top of trimming the input
    * if field type is "NumvCPUs", "NumCoresPerSocket" or "Memory" then its should be a valid number and greater than zero
    * if field type is "NumvCPUs" or "NumCoresPerSocket" then it should not accept decimal values
  */

  getInvalidUserInputFields = () => {
    const invalidInputFields = [];
    const { updatedVMConfig } = this.state;
    const { vm_config_validation_error_by_type } = resourceBundle;
    Object.keys(updatedVMConfig).forEach(key => {
      const type = key;
      const value = updatedVMConfig[key];
      const errorMessage = vm_config_validation_error_by_type[type];

      if (type === "Name") {
        const trimmedNameLength = value.trim().length;
        if (trimmedNameLength === 0 || trimmedNameLength > 80) {
          invalidInputFields.push(errorMessage);
        }
      } else if (["NumvCPUs", "NumCoresPerSocket", "Memory"].includes(type)) {
        if (isNaN(value) || value <= 0 || (type === "NumvCPUs" || type === "NumCoresPerSocket") && value % 1 !== 0) {
          invalidInputFields.push(errorMessage);
        }
      }
    });

    if (invalidInputFields.length) {
      this.showInvalidConfigFieldsBlock(invalidInputFields);
    }

    return invalidInputFields;
  }


  showInvalidConfigFieldsBlock = (configFields) => {
    const errorMessage = (
      <UnorderedList data={ configFields } />
    );

    this.setState({
      showErrorMessage: true,
      errorMessage
    });
  }


  // calls update api with changed VM config payload
  updateVMConfig = () => {
    const isValidEntry = this.getInvalidUserInputFields();
    if (!isValidEntry.length) {
      const { updating_vm_config } = resourceBundle.Information;

      this.setState({
        showErrorMessage: false,
        errorMessage: "",
        showLoaderModal: true,
        loaderModalDescription: updating_vm_config
      }, () => {
        const { vmConfig } = this.state;
        const formattedUpdateVMConfigBody = this.formatVMConfigUpdateReqBody();

        return MIGRATION.updateVMProperties(vmConfig.planUUID, vmConfig.vmID, formattedUpdateVMConfigBody)
          .then(() => {
            this.fetchVMProperties();
          })
          .catch((response) => {
            this.setState({
              showErrorMessage: true,
              errorMessage: response.Message || response.message
            }, () => {
              this.resetLoaderModal();
            });
          });
      });
    }
  }


  getVMCustomizationType = (vmConfig) => {
    const customProperties = vmConfig.CustomProperties;
    const entityType = customProperties.Type;
    const entityInfo = customProperties[entityType];

    const VMCustomizationType = CustomizationTypeData.find(customization =>
      customization.value === (entityInfo.VMCustomizeType || "static")
    );

    return VMCustomizationType;
  }


  fetchVMProperties = () => {
    const { vmConfig } = this.state;
    const { fetching_vm_info } = resourceBundle.Information;
    this.setState({
      showLoader: true,
      showLoaderModal: true,
      loaderModalDescription: fetching_vm_info,
      updatedVMConfig: {}
    });

    return MIGRATION.getVMProperties(vmConfig.planUUID, vmConfig.vmID)
      .then((response) => {
        const VMCustomizationType = this.getVMCustomizationType(response);
        this.setState({
          rawVMConfig: response,
          localVMConfig: JSON.parse(JSON.stringify(response)),
          showLoader: false,
          VMCustomizationType,
          VMSettingsChanged: false
        }, () => {
          this.resetLoaderModal();
        });
      })
      .catch((response) => {
        this.setState({
          showErrorMessage: true,
          errorMessage: response.Message || response.message,
          showLoader: false,
          VMSettingsChanged: false
        }, () => {
          this.resetLoaderModal();
        });
      });
  }


  // Revert to default customization settings or undo any user-modified configurations.
  cancelVMConfigUpdate = () => {
    const { rawVMConfig } = this.state;
    const VMCustomizationType = this.getVMCustomizationType(rawVMConfig);

    this.setState({
      showErrorMessage: false,
      updatedVMConfig: {},
      VMSettingsChanged: false,
      VMCustomizationType,
      localVMConfig: JSON.parse(JSON.stringify(rawVMConfig))
    });
  }

  renderFooter = () => {
    const { VMSettingsChanged } = this.state;

    return (
      <FlexLayout itemSpacing="10px" justifyContent="flex-end">
        <Button
          id="customization-close-button"
          type="secondary"
          className={ classNames({ "hide-action-buttoon": VMSettingsChanged }) }
          onClick={ this.props.onClose }
        >
          Close
        </Button>

        <Button
          id="customization-cancel-button"
          type="secondary"
          className={ classNames({ "hide-action-buttoon": !VMSettingsChanged }) }
          onClick={ this.cancelVMConfigUpdate }
        >
          Cancel
        </Button>

        <Button
          id="customization-apply-button"
          type="primary"
          className={ classNames({ "hide-action-buttoon": !VMSettingsChanged }) }
          onClick={ this.updateVMConfig }
        >
          Apply
        </Button>
      </FlexLayout>
    );
  }

  resetLoaderModal = () => {
    this.setState({
      showLoaderModal: false,
      loaderModalDescription: ""
    });
  }


  refreshVMProperties = () => {
    const { vmConfig } = this.state;

    this.setState({
      refreshingVMProperties: true,
      showLoaderModal: true,
      loaderModalDescription: resourceBundle.Environment.refreshing_vm_property
    });

    return MIGRATION.refreshVMProperties(vmConfig.planUUID, vmConfig.vmID)
      .then(() => {
        this.fetchVMProperties();
      })
      .catch((response) => {
        this.setState({
          showErrorMessage: true,
          errorMessage: response.Message || response.message
        }, () => {
          this.resetLoaderModal();
        });
      })
      .finally(() => {
        this.setState({
          refreshingVMProperties: false,
          VMSettingsChanged: false
        });
      });
  }


  formatVMConfigUpdateReqBody = () => {
    const {
      localVMConfig,
      updatedVMConfig,
      VMCustomizationType
    } = this.state;
    const customType = VMCustomizationType.value;
    const reqBody = JSON.parse(JSON.stringify(localVMConfig));

    // Change the config value only if customization type is static or settings changed.
    if (customType === "static") {
      const targetCustomizationConfig = reqBody.CustomProperties.VmCustomPropertiesInfo.Target.GenericVmCustomProperties;
      Object.keys(updatedVMConfig).forEach(key => {
        const input = updatedVMConfig[key];
        targetCustomizationConfig[key] = input;
        if (key === "Memory") {
          targetCustomizationConfig[key] = conversion.GiBToBytes(input);
        } else if (key === "Name") {
          targetCustomizationConfig[key] = input.trim();
        } else if (["NumvCPUs", "NumCoresPerSocket"].includes(key)) {
          targetCustomizationConfig[key] = parseInt(input, 10);
        }
      });
    }

    return reqBody;
  }


  handleOnVMCustomizationTypeChange = (selectedVMCustomType) => {
    const { rawVMConfig } = this.state;
    const localVMConfig = JSON.parse(JSON.stringify(rawVMConfig));
    localVMConfig.CustomProperties.VmCustomPropertiesInfo.VMCustomizeType = selectedVMCustomType.value;

    if (selectedVMCustomType.value === "replicate") {
      const sourceGenericProps = localVMConfig.CustomProperties.VmCustomPropertiesInfo.Source.GenericVmCustomProperties;
      const targetGenericProps = localVMConfig.CustomProperties.VmCustomPropertiesInfo.Target.GenericVmCustomProperties;
      // replicate only following properties
      const propertiesToReplicate = ["Name", "PowerState", "NumvCPUs", "NumCoresPerSocket", "Memory"];

      propertiesToReplicate.forEach((property) => {
        targetGenericProps[property] = sourceGenericProps[property];
      });
    }

    this.setState({
      VMCustomizationType: selectedVMCustomType,
      VMSettingsChanged: true,
      localVMConfig
    });
  }

  onClickEditProperty = (entity) => {
    const { updatedVMConfig } = this.state;
    if (entity.TargetName === "Memory") {
      updatedVMConfig[entity.TargetName] = entity.targetValue;
    } else {
      updatedVMConfig[entity.TargetName] = entity.nonFormattedTargetValue;
    }

    this.setState({
      updatedVMConfig,
      VMSettingsChanged: true
    });
  }

  renderViewInputBlock = (entity) => {
    const { targetValue } = entity;
    const { updatedVMConfig } = this.state;
    const attributeName = entity.TargetName;
    const customValue = updatedVMConfig[attributeName];
    let formattedFixedValue = "";
    if (entity.TargetName === "Memory") {
      formattedFixedValue = conversion.formatDecimal(targetValue, 3);
    }

    let viewInputBlockHTML = (
      <div className="editable edit-spacing">
        <TextLabel
          id="edit-config-value"
          title={ targetValue }
          type="primary"
          className="editable-value">
          { formattedFixedValue || targetValue }
        </TextLabel>
        <div
          id="edit-config-icon"
          onClick={ () => this.onClickEditProperty(entity) }
          className="edit-save-icon">
          <EditIcon />
        </div>
      </div>
    );

    if (customValue || customValue === "") {
      viewInputBlockHTML = (
        <div className="editable edit-spacing">
          { this.userInputViewCategory(customValue, attributeName, entity) }
        </div>
      );
    }

    return viewInputBlockHTML;
  }


  getFormattedPowerState = (updatedValue, originalValue) => {
    const propertyState = updatedValue || originalValue;
    return PowerStateOptions.find(state =>
      state.value === propertyState
    );
  }


  userInputViewCategory = (customValue, attributeName, entity) => {
    const { updatedVMConfig } = this.state;
    let userInputViewCategoryHTML = "";
    const updatedConfigValue = updatedVMConfig[attributeName];
    if (attributeName === "Name") {
      userInputViewCategoryHTML = (
        <Input
          id="editable-config-name"
          className="fixed-width-input"
          placeholder="e.g. VM Apache"
          value={ customValue }
          onChange={ (e) => this.changeVMConfig(e, attributeName) }
        />
      );
    } else if (attributeName === "PowerState") {
      const filteredPowerState = this.getFormattedPowerState(updatedConfigValue, entity.nonFormattedTargetValue);

      userInputViewCategoryHTML = <Select
        className="fixed-width-input"
        id="editable-config-power-state"
        rowsData={ PowerStateOptions }
        selectedRow={ filteredPowerState }
        onSelectedChange={ (e) => this.changeVMConfig(e, attributeName) }
      />;
    } else if (attributeName === "Memory") {
      userInputViewCategoryHTML = (
        <Input
          id="editable-config-power-memory"
          className="fixed-width-input"
          type="number"
          placeholder="size in GiB"
          value={ customValue }
          onChange={ (e) => this.changeVMConfig(e, attributeName) }
          suffix={ <TextLabel type="secondary">GiB</TextLabel> }
        />
      );
    } else if (["NumvCPUs", "NumCoresPerSocket"].indexOf(attributeName) > -1) {
      userInputViewCategoryHTML = (
        <Input
          id="editable-config-vcpu-cores"
          className="fixed-width-input"
          type="number"
          min={ 1 }
          placeholder="enter value in number"
          value={ customValue }
          onChange={ (e) => this.changeVMConfig(e, attributeName) }
        />
      );
    }

    return userInputViewCategoryHTML;
  }


  changeVMConfig = (e, attributeName) => {
    const { updatedVMConfig } = this.state;
    if (attributeName === "PowerState") {
      updatedVMConfig[attributeName] = e.value;
    } else {
      updatedVMConfig[attributeName] = e.target.value;
    }

    this.setState({
      updatedVMConfig
    });
  }

  showEditableBlock = (entity, isEditable) => {
    const { targetValue } = entity;
    let formattedFixedValue = "";
    if (entity.TargetName === "Memory") {
      formattedFixedValue = conversion.formatDecimal(targetValue, 3);
    }
    let editableHTMLBlock = (
      <TextLabel
        id="target-raw-config-value"
        className={ classNames(
          "title",
          { "edit-spacing": isEditable }
        ) }
        type="primary"
        title={ targetValue }>
        { formattedFixedValue || targetValue }
      </TextLabel>
    );

    if (isEditable && entity.isEditable) {
      editableHTMLBlock = this.renderViewInputBlock(entity);
    }

    return editableHTMLBlock;
  }


  isCustomizationAllowed = () => {
    const {
      VMCustomizationType,
      vmConfig
    } = this.state;

    // VM customization should not be allowed if the VM is in a state beyond
    // the cutover ready point, an error state, or if the customization type is set to static.
    return !(resourceBundle.vm_customization_not_allowed_states
      .includes(vmConfig.VMMigrationStatus)) && VMCustomizationType.value === "static";
  }


  displayVMPropertiesBody = () => {
    const {
      showLoader,
      VMCustomizationType,
      refreshingVMProperties,
      localVMConfig = {},
      errorMessage,
      vmConfig,
      VMSettingsChanged
    } = this.state;

    let VMPropertiesBodyHTML = "";
    if (showLoader) {
      VMPropertiesBodyHTML =
      <FlexLayout style={ { height: "200px" } } alignItems="center" justifyContent="center">
        <Loader
          className="generic-loader"
          loading={ showLoader } tip="Please wait..."
        />
      </FlexLayout>;
      return VMPropertiesBodyHTML;
    }

    const vmProperties = this.getFormattedVMConfig(localVMConfig);

    if ((!vmProperties || !vmProperties.displayData) && !showLoader && !errorMessage) {
      return <FlexLayout style={ { height: "200px" } } alignItems="center" justifyContent="center">
        Failed to fetch VM details
      </FlexLayout>;
    }

    const isCustomizationTypeChangeAllowed = !(resourceBundle.vm_customization_not_allowed_states
      .includes(vmConfig.VMMigrationStatus));
    const isFieldEditable = this.isCustomizationAllowed();
    const disabledRefreshState = resourceBundle.vm_refresh_not_allowed_states
      .includes(vmConfig.VMMigrationStatus);
    const disabledRefreshButton = disabledRefreshState || refreshingVMProperties ||
      VMSettingsChanged;

    VMPropertiesBodyHTML = (
      <StackingLayout padding="30px"
        style={ { background: "#F2F4F6" } }
      >
        <FlexLayout justifyContent="space-between">
          <FormItemSelect
            label={ resourceBundle.Information.vm_migration_type_setting_label }
            style={ { minWidth: "235px" } }
            selectedRow={ VMCustomizationType }
            rowsData={ CustomizationTypeData }
            onSelectedChange={ this.handleOnVMCustomizationTypeChange }
            inputProps={ { appearance: "borderless" } }
            disabled={ !isCustomizationTypeChangeAllowed }
            id="customization-type-toggle"
          />

          <Tooltip
            content={ `Refresh is not allowed when VM is in "${vmConfig.VMMigrationStatus}" state.` }
            popupPlacement="bottom"
            className={ classNames({ "hidden-tooltip": (!disabledRefreshState || refreshingVMProperties) }) }
          >
            <Button
              id="refresh-source-properties"
              onClick={ this.refreshVMProperties }
              type="borderless"
              className="top-spacing"
              disabled={ disabledRefreshButton }>
              <RefreshIcon /> Refresh Source VM Properties
            </Button>
          </Tooltip>
        </FlexLayout>

        <ContainerLayout
          backgroundColor="white"
          padding="10px">
          <StackingLayout itemSpacing="20px" >
            <StackingLayout>
              <FlexLayout itemFlexBasis="100pc">
                <Title size="h3"> Property </Title>
                <Title size="h3" className="align-right">
                  On Source
                </Title>
                <Title
                  className={ classNames("align-right", { "edit-spacing": isFieldEditable }) }
                  size="h3">
                  On Target
                </Title>
              </FlexLayout>
            </StackingLayout>
            { vmProperties.displayData.map((entity, index) => {
              const {
                DisplayName,
                sourceValue
              } = entity;

              let formattedFixedValue = "";
              if (DisplayName === "Memory") {
                formattedFixedValue = conversion.formatDecimal(sourceValue, 3);
              }
              const formattedDisplayName = DisplayName === "Memory" ? "Memory(GiB)" : DisplayName;
              return (
                <StackingLayout className="description-container">
                  <FlexLayout itemFlexBasis="100pc">
                    <TextLabel> { formattedDisplayName }</TextLabel>
                    <TextLabel
                      className="title source-row align-right"
                      type="primary"
                      title={ sourceValue }>
                      { formattedFixedValue || sourceValue }
                    </TextLabel>
                    <FlexLayout className="target-row align-right">
                      { this.showEditableBlock(entity, isFieldEditable) }
                    </FlexLayout>
                  </FlexLayout>
                </StackingLayout>
              );
            })}
          </StackingLayout>
        </ContainerLayout>
      </StackingLayout>
    );

    return VMPropertiesBodyHTML;
  }


  showLoaderModal = () => {
    const {
      showLoaderModal,
      loaderModalDescription
    } = this.state;
    let loaderModalHTML = "";

    if (showLoaderModal) {
      loaderModalHTML = (
        <LoaderModal
          visible={ showLoaderModal }
          loaderDescription={ loaderModalDescription }
        />
      );
    }

    return loaderModalHTML;
  }


  showCustomizationNotAllowedWarning = () => {
    const { vmConfig = {} } = this.state;
    const isCustomizationAllowed = !(resourceBundle.vm_customization_not_allowed_states
      .includes(vmConfig.VMMigrationStatus));
    let warningsMsg = "";

    if (!isCustomizationAllowed) {
      warningsMsg = (<Alert
        visible={ true }
        showCloseIcon={ false }
        type="warning"
        message={ `Update of the given set of VM properties, in the current VM
          state: "${vmConfig.VMMigrationStatus}" is not supported.` }
      />);
    }

    return warningsMsg;
  }


  showErrorMessageBlock = (showMsg, errorMessage) => {
    let errorMessageHTML = "";
    if (showMsg) {
      errorMessageHTML = (
        <Alert
          visible={ showMsg }
          showCloseIcon={ false }
          type="error"
          message={
            <AlertLayout>
              { errorMessage || "Failed to fetch VM details." }
            </AlertLayout>
          }
        />
      );
    }

    return errorMessageHTML;
  }

  render() {
    const VMConfigModalHTML = "";
    const {
      visible,
      onClose
    } = this.props;
    const {
      showLoaderModal,
      errorMessage,
      VMCustomizationType,
      showErrorMessage
    } = this.state;

    if (!visible) {
      return VMConfigModalHTML;
    }

    const {
      static_vm_config_help_message,
      replicate_vm_config_help_message
    } = resourceBundle.Information;
    let helpMessage = static_vm_config_help_message;
    const showInfoAlert = !!(!errorMessage && !showLoaderModal);

    if (VMCustomizationType.value !== "static") {
      helpMessage = replicate_vm_config_help_message;
    }

    return (
      <Modal
        visible={ visible }
        title="VM Configurations"
        footer={ this.renderFooter() }
        className="vms-details-modal"
        maskClosable={ false }
        width={ 700 }
        onClose={ onClose }
      >

        { this.showCustomizationNotAllowedWarning() }

        <Alert
          visible={ showInfoAlert }
          showCloseIcon={ false }
          type="info"
          message={ helpMessage }
        />

        { this.showErrorMessageBlock(showErrorMessage, errorMessage) }
        { this.displayVMPropertiesBody()}
        { this.showLoaderModal() }
      </Modal>
    );
  }

}

export default VMConfigModal;
