import React from "react";
import { Modal, Button, StackingLayout, FlexLayout,
  Loader, Alert } from "@nutanix-ui/prism-reactjs";
import MIGRATION from "../../../RestAPI/migration";
import SOURCE from "../../../RestAPI/source";
import AppUtil from "../../../tools/AppUtil";
import "./VMToMigrateModal.less";

const resourceBundle = AppUtil.getI18nJSONResourceBundle();

class VMToMigrateModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sourceVMs: [],
      planID: props.planID
    };
  }

  componentWillMount() {
    this.setState({
      planID: this.props.planID,
      sourceVMs: this.props.sourceVMs,
      showLoader: !!this.props.planID,
      sourceID: this.props.sourceID
    }, () => {
      this.getVMsList();
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      planID: nextProps.planID,
      sourceVMs: nextProps.sourceVMs,
      showLoader: !!nextProps.planID,
      sourceID: nextProps.sourceID
    });
  }

  getTableHeaders = () => {
    let tableHeaderHTML = "";
    let tableHeaderColumns = resourceBundle.tableHeaderVMsToMigrate[this.props.sourceType];
    if (!tableHeaderColumns) {
      tableHeaderColumns = resourceBundle.tableHeaderVMsToMigrate.Common;
    }

    tableHeaderHTML = tableHeaderColumns.map((columnName, index) => {
      return (
        <th key={ index }>
          { columnName }
        </th>
      );
    });
    return tableHeaderHTML;
  }

  getVMsList = () => {
    const {
      sourceID,
      planID
    } = this.state;

    if (sourceID && planID) {
      return SOURCE.getSourceSummary(sourceID)
        .then((sourceInventory) => {
          return MIGRATION.getPlanWorkLoads(this.state.planID)
            .then((response) => {
              const sourceVMs = this.formatVMDetails(response.Status.VMStatus, sourceInventory.Entities);
              this.setState({
                sourceVMs,
                showLoader: false
              });
            })
            .catch((response) => {
              this.vmDetailsFailureMessage(response);
            });
        })
        .catch((response) => {
          this.vmDetailsFailureMessage(response);
        });
    }
  }

  vmDetailsFailureMessage = (response) => {
    this.setState({
      VMStatusFailed: response.Message,
      showLoader: false
    });
  }

  formatVMDetails = (addedVMs, sourceInventory) => {
    let newAddedVMs = addedVMs.map((vm) => {
      vm.VMName = vm.VMName || vm.Name;
      const newVM = sourceInventory.find((ele) => {
        return (vm.UUID === (ele.UUID || ele.VMUuid)) || (vm.ID === ele.VmID);
      });

      if (newVM) {
        vm.DatacenterName = newVM.DatacenterName;
        vm.PowerState = newVM.PowerState;
        vm.VmID = newVM.VmID;
      }
      return vm;
    });

    newAddedVMs = newAddedVMs.sort(
      AppUtil.sortByProperty("VMName", false,
        function(name) {
          return name.toLowerCase();
        })
    );

    return newAddedVMs;
  }

  renderFooter() {
    return (
      <div>
        <Button
          key="submit"
          type="primary"
          onClick={ this.props.closeVMsToMigrateModal }
        >
          Close
        </Button>
      </div>
    );
  }

  displayAddedVMs = () => {
    if (this.state.sourceVMs.length) {
      const {
        not_available
      } = resourceBundle.Common;

      return this.state.sourceVMs.map((vm, index) => {
        const VMPowerState = vm.PowerState
          ? resourceBundle.power_state[vm.PowerState] : "";
        return (
          <tr key={ index } className="title">
            <td title={ vm.VMName || vm.Name }>
              { vm.VMName || vm.Name || not_available }
            </td>
            {
              resourceBundle.aws_providers_type.indexOf(this.props.sourceType) > -1
                ? <td title={ vm.VmID } className="title">
                  { vm.VmID || not_available }
                </td>
                : <td title={ vm.DatacenterName || vm.Datacenter } className="title">
                  { vm.DatacenterName || vm.Datacenter || not_available }
                </td>
            }

            <td title={ VMPowerState } className="title">
              { VMPowerState || not_available }
            </td>
          </tr>
        );
      });
    }
  }
  render() {
    return (
      <Modal
        afterClose={ this.props.closeVMsToMigrateModal }
        visible={ this.props.visible }
        title="VM Details"
        footer={ this.renderFooter() }
        contentClassName="vms-to-migrate-modal"
        maskClosable={ false }
        width={ 500 }
        onClose={ this.props.closeVMsToMigrateModal }
      >

        <FlexLayout alignItems="center" justifyContent="center">
          {
            this.state.VMStatusFailed
              ? <Alert
                type="error"
                showCloseIcon={ false }
                message={ this.state.VMStatusFailed }>
              </Alert>
              : <Loader tip="loading..." loading={ this.state.showLoader }>
                <StackingLayout padding="20px">
                  <div className="ntnx ntnx-table" data-border="true" data-background="base">
                    <div className="table-main-section">
                      <div className="table-header">
                        <table>
                          <thead>
                            <tr className="shared-cell small-column-vm-name">
                              {
                                this.getTableHeaders()
                              }
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-body">
                        <table>
                          <tbody className="ant-table-tbody">
                            { this.displayAddedVMs() }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </StackingLayout>
              </Loader>
          }
        </FlexLayout>
      </Modal>
    );
  }

}

export default VMToMigrateModal;
