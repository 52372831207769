import React from "react";
import { ConfirmModal, StackingLayout, TextLabel,
  FlexLayout, Loader } from "@nutanix-ui/prism-reactjs";
import "./LoaderModal.less";

class LoaderModal extends React.Component {

  render() {
    const {
      visible,
      loaderTitle,
      loaderDescription
    } = this.props;

    return (
      <ConfirmModal
        visible={ visible }
        className="loader-modal -no-footer -center-align-tip"
        maskClosable={ false }
        width={ 360 }
      >
        <div className="loader-modal-body">
          <FlexLayout alignItems="center" justifyContent="center">
            {
              loaderTitle
                ? <StackingLayout>
                  <Loader loading={ true } tip={ loaderTitle } />
                  <TextLabel>{ loaderDescription }</TextLabel>
                </StackingLayout>
                : <StackingLayout className="inline-loader">
                  <TextLabel>
                    <Loader loading={ true }/>
                    { loaderDescription }
                  </TextLabel>
                </StackingLayout>
            }
          </FlexLayout>
        </div>
      </ConfirmModal>
    );
  }

}


export default LoaderModal;
