// Copyright 2019 Nutanix
import AppConfig from "../../../app-config/AppConfig";
/**
 * Check wether CheckEULAConfig
 * @param {obj} nextState the nextState to be executed.
 * @param {func} replace  path to be replace.
 * @param {func} cb callback function to be executed
 */
export function CheckEULAConfig(nextState, replace, cb) {
  const appConfigData = AppConfig.get();
  if (!appConfigData.EulaAccepted) {
    replace({
      pathname: "/"
    });
  }
  cb();
}
