import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, TextLabel, ChevronLeftIcon, ChevronRightIcon, FlexLayout } from "@nutanix-ui/prism-reactjs";


const propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  className: PropTypes.string,
  itemsPerPage: PropTypes.number
};

class Pagination extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pager: {},
      items: [],
      itemsPerPage: props.itemsPerPage || 10
    };
  }

  componentWillMount() {
    this.setState({ items: this.props.items });
    this.setPage(this.props.initialPage);
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.items && nextProps.items &&
      JSON.stringify(this.props.items) !== JSON.stringify(nextProps.items)) ||
      (this.props.itemsPerPage !== nextProps.itemsPerPage)) {
      this.setPage(this.props.initialPage, nextProps);
    }
  }


  setPage(page, nextProps = this.props, pageChangeFlag = false) {
    const items = nextProps.items;
    let pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, page, nextProps.itemsPerPage);

    // get new page of items from items array
    const pageOfVMsTable = items.slice(pager.startIndex, pager.endIndex + 1);

    // update state
    this.setState({ pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfVMsTable, pager.totalItems, pageChangeFlag);
  }

  getPager(totalItems, currentPage = 1, pageSize = 10) {
    // default to first page
    // default page size is 10

    // calculate total pages
    let startPage, endPage;

    const totalPages = Math.ceil(totalItems / pageSize) || 1;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex
    };
  }

  render() {
    if (!this.props.show) {
      return false;
    }

    const pager = this.state.pager;

    const isPrevDisabled = pager.currentPage === 1;
    const isNextDisabled = pager.currentPage === pager.totalPages;
    const prevButtonType = isPrevDisabled ? "secondary" : "primary";
    const nextButtonType = isNextDisabled ? "secondary" : "primary";

    return (
      <FlexLayout
        className={ classNames("ntnx ntnx-pagination", this.props.className) }
        alignItems="center"
        itemSpacing="10px">
        <Button
          appearance="mini"
          disabled={ isPrevDisabled }
          type={ prevButtonType } onClick={ () => this.setPage(pager.currentPage - 1, this.props, true) }>
          <ChevronLeftIcon/>
        </Button>

        <TextLabel>{pager.startIndex + 1} - { pager.endIndex + 1} of { pager.totalItems }</TextLabel>

        <Button
          appearance="mini"
          onClick={ () => this.setPage(pager.currentPage + 1, this.props, true) }
          disabled={ isNextDisabled }
          type={ nextButtonType }>
          <ChevronRightIcon/>
        </Button>
      </FlexLayout>
    );
  }

}

Pagination.defaultProps = {
  initialPage: 1,
  itemsPerPage: 10,
  show: true
};

Pagination.propTypes = propTypes;
export default Pagination;
