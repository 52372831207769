import { SOURCES as SOURCES_API } from "../data/DataURLConstants";
import FetchUtil from "../tools/FetchUtil";
import Request from "../api/Request-Interface/Request.js";

const SOURCE = {

  allSources() {
    const getSourceReqBody = {
      Role: 1
    };
    return Request.post("/move/v2/providers/list", getSourceReqBody, FetchUtil.headers());
  },

  getSource(id) {
    return Request.get(`/move/v2/providers/${id}`, FetchUtil.headers());
  },

  getInventoryOfAllSources() {
    return Request.get(SOURCES_API.inventory, FetchUtil.headers());
  },

  addSource(sourceDetails) {
    return Request.post("/move/v2/providers", sourceDetails, FetchUtil.headers());
  },

  updateSource(sourceDetails, sourceID) {
    return Request.put(`/move/v2/providers/${sourceID}`, sourceDetails,
      FetchUtil.headers());
  },

  removeSource(sourceID) {
    return Request.delete(`/move/v2/providers/${sourceID}`, null, FetchUtil.headers());
  },

  getSourceSummary(sourceId) {
    const formData = {
      Filter: {}
    };
    return Request.post(`/move/v2/providers/${sourceId}/workloads/list`, formData, FetchUtil.headers());
  },

  deployxtractlite(sourceId, regionDetails) {
    return Request.post(`${SOURCES_API.url}/${sourceId}/deployxtractlite`,
      regionDetails, FetchUtil.headers());
  },

  getGuestScripts(url) {
    return Request.get(url, FetchUtil.headers());
  },

  poweronvms(sourceId, regionId, vms) {
    return Request.post(`/move/v2/sources/${sourceId}/regions/${regionId}/poweronvms`,
      vms, FetchUtil.headers());
  },

  isSourceEsx(sourceType) {
    // Keep in sync with sourceTypeMap in srcprops.go.
    return sourceType === "ESXi" || sourceType === "ESX Host";
  }

};

export default SOURCE;
