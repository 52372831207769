
import React from "react";
import { Modal, Button, StackingLayout } from "@nutanix-ui/prism-reactjs";
import NetworkConfiguration from "../../containers/network-configuration/";
import "./NetworkMappingModal.less";

class NetworkMappingModal extends React.Component {

  enableNextButton = () => {
    const validSelection = this.props.varifyNetworkMapSelection();

    // Check if all network mappings are selected and valid
    if (this.props.networkMapping.length &&
      (this.props.networkMapping.length === this.props.totalNumNetworks) && validSelection) {
      return true;
    }
  }

  render() {
    if (!this.props.visible) {
      return false;
    }

    const enableNextButton = this.enableNextButton();
    return (
      <Modal
        visible={ this.props.visible }
        title="Network Configuration"
        onClose={ () => this.props.closeNetworkMappingModal(false) }
        width={ 850 }
        contentClassName="-network-configuration-modal"
        footer={
          <Button
            key="submit"
            type="primary"
            className="btn-close"
            disabled={ !enableNextButton }
            onClick={ () => this.props.closeNetworkMappingModal(true) }>
            Next
          </Button>
        }
      >
        <StackingLayout>
          <NetworkConfiguration
            hideFooterButtons={ true }
            { ...this.props }
          />
        </StackingLayout>
      </Modal>
    );
  }

}

export default NetworkMappingModal;
