import MOVE_APPLICATION from "../RestAPI/MOVE_APPLICATION.js";

const LOCALSTORAGE = window.localStorage;

const MoveUpgradeConfig = {
  formatUpgradeConfig() {
    const {
      "move.upgrade" : moveUpgradeInProgress,
      showSplashScreen
    } = LOCALSTORAGE;

    const upgradeConfig = {
      moveUpgradeInProgress,
      showSplashScreen
    };

    return MOVE_APPLICATION.checkUpdate()
      .then((response) => {
        const responseStatus = (response && response.Status) || {};
        if (responseStatus.UpdateAvailable) {
          LOCALSTORAGE.setItem("move.updateAvailable", true);
        } else {
          LOCALSTORAGE.removeItem("move.updateAvailable");
        }

        upgradeConfig.updateAvailable = responseStatus.UpdateAvailable;
        return upgradeConfig;
      })
      .catch(() => {
        upgradeConfig.updateAvailable = false;
        LOCALSTORAGE.removeItem("move.updateAvailable");
        return upgradeConfig;
      });
  }
};

export default MoveUpgradeConfig;
