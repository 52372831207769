const bytesToGiBMultiplier = 1073741824;
const bytesToMiBMultiplier = 1048576;

const conversion = {
  // Convert value into readable text
  unitsToSize(units, decimal = 2, type) {
    let sizes;
    const fraction = 1024;
    const dm = decimal < 0 ? 0 : decimal;

    if (type === "Hz") {
      sizes = ["Hertz", "KHz", "MHz", "GHz", "THz"];
    } else {
      sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    }

    if (!units || units === 0) {
      return "n/a";
    }

    const i = Math.floor(Math.log(units) / Math.log(fraction));

    return `${parseFloat((units / Math.pow(fraction, i)).toFixed(dm))} ${sizes[i]}`;
  },

  convertMegaToBase(value) {
    return value * bytesToMiBMultiplier;
  },

  bytesToGiB(bytes) {
    const gb = bytes / bytesToGiBMultiplier;
    return gb % 1 === 0 ? gb : gb;
  },

  GiBToBytes(GiB) {
    const bytes = Math.floor(GiB * bytesToGiBMultiplier);
    return bytes;
  },

  roundOneDigit(value, precision) {
    if (!value) {
      return false;
    }
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  },

  convertByteToGigabyte(value) {
    return (value / (bytesToGiBMultiplier)).toFixed(2);
  },

  /**
  Formats a number with a specified number of decimal points.
  If the decimal portion of the number is zero, the resulting string will not contain a decimal point.
  @param {number} value - The number to be formatted.
  @param {number} [decimal=2] - The number of decimal points to be included in the formatted number.
  @returns {string} - The formatted number as a string.
  */
  formatDecimal(value, decimal = 2) {
    const roundedValue = parseFloat(value).toFixed(decimal);
    const decimalCount = (roundedValue.split(".")[1] || "").length;
    if (decimalCount === 0) {
      return roundedValue.replace(".", "");
    }
    return roundedValue.replace(/\.?0*$/, "");
  },

  formatNumberWithUnit(number = 0) {
    // returns invalid entry if input is unexpected
    if (typeof number !== "number" || number === 0) {
      return "-";
    }

    // Units: ['', 'K' (thousand), 'M' (million), 'B' (billion), 'T' (trillion)]
    const units = ["", "k", "m", "b", "t"];
    // Number of zeros in each unit (thousand has 3 zeros, million has 6 zeros, etc.)
    const unitStep = 3;

    const sign = Math.sign(number);
    const absNumber = Math.abs(number);

    if (absNumber < 1000) {
      // Return the number as it is if it's less than 1000
      return number.toString();
    }

    // Calculate the index of the unit
    const unitIndex = Math.floor(Math.log10(absNumber) / unitStep);
    // Divide the number by the appropriate power of 1000
    const scaledNumber = absNumber / Math.pow(1000, unitIndex);
    // Apply the sign and format the scaled number to 2 decimal place
    const formattedNumber = sign * scaledNumber.toFixed(2);

    return formattedNumber.toString() + units[unitIndex];
  },

  secondsToUnit(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "n/a";
    }

    const units = [
      { label: "day",
        value: 24 * 60 * 60 },
      { label: "hour",
        value: 60 * 60 },
      { label: "min",
        value: 60 },
      { label: "sec",
        value: 1 }
    ];

    let output = "";

    for (const unit of units) {
      if (seconds >= unit.value) {
        const count = Math.floor(seconds / unit.value);
        output += `${count} ${unit.label}${count === 1 ? "" : "s"}, `;
        seconds %= unit.value;
      }
    }

    output = output.slice(0, -2);
    return output;
  },


  /**
   * Formats a large number into a more readable string with thousands separators and optional decimal places.
   *
   * @param {number|string|null|undefined} number - The number to be formatted. Can be a number, a numeric string, `null`, or `undefined`.
   * @param {number} [decimalPlaces=0] - The number of decimal places to include in the formatted string. Default is 0.
   * @returns {string} The formatted number as a string with thousands separators and the specified decimal places. If the input is empty, `null`, or `undefined`, it returns "N/A". If the input is not a valid number, it returns "Invalid Number".
 */
  formatLargeNumber(number, decimalPlaces = 0) {
    if (typeof number !== "number" || number === 0) {
      return "-";
    }
    const num = Number(number);
    const formattedNumber = num.toFixed(decimalPlaces);
    const [integerPart, decimalPart] = formattedNumber.split(".");

    // Add commas as thousands separators to the integer part
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine the integer part with the decimal part (if any)
    if (decimalPart !== undefined) {
      return `${integerWithCommas}.${decimalPart}`;
    }
    return integerWithCommas;
  }


};


export default conversion;
