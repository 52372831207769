import React from "react";
import { Modal, Button, Select, Title, Alert,
  TextLabel, FlexLayout, Separator, VerticalSeparator,
  QuestionIcon, CloseIcon, Form } from "@nutanix-ui/prism-reactjs";
import AddUpdateAHVProvider from "../add-update-ahv-provider";
import AddUpdateESXiSource from "../add-update-esxi-source";
import AddUpdateAWSSource from "../add-update-aws-source";
import AddUpdateHyperVSource from "../add-update-hyperv-source";
import AddUpdateAzureProvider from "../add-update-azure-provider";
import LoaderModal from "../../presentational/loader-modal/";
import SOURCE from "../../../RestAPI/source";
import AppUtil from "../../../tools/AppUtil";
import AppConfig from "../../../app-config/AppConfig";
import Warning from "../../Warning";
import VCRegistrationModal from "../vcenter-registration-modal";
import "./select-source-type.less";

const rb = AppUtil.getI18nJSONResourceBundle();
const { Source, Provider } = rb;

class SelectSourceEnvironmentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      selectedSourceType: "",
      continueWithHyperVAgentReplace: false,
      ContextEntries: [],
      sourceFormBody: null
    };
  }

  saveFormRef = (Form) => {
    this.form = Form;
  }

  onSelectSourceType = (selectedSource) => {
    this.setState({
      selectedSourceType: selectedSource.value,
      showWarning: false,
      sourceFormBody: null
    });
  }

  componentDidMount() {
    const {
      selectedSourceType,
      ContextEntries
    } = this.props;
    this.setState({
      selectedSourceType,
      ContextEntries: ContextEntries || []
    });
  }


  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }


  handleOnInputValueChange = (event, index, formBody) => {
    formBody[index].value = event.target.value;
    this.setState({
      sourceFormBody: formBody
    });
  }


  addUpdateEnvironment = (e) => {
    e.preventDefault();
    const {
      selectedSourceType,
      showLoader
    } = this.state;
    const isValidForm = this.checkForEmptyValues();
    const continueAddEnv = !showLoader && isValidForm;
    const isAOSSource = rb.aos_providers_type.indexOf(selectedSourceType) > -1;
    if (continueAddEnv) {
      let loaderText = "";
      let addUpdateEnvironmentPromise;

      const envFormBody = this.prepareProviderFormBody();
      if (this.props.modalInEditMode && this.props.selectedEnvUUID) {
        loaderText = Source.source_update_message;
        envFormBody.UUID = this.props.selectedEnvUUID;
        addUpdateEnvironmentPromise = SOURCE.updateSource(envFormBody,
          this.props.selectedEnvUUID);
      } else {
        loaderText = Source.source_addition_message;
        addUpdateEnvironmentPromise = SOURCE.addSource(envFormBody);
      }

      // setting submitting form to true this is to avoid multiple clicks
      this.setState({
        showLoader: true,
        loaderText,
        warningText: "",
        showWarning: ""
      });

      addUpdateEnvironmentPromise.then((response) => {
        if (isAOSSource) {
          this.showVCRegistrationBlock(response, envFormBody);
        } else {
          this.handleAddUpdateEnvSuccess();
        }
      })
        .catch((response) => {
          this.handleAddUpdateEnvFailure(response);
        });
    }
  }

  prepareProviderFormBody = () => {
    const {
      selectedSourceType,
      sourceFormBody,
      continueWithHyperVAgentReplace
    } = this.state;

    const providerFormBody = {
      Spec: {
        Name: sourceFormBody[0].value
      }
    };

    switch (true) {
      case rb.aws_providers_type.indexOf(selectedSourceType) > -1:
        const {
          ContextEntries
        } = this.state;
        ContextEntries.forEach((entry) => {
          if (typeof entry.ContextKeyValues === "string") {
            entry.ContextKeyValues = entry.ContextKeyValues.split(",");
          }
        });
        providerFormBody.Spec.AWSAccessInfo = {
          AccessKey: sourceFormBody[1].value,
          SecretKey: sourceFormBody[2].value
        };
        providerFormBody.Spec.AWSProperties = {
          ContextEntries
        };
        providerFormBody.Spec.Type = "AWS";
        break;
      case rb.vmware_providers_type.indexOf(selectedSourceType) > -1:
        providerFormBody.Spec.ESXAccessInfo = {
          IPorFQDN: sourceFormBody[1].value.trim(),
          Username: sourceFormBody[2].value,
          Password: sourceFormBody[3].value
        };
        providerFormBody.Spec.Type = "ESXI";
        break;
      case rb.hyperv_providers_type.indexOf(selectedSourceType) > -1:
        providerFormBody.Spec.HyperVAccessInfo = {
          IPorFQDN: sourceFormBody[1].value.trim(),
          Username: sourceFormBody[2].value,
          Password: sourceFormBody[3].value
        };
        providerFormBody.Spec.HyperVSettings = {
          OverrideAgent: continueWithHyperVAgentReplace
        };
        providerFormBody.Spec.Type = "HYPERV";
        break;
      case rb.aos_providers_type.indexOf(selectedSourceType) > -1:
        providerFormBody.Spec.AOSAccessInfo = {
          IPorFQDN: sourceFormBody[1].value.trim(),
          Username: sourceFormBody[2].value,
          Password: sourceFormBody[3].value
        };
        providerFormBody.Spec.Type = "AOS";
        break;
      case rb.azure_providers_type.indexOf(selectedSourceType) > -1:
        providerFormBody.Spec.AzureAccessInfo = {
          TenantID: sourceFormBody[2].value,
          SubscriptionID: sourceFormBody[1].value,
          ClientID: sourceFormBody[3].value,
          ClientSecret: sourceFormBody[4].value
        };
        providerFormBody.Spec.Type = "AZURE";
        break;
      default:
        return null;
    }

    return providerFormBody;
  }


  handleAddUpdateEnvSuccess = () => {
    this.setState({
      showLoader: false,
      loaderText: "",
      continueWithHyperVAgentReplace: false,
      showVCsWithoutCreds: false,
      closeEnvModal: true
    });
    if (this.props.modalInEditMode) {
      this.props.refreshSelectedInventory(
        this.props.selectedEnvUUID
      );
    }
    this.props.onCancel(this.props.Role);
  }

  handleAddUpdateEnvFailure = (errorResponse) => {
    const HyperVAgentReplace = errorResponse.Code &&
      errorResponse.Code === rb.override_hyperv_agent_error_code;
    this.setState({
      warningText: errorResponse.Message || errorResponse.message,
      showLoader: false,
      showWarning: true,
      loaderText: "",
      showHyperVAgentReplaceWarning: HyperVAgentReplace,
      continueWithHyperVAgentReplace: false
    });
  }

  updateSourceFormBody = (sourceFormBody) => {
    this.setState({
      sourceFormBody
    });
  }


  getFormattedFormBody = (FormItemType) => {
    const { sourceFormBody } = this.state;
    const { sourceBody } = this.props;

    if (sourceFormBody) {
      return sourceFormBody;
    }
    const InputFormItemSet = JSON.parse(JSON.stringify(rb.Provider[FormItemType]));
    const {
      name = "",
      username = "",
      ip = "",
      TenantID = "",
      SubscriptionID = "",
      ClientID = "",
      AccessKey = ""
    } = sourceBody;

    switch (FormItemType) {
      case "ESXiSourcePopUpFormItems":
      case "AHVFormItems":
        InputFormItemSet[0].value = name;
        InputFormItemSet[1].value = ip;
        InputFormItemSet[2].value = username;
        break;
      case "AzureFormItems":
        InputFormItemSet[0].value = name;
        InputFormItemSet[1].value = SubscriptionID;
        InputFormItemSet[2].value = TenantID;
        InputFormItemSet[3].value = ClientID;
        break;
      case "AWSSourcePopUpFormItems":
        InputFormItemSet[0].value = name;
        InputFormItemSet[1].value = AccessKey;
        break;
      case "HyperVSourcePopUpFormItems":
        InputFormItemSet[0].value = name;
        InputFormItemSet[1].value = ip;
        InputFormItemSet[2].value = username;
        break;
      default:
        break;
    }

    return InputFormItemSet;
  }


  showSourceFormBody = (form) => {
    const {
      selectedSourceType
    } = this.state;

    if (!selectedSourceType) {
      return false;
    }

    switch (true) {
      case rb.vmware_providers_type.indexOf(selectedSourceType) > -1:
        const vmware_esx_formBody = this.getFormattedFormBody("ESXiSourcePopUpFormItems");
        return (
          <AddUpdateESXiSource
            ref={ this.saveFormRef }
            form={ form }
            sourceBody={ this.props.sourceBody }
            modalInEditMode={ this.props.modalInEditMode }
            handleOnInputValueChange={ this.handleOnInputValueChange }
            formBody={ vmware_esx_formBody }
          />
        );

      case rb.aws_providers_type.indexOf(selectedSourceType) > -1:
        const aws_formBody = this.getFormattedFormBody("AWSSourcePopUpFormItems");
        return (
          <AddUpdateAWSSource
            ref={ this.saveFormRef }
            form={ form }
            sourceBody={ this.props.sourceBody }
            modalInEditMode={ this.props.modalInEditMode }
            ContextEntries={ this.state.ContextEntries }
            handleOnInputValueChange={ this.handleOnInputValueChange }
            formBody={ aws_formBody }
          />
        );

      case rb.hyperv_providers_type.indexOf(selectedSourceType) > -1:
        const hyperv_esx_formBody = this.getFormattedFormBody("HyperVSourcePopUpFormItems");
        return (
          <AddUpdateHyperVSource
            ref={ this.saveFormRef }
            form={ form }
            sourceBody={ this.props.sourceBody }
            modalInEditMode={ this.props.modalInEditMode }
            handleOnInputValueChange={ this.handleOnInputValueChange }
            formBody={ hyperv_esx_formBody }
          />
        );

      case rb.aos_providers_type.indexOf(selectedSourceType) > -1:
        const aos_formBody = this.getFormattedFormBody("AHVFormItems");
        return (
          <AddUpdateAHVProvider
            ref={ this.saveFormRef }
            form={ form }
            sourceBody={ this.props.sourceBody }
            modalInEditMode={ this.props.modalInEditMode }
            handleOnInputValueChange={ this.handleOnInputValueChange }
            formBody={ aos_formBody }
          />
        );
      case rb.azure_providers_type.indexOf(selectedSourceType) > -1:
        const azure_formBody = this.getFormattedFormBody("AzureFormItems");
        return (
          <AddUpdateAzureProvider
            ref={ this.saveFormRef }
            form={ form }
            sourceBody={ this.props.sourceBody }
            modalInEditMode={ this.props.modalInEditMode }
            handleOnInputValueChange={ this.handleOnInputValueChange }
            formBody={ azure_formBody }
          />
        );
      default:
        return false;
    }
  }

  closeHyperVAgentReplaceWarning = () => {
    this.setState({
      showHyperVAgentReplaceWarning: false
    });
  }

  continueWithHyperVAgentReplace = (e) => {
    this.setState({
      continueWithHyperVAgentReplace: true,
      showHyperVAgentReplaceWarning: false
    }, () => {
      this.addUpdateEnvironment(e);
    });
  }


  showVCRegistrationBlock = (response, envFormBody) => {
    const VCsWithoutCreds = this.checkRegisterdVCCredsDetails(response);

    if (VCsWithoutCreds) {
      this.setState({
        showVCsWithoutCreds: true,
        showLoader: false,
        loaderText: "",
        providerFormBody: envFormBody,
        VCsWithoutCreds,
        providerUUIDForVCsCreds: response.MetaData.UUID
      });
    } else {
      this.handleAddUpdateEnvSuccess();
    }
  }


  checkRegisterdVCCredsDetails = (envDetails) => {
    let VCsWithoutCreds = null;
    const registeredVCsDetails = [];
    const envAccessInfo = envDetails.Spec.AOSAccessInfo;
    const envClusters = envDetails.Spec.AOSProperties.Clusters;
    if (envClusters.length) {
      envClusters.forEach((cluster) => {
        if (cluster.RegisteredVCIPAddress) {
          const vcObject = {
            IPAddress: cluster.RegisteredVCIPAddress
          };
          registeredVCsDetails.push(vcObject);
        }
      });
    }
    if (registeredVCsDetails.length) {
      VCsWithoutCreds = this.getVCsWithoutCreds(registeredVCsDetails, envAccessInfo);
    }

    return VCsWithoutCreds;
  }


  getVCsWithoutCreds = (registeredVCs, envAccessInfo) => {
    const RegisteredVCsAccessInfo = envAccessInfo.RegisteredVCAccessInfo || [];
    if (RegisteredVCsAccessInfo.length) {
      const VCsWithoutCreds = registeredVCs.filter((registeredVC) => {
        RegisteredVCsAccessInfo.forEach((addedVCs) => {
          if (addedVCs.IPAddress !== registeredVC.IPAddress) {
            return true;
          }
        });
      }, Object.create(null));

      return VCsWithoutCreds;
    }
    return registeredVCs;
  }

  showVCRegistrationModal = (showVCRegistration) => {
    let VCRegistrationModalHTML = "";
    if (showVCRegistration) {
      const {
        providerFormBody,
        VCsWithoutCreds,
        providerUUIDForVCsCreds
      } = this.state;

      VCRegistrationModalHTML = <VCRegistrationModal
        envFormBody={ providerFormBody || {} }
        VCsWithoutCreds={ VCsWithoutCreds || [] }
        checkRegisterdVCCredsDetails={ this.checkRegisterdVCCredsDetails }
        onCancel={ this.handleAddUpdateEnvSuccess }
        providerUUID={ providerUUIDForVCsCreds }
      />;
    }

    return VCRegistrationModalHTML;
  }

  openMoveHelpPortal = () => {
    const selectedSourceType = this.state.selectedSourceType;
    let prism_id = rb.help_prism_ids.move_default;
    if (rb.vmware_providers_type.indexOf(selectedSourceType) > -1) {
      prism_id = rb.help_prism_ids.add_esxi_provider;
    } else if (rb.aos_providers_type.indexOf(selectedSourceType) > -1) {
      prism_id = rb.help_prism_ids.add_ahv_provider;
    } else if (rb.hyperv_providers_type.indexOf(selectedSourceType) > -1) {
      prism_id = rb.help_prism_ids.add_hyperv_provider;
    } else if (rb.aws_providers_type.indexOf(selectedSourceType) > -1) {
      prism_id = rb.help_prism_ids.add_aws_provider;
    } else if (rb.azure_providers_type.indexOf(selectedSourceType) > -1) {
      prism_id = rb.help_prism_ids.add_azure_provider;
    }
    const url = rb.help_url_pre + rb.move_version + rb.help_url_mid + prism_id;
    const win = window.open(url, "_blank");
    win.focus();
  }

  getModalheader = () => {
    const header =
      <Separator separator={ <VerticalSeparator /> }>
        <div onClick={ () => this.openMoveHelpPortal() }><QuestionIcon id="_uiauto-move-help-icon"/></div>
        <div onClick={ this.props.onCancel }><CloseIcon /></div>
      </Separator>;
    return header;
  }


  checkForEmptyValues = () => {
    const { sourceFormBody } = this.state;

    if (!sourceFormBody) {
      return false;
    }

    return sourceFormBody.every(({ value }) => value !== "") && sourceFormBody.length > 0;
  }


  render() {
    const { form, modalInEditMode, onCancel, filteredSelectEnvOption } = this.props;
    const {
      selectedSourceType,
      showLoader,
      warningText,
      loaderText,
      showHyperVAgentReplaceWarning,
      showWarning,
      showVCsWithoutCreds,
      closeEnvModal
    } = this.state;

    const modalTitleSuffix = "Environment";
    let modalTitlePrefix = "Add";
    if (modalInEditMode) {
      modalTitlePrefix = "Edit";
    }

    const isFormValid = this.checkForEmptyValues();
    const modalTitile = `${modalTitlePrefix} ${modalTitleSuffix}`;
    let selectedSourceConfig = filteredSelectEnvOption.find((env) => {
      return env.value === selectedSourceType;
    });
    const showAOSSourceAlertInfo = rb.aos_providers_type.indexOf(selectedSourceType) > -1 &&
      !modalInEditMode && !showWarning;
    const Footer = (
      <FlexLayout itemSpacing="10px" justifyContent="flex-end">
        <Button
          id="_uiauto-add-environment-cancel-button"
          type="secondary"
          onClick={ onCancel }
          disabled={ this.state.showLoader }>
          Cancel
        </Button>
        <Button
          id="_uiauto-add-environment-add-button"
          key="submit"
          type="primary"
          onClick={ this.addUpdateEnvironment }
          disabled={ showLoader ||
            !selectedSourceType || !isFormValid }>
          { modalInEditMode ? "Save" : "Add" }
        </Button>
      </FlexLayout>
    );
    if (filteredSelectEnvOption.length === 1) {
      selectedSourceConfig = filteredSelectEnvOption[0];
    }
    return (
      <div>
        { !showVCsWithoutCreds && !closeEnvModal
          ? <Modal
            visible={ !showVCsWithoutCreds }
            afterClose={ onCancel }
            title={ modalTitile }
            footer={ Footer }
            contentClassName="ntnx-modal-content"
            maskClosable={ false }
            className="add-source-modal"
            width={ 500 }
            headerActions={ this.getModalheader() }
          >
            {
              showAOSSourceAlertInfo &&
                <Alert
                  type="info"
                  className="error-message _uiauto-add-provider-nutanix-message"
                  message={ rb.Information.aos_esx_environment_help_message }
                  showCloseIcon={ false }
                />
            }
            { showWarning &&
              <Alert
                type="error"
                className="error-message _uiauto-add-provider-error-message"
                message={ warningText }
                showCloseIcon={ false }
              />
            }

            <div className="add-update-source-body">
              { filteredSelectEnvOption.length > 1
                ? <div id="select-source-type">
                  <Title size="h4"
                    className="source-type-dropdown-label">
                  Select Environment Type
                  </Title>
                  <Select
                    id="_uiauto-select-environment-type"
                    rowsData={ filteredSelectEnvOption }
                    placeholder={ Source.select_source_type_placeholder }
                    selectedRow={ selectedSourceConfig }
                    onSelectedChange={ this.onSelectSourceType }
                    disabled={ modalInEditMode }
                    border={ true }
                  />
                </div>
                : <TextLabel type="primary">
                  Enter Nutanix AHV/ESXi environment details that you want to migrate VMs to. You
                  can supply connection details for either Prism Element or Prism Central.
                </TextLabel>
              }

              <Form onSubmit={ this.addUpdateEnvironment }>
                { this.showSourceFormBody(form) }
                <input type="submit" className="hidden-submit-button"/>
              </Form>
            </div>

            <LoaderModal
              visible={ showLoader }
              loaderDescription={ loaderText }
            />

            <Warning show={ showHyperVAgentReplaceWarning }
              warningText={ warningText } className="move-agent-warning">
              <TextLabel type="warning">
                { rb.Warnings.move_agent_cleanup_warning }
              </TextLabel>
              <Title size="h3" id="_uiauto-hyper-v-final-warning">
                Do you want to replace the Move Agent ?
              </Title>
              <div className="warning-buttons">
                <Button
                  id="_uiauto-hyper-v-final-cancel"
                  onClick={ this.closeHyperVAgentReplaceWarning }
                  type="primary">
                  { rb.Common.cancel }
                </Button>
                <Button
                  id="_uiauto-hyper-v-final-continue"
                  onClick={ (event) => this.continueWithHyperVAgentReplace(event) }
                  type="secondary"
                >
                  { rb.Common.continue }
                </Button>
              </div>
            </Warning>
          </Modal> : ""
        }
        { this.showVCRegistrationModal(showVCsWithoutCreds) }
      </div>
    );
  }

}


class SelectSourceType extends React.Component {

  componentWillMount() {
    const appSettings = AppConfig.get();
    const selectedSourceType = this.props.selectedSourceType;
    const filteredSelectEnvOption = Provider.provider_type_menu_items;
    this.setState({
      sourceBody: this.props.sourcePopupBody,
      modalInEditMode: this.props.modalInEditMode,
      selectedSourceType,
      appSettings,
      filteredSelectEnvOption
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      sourceBody: nextProps.sourcePopupBody,
      modalInEditMode: nextProps.modalInEditMode,
      selectedSourceType: nextProps.selectedSourceType
    });
  }


  render() {
    return (
      <div>
        <SelectSourceEnvironmentForm
          Role={ this.props.Role }
          sourceBody={ this.state.sourceBody }
          onCancel={ this.props.onCancel }
          modalInEditMode={ this.state.modalInEditMode }
          selectedSourceType={ this.state.selectedSourceType }
          selectedEnvUUID={ this.props.selectedEnvUUID }
          refreshSelectedInventory={ this.props.refreshSelectedInventory }
          appSettings={ this.state.appSettings }
          filteredSelectEnvOption={ this.state.filteredSelectEnvOption }
          ContextEntries={ this.props.ContextEntries }
        />
      </div>
    );
  }

}

export default SelectSourceType;
