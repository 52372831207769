/* global window */
// initialized EulaAccepted as true
const appData = {
  EulaAccepted: false
};

class AppInfoData {

  static get() {
    return appData;
  }

  static set(data) {
    Object.assign(appData, data);
  }

}

export default AppInfoData;
