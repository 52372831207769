import moment from "moment";
import i18nResourceBundle from "../i18n/en-US/i18n.json";

// ignores case-sensitive
const getValue = value => (typeof value === "string" ? value.toUpperCase() : value);

const AppUtil = {
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) { func.apply(context, args); }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) { func.apply(context, args); }
    };
  },

  // Sort array of objects alphabetically or ascending/descending order
  // by passed property

  sortByProperty(field, reverse, primer, objectKey) {
    const key = primer ? function(x) { return primer(x[field]); }
      : function(x) { return x[field]; };

    reverse = !reverse ? 1 : -1;

    return function(a, b) {
      if (objectKey) {
        a = key(a[objectKey]);
        b = key(b[objectKey]);
      } else {
        a = key(a);
        b = key(b);
      }
      return reverse * ((a > b) - (b > a));
    };
  },


  /**
   * @param { String } str The text to be converted to titleCase.
   * @param { Array } glue the words to leave in lowercase.
   * @return { String } returns title case for the given string.
   */
  titleCase(str, glue) {
    glue = (glue) || ["of", "for", "and"];
    return str.replace(/(\w)(\w*)/g, function(_, i, r) {
      const j = i.toUpperCase() + (r !== null ? r : "");
      return (glue.indexOf(j.toLowerCase()) < 0) ? j : j.toLowerCase();
    });
  },

  escapeBackslash(string) {
    return string.replace(/\\/g, "\\");
  },

  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  },

  // Create Cookie
  // Example:
  // createCookie('ppkcookie','testcookie',7);
  createCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
  },

  // Read Cookie
  // Example:
  // var x = readCookie('ppkcookie')
  // if (x) {
  //   [do something with x]
  // }
  readCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") { c = c.substring(1, c.length); }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  },

  eraseCookie(name) {
    this.createCookie(name, "", -1);
  },

  // Get Base64 of a File
  // Example:
  // var base64ofFile = getBase64('file')
  getFileBase64(file) {
    return new Promise((resolve) => {
      const fr = new FileReader();
      fr.onload = resolve; // CHANGE to whatever function you want which would eventually call resolve
      fr.readAsBinaryString(file);
    });
    // let reader = new FileReader();
    // reader.readAsBinaryString(file);
    // const base64String = btoa(reader.result);
    // return base64String;
  },

  // Reads i18n.json
  getI18nJSONResourceBundle() {
    const strings = i18nResourceBundle;
    return strings;
  },

  // compare two version strings
  // Return 1 if vaersion a > version b
  // Return -1 if vaersion a < version b
  // Return 0 if vaersion a == version b
  compareProductVersion(compatibleUpgradeVersions, currentVersion) {
    return compatibleUpgradeVersions.indexOf(currentVersion) > -1;
  },


  /**
   * Filters an array of objects (one level-depth) with multiple criteria.
   *
   * @param  {Array}  array: the array to filter
   * @param  {Object} filters: an object with the filter criteria
   * @return {Array}
  */
  filterPlainArray(arr, filters) {
    return arr.filter((item) => {
      for (const key in filters) {
        const filterValues = filters[key];

        if (Array.isArray(filterValues)) {
          if (filterValues.length === 0) { continue; }
          if (!filterValues.some((value) => item[key].toLowerCase().includes(value.toLowerCase()))) {
            return false;
          }
        } else if (!item[key].toLowerCase().includes(filterValues.toLowerCase())) {
          return false;
        }
      }

      return true;
    });
  },


  /**
   * Finds if one item of an aaray is contained in another array .
   *
   * @param  {Array}  array: main array where to look
   * @param  {Array} array: array items which is to be search
   * @return {Boolean}
  */
  checkIfArrayContainsAnotherArray(mainArr, containedArr) {
    return containedArr.some(r => mainArr.includes(r));
  },

  // Pass in the name of the key for you to sort an array of objects by.
  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a, b) {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  },


  /**
   * Replace string with JSX
   *
   * @param  {Array}  array: main array where to look
   * @param  {String} string: string items which is to be search
   * @return {Object} object: object to be replaced
  */

  replaceStringWithJSX(str, find, replace) {
    const result = [];
    const parts = str.split(find);
    for (let i = 0; i < parts.length; i++) {
      result.push(parts[i]);

      if ((parts.length - 1) !== i) {
        result.push(replace);
      }
    }
    return result;
  },


  // Pass in the array of strings and returns the comma seperated joined strings
  commaSeperatedString(stringsArray) {
    return stringsArray
      .join(", ").replace(/, ([^,]*)$/, " and $1");
  },


  /**
  * Filters an array of objects (one level-depth) with multiple criteria.
  *
  * @param  {Array}  array: the array to filter
  * @param  {Object} filters: an object with the filter criteria
  * @return {Array}
  */
  filterPlainArrayWithMultipleProps(array, filters) {
    const filterKeys = Object.keys(filters);
    return array.filter(item => {
      // validates all filter criteria
      return filterKeys.every(key => {
        // ignores an empty filter
        if (!filters[key].length) { return true; }
        return filters[key].find(filter => getValue(filter) === getValue(item[key]));
      });
    });
  },


  /**
  * Toggles(add/remove) an item from array based on availabilty.
  *
  * @param  {Array}  array: the array to look for
  * @param  {String} str: item to add/remove
  * @return {Array}
  */
  toggleItemInArray(array, item) {
    const exists = array.includes(item);

    if (exists) {
      return array.filter((c) => { return c !== item; });
    }
    const result = array;
    result.push(item);
    return result;
  },


  /**
  * Converts EPOC time to readable format
  */
  formatEPOCTimeToReadable(epocTime) {
    let formattedTime = "";
    const { nano_sec_multiplier } = i18nResourceBundle;
    if (epocTime) {
      formattedTime = moment.unix((epocTime / nano_sec_multiplier)).format("ll, h:mm a");
    }

    return formattedTime;
  },

  // compare two version strings
  compareAOSVersions(ver1, ver2) {
    let compatibleVersion = false;
    const compatibilityIndex = ver2.localeCompare(ver1, undefined, { numeric: true,
      sensitivity: "base" });

    if (compatibilityIndex >= 0) {
      compatibleVersion = true;
    }

    return compatibleVersion;
  },

  /**
  * Format Numbet to two digit readable format
  */
  formatNumberInTwoDigit(d) {
    return (d < 10) ? `0${d.toString()}` : d.toString();
  },

  /**
  * Title case first char of the string and rest of the chars to lower
  * case
  */
  transformTitleCase(textToConvert) {
    return textToConvert.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
  },

  /**
   * Removes items from objectList based on the indexes provided in arr2.
   *
   * @param {Array} objectList - The original array from which items will be removed.
   * @param {Array} indexList - The array containing the indexes of items to be removed.
   * @returns {Array} - A new array with items removed based on the provided indexes.
   */
  removeItemsFromObjectListByListOfIndex(objectList, indexList) {
    return objectList.filter(item => !indexList.includes(item.key));
  },


  sortingNameByOrder(list, order) {
    return list.slice(0).sort((a, b) => {
      const nameA = a.mpName.toLowerCase();
      const nameB = b.mpName.toLowerCase();
      const ascendingOrderSort = 1;
      const descendingOrderSort = -1;
      if (order === ascendingOrderSort) {
        return nameA.localeCompare(nameB);
      } else if (order === descendingOrderSort) {
        return nameB.localeCompare(nameA);
      }

      return 0;
    });
  },

  findObjectByKey(array = [], key, value) {
    return array.find(obj => obj[key] === value);
  },


  /**
   * Returns the common available actions from an array of selected rows(Table) data.
   *
   * @param {Array} selectedRowsData - An array of objects representing selected rows' data.
   * @returns {Array} - An array containing the common available actions across all selected rows.
  */
  getAvailableActions(selectedRowsData) {
    if (selectedRowsData.length === 0) {
      return [];
    }

    // Starting the actions from the first share
    let availableActions = selectedRowsData[0].Actions.slice();

    for (let i = 1; i < selectedRowsData.length; i++) {
      const currentActions = selectedRowsData[i].Actions;
      availableActions = availableActions.filter(action => currentActions.includes(action));

      // Break the loop if no common actions are found
      if (availableActions.length === 0) {
        break;
      }
    }

    return availableActions;
  }

};

export default AppUtil;
