
import React from "react";
import { FlexLayout, StackingLayout, Title, TextLabel, Link, FlexItem } from "@nutanix-ui/prism-reactjs";
import "../esxi-vm-preparation/ESXiVMPreparation.less";

class ManualVMPreprationBody extends React.Component {

  render() {
    const {
      guest_scripts = {},
      disabledScripts
    } = this.props;

    let WindowsGuestScript = null;
    let LinuxGuestScript = null;

    WindowsGuestScript = guest_scripts.WindowsGuestScript;
    LinuxGuestScript = guest_scripts.LinuxGuestScript;

    return (
      <StackingLayout className={ disabledScripts ? "disabled-script" : "" }>
        { WindowsGuestScript &&
          <StackingLayout>
            <Title size="h3" className="script-box-title">
              For Windows VMs
            </Title>
            <TextLabel>
              Run the following PowerShell command on the VMs that you have
              added to the migration plan. Make sure to run powershell as Administrator.
            </TextLabel>
            <StackingLayout className="script-box">
              <FlexLayout>
                <FlexItem flexGrow="1" className="flex-grow copy-script">
                  <TextLabel id="_uiauto-windows-guest-script" type="secondary">
                    { WindowsGuestScript[0] }
                  </TextLabel>
                </FlexItem>
                <StackingLayout style={ { width: "55px" } } className="copy-link-box">
                  <FlexLayout justifyContent="center" alignItems="center">
                    <Link
                      id="_uiauto-copy-windows-guest-script"
                      onClick={ () => this.props.copyGuestScript(WindowsGuestScript[0]) }
                      disabled={ disabledScripts }
                    >
                      Copy
                    </Link>
                  </FlexLayout>
                </StackingLayout>
              </FlexLayout>
            </StackingLayout>
          </StackingLayout>
        }
        { LinuxGuestScript &&
          <StackingLayout>
            <Title size="h3" className="script-box-title">
              For Linux VMs
            </Title>
            <TextLabel>
              Run the following command on the VMs that you have added to the
              migration plan. Make sure to log in to the VMs as a root user.
            </TextLabel>
            <StackingLayout className="script-box">
              <FlexLayout>
                <FlexItem flexGrow="1" className="flex-grow copy-script">
                  <TextLabel id="_uiauto-linux-guest-script" type="secondary">
                    { LinuxGuestScript[0] }
                  </TextLabel>
                </FlexItem>
                <StackingLayout style={ { width: "55px" } } className="copy-link-box">
                  <FlexLayout justifyContent="center" alignItems="center">
                    <Link
                      id="_uiauto-copy-linux-guest-script"
                      onClick={ () => this.props.copyGuestScript(LinuxGuestScript[0]) }
                      disabled={ disabledScripts }
                    >
                      Copy
                    </Link>
                  </FlexLayout>
                </StackingLayout>
              </FlexLayout>
            </StackingLayout>
          </StackingLayout>
        }
      </StackingLayout>
    );
  }

}

export default ManualVMPreprationBody;
