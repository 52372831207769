import FetchUtil from "../tools/FetchUtil";
import Request from "../api/Request-Interface/Request.js";

const MOVE_APPLICATION = {
  checkUpdate() {
    return Request.get("/move/v2/checkUpgrade", FetchUtil.headers());
  },

  checkUpdateReCache() {
    return Request.get("/move/v2/checkUpgrade?ReCache=true", FetchUtil.headers());
  },

  checkUpgradeStatus() {
    return Request.get("/move/v2/upgrade", FetchUtil.headers());
  },

  startUpgrade(upgradeDetails) {
    return Request.post("/move/v2/upgrade", upgradeDetails, FetchUtil.headers());
  },

  uploadUpgradeFiles(upgradeFilesinfo) {
    const headers = {
      Accept: "application/json",
      Authorization: sessionStorage.getItem("token")
    };
    return Request.post("/v1/uploadUpgradeFiles", upgradeFilesinfo, headers, true);
  },

  getAllBandwidthPolicies() {
    const headers = {
      Accept: "application/json",
      Authorization: sessionStorage.getItem("token")
    };
    return Request.post("/move/v2/plans/bwcap/policies/list", null, headers);
  },

  createBandwidthPolicy(policyJson) {
    return Request.post("/move/v2/plans/bwcap/policies", policyJson, FetchUtil.headers());
  },

  updateBandwidthPolicy(policyJson, policyId) {
    return Request.put(`/move/v2/plans/bwcap/policies/${policyId}`, policyJson, FetchUtil.headers());
  },

  deleteBandwidthPolicy(policyId) {
    return Request.delete(`/move/v2/plans/bwcap/policies/${policyId}`, null, FetchUtil.headers());
  },

  getSnapshotsConfigurations() {
    return Request.get("/move/v2/configurations/appliance/providers/snapshots", FetchUtil.headers());
  },

  updateSnapshotsConfigurations(config) {
    return Request.put("/move/v2/configurations/appliance/providers/snapshots", config, FetchUtil.headers());
  },

  getDockerIP() {
    return Request.get("/move/v2/configurations/appliance/dockerip", FetchUtil.headers());
  },

  getNTPServers() {
    return Request.get("/move/v2/configurations/appliance/ntpservers", FetchUtil.headers());
  },

  updateNTPServers(config) {
    return Request.put("/move/v2/configurations/appliance/ntpservers", config, FetchUtil.headers());
  },

  getIAMProviders() {
    return Request.get("/move/v2/iamproviders", FetchUtil.headers());
  },

  addIAMProvider(IAMDetails) {
    return Request.post("/move/v2/iamproviders", IAMDetails, FetchUtil.headers());
  },

  updateIAMProvider(IAMDetails, IAMId) {
    return Request.put(`/move/v2/iamproviders/${IAMId}`, IAMDetails, FetchUtil.headers());
  },

  deleteIAMProvider(IAMProviderId, payload) {
    return Request.delete(`/move/v2/iamproviders/${IAMProviderId}`, payload, FetchUtil.headers());
  },

  editIAMProvider(IAMProviderId, config) {
    return Request.put(`/move/v2/iamproviders/${IAMProviderId}`, config, FetchUtil.headers());
  },

  viewPCRegistationDetails() {
    return Request.get("/move/v2/iamproviders/view");
  },

  getTokenStatus() {
    return Request.get("/move/v2/token/status", FetchUtil.headers());
  },

  checkAuthorizedToken() {
    const formBody = {
      Token: sessionStorage.getItem("token")
    };
    return Request.post("move/v2/token/checkAuthorized", formBody, FetchUtil.headers());
  },

  generateToken(generateTokenPayload, iamUUID) {
    return fetch((`/move/v2/iamproviders/${iamUUID}/token`), {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      body: generateTokenPayload
    }).then((response) => {
      return Request.parseJSON(response);
    });
  },

  checkTokenStatus() {
    return Request.get("/move/v2/token/status", FetchUtil.headers());
  }

};

export default MOVE_APPLICATION;
