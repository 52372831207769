import React from "react";
import { FormSection, FormItemInput, FlexLayout, FormItemProvider,
  InputPassword, Title, TextLabel } from "@nutanix-ui/prism-reactjs";

const PasswordInputPlusLabel = FormItemProvider(InputPassword);

class OtherFileServerForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      togglePasswordLabel: "Show"
    };
  }

  render() {
    const { updateFormData, serverBody, modalInEditMode } = this.props;
    const optionalDescription = (
      <Title size="h3">
        Optional for NFS,
        <TextLabel type="secondary">
          &nbsp;these credentials will be used to discover SMB shares.
        </TextLabel>
      </Title>
    );

    return (
      <FormSection className="-file-server-section" itemSpacing="0px">
        <FormItemInput
          id={ serverBody[0].key }
          label={ serverBody[0].inputTitle }
          placeholder={ serverBody[0].placeholder }
          value={ serverBody[0].value }
          onChange={ (e) => updateFormData(0, e.currentTarget.value) }
        />
        <FormItemInput
          id={ serverBody[1].key }
          label={ serverBody[1].inputTitle }
          placeholder={ serverBody[1].placeholder }
          value={ serverBody[1].value }
          onChange={ (e) => updateFormData(1, e.currentTarget.value) }
          disabled={ modalInEditMode }
        />

        <FormSection
          title="SMB File Server Credentials"
          description={ optionalDescription }
          style={ { background: "#f2f4f6",
            padding:"20px" } }
          itemSpacing="10px"
        >
          <FlexLayout
            className="file-split-creds-block -file-server-section"
            itemFlexBasis="100pc" itemSpacing="20px">
            <FormItemInput
              appearance="borderless"
              id={ serverBody[2].key }
              label={ serverBody[2].inputTitle }
              placeholder={ serverBody[2].placeholder }
              value={ serverBody[2].value }
              onChange={ (e) => updateFormData(2, e.currentTarget.value) }
            />
            <PasswordInputPlusLabel
              appearance="borderless"
              id={ serverBody[3].key }
              label={ serverBody[3].inputTitle }
              placeholder={ serverBody[3].placeholder }
              value={ serverBody[3].value }
              onChange={ (e) => updateFormData(3, e.currentTarget.value) }
            />
          </FlexLayout>
        </FormSection>
      </FormSection>
    );
  }

}

export default OtherFileServerForm;
