import React from "react";
import { browserHistory } from "react-router";
import { Modal, Button, FormItemProvider, StackingLayout, Input,
  FlexLayout, Loader } from "@nutanix-ui/prism-reactjs";
import MIGRATION from "../../../RestAPI/migration";
import AppUtil from "../../../tools/AppUtil";
import "./edit-migration-name-modal.less";

const InputPlusLabel = FormItemProvider(Input);
const LOCALSTORAGE = window.localStorage;
const resourceBundle = AppUtil.getI18nJSONResourceBundle();

class EditMigrationNameModal extends React.Component {

  constructor(props) {
    super(props);
    const localMigType = LOCALSTORAGE.getItem("migrationType");
    const migrationType = ["Files", "VM"].indexOf(localMigType) > -1 ? localMigType : "";
    this.state = {
      planName: "",
      planNameError: false,
      plansList: [],
      fetchingPlansList: true,
      migrationType
    };
    this.getAllMigrationPlans();
  }

  componentWillMount() {
    this.setState({
      planName: this.props.migrationPlanName,
      migPlanID: this.props.migPlanID
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      planName: nextProps.migrationPlanName,
      migPlanID: nextProps.migPlanID
    });
  }


  closePlan = () => {
    const { newPlanFlow, migrationPlanName } = this.props;

    if (newPlanFlow && !migrationPlanName) {
      browserHistory.push("/dashboard");
    }
    this.props.closeEditMigrationNameModal();
  }


  /**
   * Fetches all migration plans for the current type(files/VM) and updates the filesList object.
   *
   * @returns {Promise<void>} A Promise that resolves when the plans are fetched and the state is updated.
  */
  getAllMigrationPlans = () => {
    return MIGRATION.getAllPlans(this.state.migrationType)
      .then((planData) => {
        this.setState({
          plansList: planData.Entities || []
        });
      })
      .finally(() => {
        this.setState({
          fetchingPlansList: false
        });
      });
  }

  /**
   * Handle the change event for a plan name input field with max-60
   * chars validation.
   *
   * @param {Event} event - The change event object.
  */
  onChangePlanName = (event) => {
    const { migrationType } = this.state;
    const planName = event.currentTarget.value;
    let planNameError = "";

    if (migrationType === "Files") {
      const { max_migration_plan_name_length } = resourceBundle.Constants;
      planNameError = planName.length > max_migration_plan_name_length
        ? `Plan name should not exceed ${max_migration_plan_name_length} characters.` : "";
    }

    this.setState({
      planName,
      planNameError
    });
  }


  /**
   * Handles the submission of a plan name with name vaildation.
   *
   * @param {Event} event - The event object triggering the submission.
  */
  submitPlanName = (event) => {
    const { planName } = this.state;
    const isPlanNameValid = this.isPlanNameValid(planName);
    if (isPlanNameValid) {
      this.props.onOk(event, planName);
    } else {
      this.setState({
        planNameError: "Plan with the same name already exists."
      });
    }
  }


  /**
   * Checks if a plan name is valid before saving it, considering existing plans and their UUID.
   *
   * @param {string} planName - The name of the plan to be saved.
   * @throws {Error} If the plan name is empty or if a plan with the same name already exists.
   * @returns {boolean} True if the plan name is valid; otherwise, false.
  */
  isPlanNameValid = (planName) => {
    const {
      plansList,
      migPlanID
    } = this.state;
    // Check if a plan with the same name already exists
    const planWithSameName = plansList.find((plan) => {
      return plan.MetaData.Name.toLowerCase() === planName.toLowerCase();
    });

    if (planWithSameName) {
      // If the plan being edited has the same UUID, it's the same plan and the name is considered valid
      if (migPlanID && planWithSameName.MetaData.UUID === migPlanID) {
        return true;
      }

      return false;
    }

    // If no plan with the same name was found, the name is valid
    return true;
  }

  renderFooter() {
    const { planName, planNameError, fetchingPlansList } = this.state;
    const { migrationPlanName } = this.props;

    return (<FlexLayout itemSpacing="10px" justifyContent="flex-end">
      <Button
        id="_uiauto-plan-name-cancel"
        key="back"
        type="secondary"
        onClick={ this.closePlan }
      >
        Cancel
      </Button>

      <Button
        id="_uiauto-plan-name-proceed"
        key="submit"
        type="primary"
        onClick={ this.submitPlanName }
        disabled={ !planName || planNameError || fetchingPlansList }
      >
        { !migrationPlanName ? "Proceed" : "Update" }
      </Button>
    </FlexLayout>);
  }

  render() {
    const {
      planName,
      planNameError,
      fetchingPlansList
    } = this.state;
    const {
      visible,
      afterClose,
      migrationPlanName
    } = this.props;

    let modalTitile = "New Migration Plan";
    if (migrationPlanName) {
      modalTitile = "Edit Migration Plan Name";
    }

    if (!visible) {
      return null;
    }

    return (
      <Modal
        afterClose={ afterClose }
        visible={ visible }
        title={ modalTitile }
        footer={ this.renderFooter() }
        className="edit-migration-name-modal"
        width={ 400 }
        maskClosable={ false }
        keyboard={ false }
        autoFocus={ false }
        onClose={ this.closePlan }
      >
        <StackingLayout padding="20px">
          <Loader
            overlay={ true }
            loading={ fetchingPlansList }
            tip="Please wait...">
            <form onSubmit={ this.submitPlanName }>
              <InputPlusLabel
                id="plan-name"
                label="Plan Name"
                name="planName"
                onChange={ this.onChangePlanName }
                placeholder="Enter Migration Plan Name"
                value={ planName }
                error={ !!planNameError }
                helpText={ planNameError || "" }
                autoFocus
              />
              <input type="submit" className="hidden-submit-button"/>
            </form>
          </Loader>
        </StackingLayout>
      </Modal>
    );
  }

}

export default EditMigrationNameModal;
