
import React from "react";
import { Modal, Alert, StackingLayout, FlexLayout, Title,
  Input, TextLabel, Button, RemoveIcon, Tooltip, Link } from "@nutanix-ui/prism-reactjs";
import SOURCE from "../../../RestAPI/source";
import LoaderModal from "../../presentational/loader-modal/";
import AppUtil from "../../../tools/AppUtil";
import "./VCRegistrationModal.less";


const resourceBundle = AppUtil.getI18nJSONResourceBundle();

class VCRegistrationModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      VCsWithoutCreds: props.VCsWithoutCreds,
      envFormBody: props.envFormBody,
      showWarning: false,
      providerUUID: props.providerUUID,
      togglePasswordLabel: "Show"
    };
  }


  togglePasswordField = (togglePasswordLabel) => {
    let labelValue = "";

    if (togglePasswordLabel === "Show") {
      labelValue = "Hide";
    } else {
      labelValue = "Show";
    }
    this.setState({ togglePasswordLabel: labelValue });
  }


  onChangeVCCreds = (index, property, e) => {
    const { VCsWithoutCreds } = this.state;
    VCsWithoutCreds[index][property] = e.target.value;
    this.setState({
      VCsWithoutCreds
    });
  }


  onSkipSelectedVC = (vcdetails) => {
    const { VCsWithoutCreds } = this.state;
    const selectedVCsWithoutCreds = VCsWithoutCreds.filter(function(vc) {
      return vc.IPAddress !== vcdetails.IPAddress;
    });

    this.setState({
      VCsWithoutCreds: selectedVCsWithoutCreds
    });
  }


  showSkipVCCredsIcon = (VCs, vcdetails) => {
    let showSkipVCCredsIconHTML = "";

    if (VCs.length > 1) {
      showSkipVCCredsIconHTML = (
        <Tooltip
          popupPlacement="right"
          content="Skip registration">
          <TextLabel
            className="skip-vc-registration-link"
            onClick={ () => this.onSkipSelectedVC(vcdetails) }>
            <RemoveIcon />
          </TextLabel>
        </Tooltip>
      );
    }

    return showSkipVCCredsIconHTML;
  }


  VCsWithoutCredsBlock = () => {
    const {
      VCsWithoutCreds,
      togglePasswordLabel
    } = this.state;

    const suffix = (
      <Link
        id="_uiauto-aws-toggle-secret-key"
        className="toggle-password-suffix"
        onClick={ () => this.togglePasswordField(togglePasswordLabel) }
      >
        { togglePasswordLabel }
      </Link>
    );

    return VCsWithoutCreds.map((vc, index) => {
      return (
        <StackingLayout
          itemSpacing="20px"
          padding="20px"
          key={ index }
          className="vc-creds-well">
          <FlexLayout justifyContent="space-between">
            <Title size="h3">
              <TextLabel>vCenter IP address: </TextLabel>
              { vc.IPAddress }
            </Title>
            { this.showSkipVCCredsIcon(VCsWithoutCreds, vc) }
          </FlexLayout>

          <FlexLayout itemFlexBasis="100pc">
            <Input
              id="_uiauto-vc-register-username"
              onChange={ (e) => this.onChangeVCCreds(index, "Username", e) }
              placeholder="username"
              value={ vc.Username || "" }
            />
            <Input
              id="_uiauto-vc-register-password"
              type={ resourceBundle.togglePasswordInputType[togglePasswordLabel] }
              onChange={ (e) => this.onChangeVCCreds(index, "Password", e) }
              placeholder="password"
              value={ vc.Password || "" }
              suffix={ suffix }
            />
          </FlexLayout>
        </StackingLayout>
      );
    });
  }


  getEmptyFormFields = () => {
    const {
      VCsWithoutCreds
    } = this.state;

    let emptyFormFields = false;
    VCsWithoutCreds.forEach((vc) => {
      if (!vc.Username || !vc.Password) {
        emptyFormFields = true;
      }
    });

    return emptyFormFields;
  }


  updateEnvWithVCsConfigs = (e) => {
    e.preventDefault();
    const {
      envFormBody,
      VCsWithoutCreds,
      providerUUID
    } = this.state;
    this.setState({
      showLoader: true,
      loaderText: resourceBundle.Information.generic_vc_registration_loader_msg
    });
    const RegisteredVCAccessInfo = [];
    VCsWithoutCreds.forEach((vc) => {
      RegisteredVCAccessInfo.push(vc);
    });

    envFormBody.UUID = providerUUID;
    envFormBody.Spec.AOSAccessInfo.RegisteredVCAccessInfo = RegisteredVCAccessInfo;
    SOURCE.updateSource(envFormBody, providerUUID)
      .then((response) => {
        const vcsWithoutCreds = this.props.checkRegisterdVCCredsDetails(response);
        if (vcsWithoutCreds.length) {
          this.setState({
            showLoader: false,
            loaderText: "",
            warningText: resourceBundle.Errors.generic_vc_creds_validation_error,
            showWarning: true
          });
        } else {
          this.props.onCancel();
        }
      })
      .catch((errorResponse) => {
        this.setState({
          warningText: errorResponse.Message,
          showLoader: false,
          showWarning: true,
          envFormBody
        });
      });
  }


  render() {
    const {
      showWarning,
      showLoader,
      loaderText,
      warningText
    } = this.state;
    const checkEmptyFormFields = this.getEmptyFormFields();

    const Footer = (
      <FlexLayout justifyContent="flex-end">
        <Button
          id="_uiauto-add-environment-cancel-button"
          type="secondary"
          onClick={ this.props.onCancel }
          disabled={ showLoader }>
          Cancel
        </Button>
        <Button
          id="_uiauto-add-environment-add-button"
          key="submit"
          type="primary"
          onClick={ this.updateEnvWithVCsConfigs }
          disabled={ checkEmptyFormFields || showLoader }
        >
          Save
        </Button>
      </FlexLayout>
    );


    return (
      <Modal
        visible={ true }
        title={ resourceBundle.Common.vc_registration_modal_title }
        onClose={ this.props.onCancel }
        width={ 550 }
        className="vc-creds-modal"
        footer={ Footer }
        maskClosable={ false }
      >

        <StackingLayout itemSpacing="0px">
          <Alert
            type="info"
            message={ resourceBundle.Information.esxi_vc_credentials_info }
            showCloseIcon={ false }
          />
          {
            showWarning
              ? <Alert
                type="error"
                message={ warningText }
                className="error-message-alert error-message"
              /> : ""
          }
          <form
            onSubmit={ this.updateEnvWithVCsConfigs }>
            { this.VCsWithoutCredsBlock() }
            <input type="submit" className="hidden-submit-input"/>
          </form>
          <LoaderModal
            visible={ showLoader }
            loaderDescription={ loaderText }
          />
        </StackingLayout>
      </Modal>
    );
  }

}

export default VCRegistrationModal;
