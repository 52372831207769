import React from "react";
import classNames from "classnames";
import { FullPageModal, Button, StackingLayout, FlexLayout, Alert,
  ConfirmModal, StatusIcon, EditIcon, RemoveIcon, FormItemProvider, Table,
  Checkbox, Select, Tooltip, CloseIcon, Input, MagGlassIcon, Link, TextLabel,
  AlertLayout, Paragraph } from "@nutanix-ui/prism-reactjs";
import EditIndividualVMPreparation from "../edit-individual-vm-preparation";
import AppUtil from "../../../tools/AppUtil";
import "./ManageIndividualVMPreparation.less";

const resourceBundle = AppUtil.getI18nJSONResourceBundle();
const SCRIPTS_ICON = require("../../../assets/img/icons/scripts.png");

const SelectWithLabel = FormItemProvider(Select);

class ManageIndividualVMPreparation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showEditIndividualVMPrepPopup: false,
      sourceVMs: [],
      selectedVM: {},
      VMRemovedMessage: "",
      VMUpdatedMessage: "",
      tableSourceVms: [],
      searchText: "",
      showIPConfigurationsTable: false,
      showChangeGuestPrepModal: false,
      showBypassGuestOperationsModal: false,
      changeGuestPrepVMID: "",
      bypassGuestOperationsVMID: "",
      showFailedValidations: false
    };
  }

  componentWillMount() {
    this.setState({
      sourceVMs: this.props.sourceVMs,
      guest_scripts: this.props.guest_scripts,
      showBypassOption:  this.props.showBypassOption,
      showRetainIPOption: this.props.showRetainIPOption,
      showUninstallVMwareToolOption: this.props.showUninstallVMwareToolOption,
      sourceType: this.props.sourceType,
      tableSourceVms: this.props.tableSourceVms
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      sourceVMs: nextProps.sourceVMs,
      guest_scripts: nextProps.guest_scripts,
      showBypassOption:  nextProps.showBypassOption,
      showRetainIPOption: nextProps.showRetainIPOption,
      showUninstallVMwareToolOption: nextProps.showUninstallVMwareToolOption,
      sourceType: nextProps.sourceType,
      tableSourceVms: nextProps.tableSourceVms
    }, () => {
      this.updateSelectedVM();
    });
  }

  updateSelectedVM = () => {
    const {
      selectedVM = {},
      sourceVMs
    } = this.state;

    if (selectedVM.VMName) {
      const updatedSelectedVM = sourceVMs.find((vm) => {
        return vm.UUID === selectedVM.UUID;
      }, selectedVM);
      this.setState({
        selectedVM: updatedSelectedVM
      });
    }
  }

  removeAddedVM = (UUID, vmName) => {
    let addedVMs = this.state.sourceVMs;

    if (addedVMs.length === 1) {
      this.setState({
        showConfirmVMDeletion: true
      });

      return;
    }

    addedVMs = addedVMs.filter(function(vm) {
      return vm.UUID !== UUID;
    });

    this.setState({
      sourceVMs: addedVMs,
      VMRemovedMessage: `'${vmName}' has been removed from the plan.`
    });

    this.props.updateSelectedVMs(addedVMs);
    this.props.clearNetworkMapping();
  }


  closeConfirmDeletePopUp = () => {
    this.setState({
      showConfirmVMDeletion: false
    });
  }


  redirectToDashboard = () => {
    this.setState({
      showConfirmVMDeletion: false
    });
    this.props.redirectToDashboard();
  }


  openEditIndividulaVMPrepPopup = () => {
    this.setState({
      showEditIndividualVMPrepPopup: true,
      VMUpdatedMessage: "",
      VMRemovedMessage: ""
    });
  }


  closeEditIndividulaVMPrepPopup = (selectedVM) => {
    this.setState({
      showEditIndividualVMPrepPopup: false
    }, () => {
      if (selectedVM) {
        this.updateParentVMs(selectedVM);
      }
    });
  }


  updateParentVMs = (selectedVM) => {
    const sourceVMs = this.state.sourceVMs.map((vm) => {
      if (vm.UUID === selectedVM.UUID) {
        vm = Object.assign(vm, selectedVM);
      }
      return vm;
    });

    this.setState({
      selectedVM,
      sourceVMs
    }, () => {
      this.props.updateSelectedVMs(sourceVMs);
    });
  }

  displayIPConfigurationsTable = (selectedVM) => {
    const sourceVMs = this.state.sourceVMs.map((vm) => {
      if (vm.UUID === selectedVM.UUID) {
        vm.DisplayIPConfigurations = !vm.DisplayIPConfigurations;
      }
      return vm;
    });

    this.setState({
      sourceVMs
    }, () => {
      this.props.updateSelectedVMs(sourceVMs);
    });
  }


  handleActionClick = (vm) => {
    const {
      UninstallGuestTools,
      SkipIPRetention,
      GuestPrepMode
    } = vm;
    if (GuestPrepMode === "manual") {
      this.props.getVMGuestScript(vm, UninstallGuestTools, SkipIPRetention);
    }
    this.setState({
      selectedVM: vm
    }, () => {
      this.openEditIndividulaVMPrepPopup();
    });
  }

  leftAlginColumn = (columnName) => {
    let headerClass = "";
    if (columnName === "Actions") {
      headerClass = "right";
    }
    return headerClass;
  }

  getTableHeaders = () => {
    const {
      showBypassOption,
      showUninstallVMwareToolOption,
      showRetainIPOption
    } = this.state;
    let tableHeaderHTML = "";
    const guestOpsSettingsOptions = [];

    if (showRetainIPOption) {
      guestOpsSettingsOptions.push(resourceBundle.Common.ip_configrations);
    }

    if (showBypassOption) {
      guestOpsSettingsOptions.push(resourceBundle.Common.bypass_guest_operations);
    }

    if (showUninstallVMwareToolOption) {
      guestOpsSettingsOptions.push(resourceBundle.Common.uninstall_vMware_tools);
    }

    const tableHeaderColumnName = Object.assign([], resourceBundle.tableHeaderForManageVMPrepModal.Common);

    tableHeaderColumnName.splice(2, 0, ...guestOpsSettingsOptions);

    tableHeaderHTML = tableHeaderColumnName.map((columnName, index) => {
      const leftAlignClass = this.leftAlginColumn(columnName);
      return (
        <th key={ index }
          data-text-align={ leftAlignClass }
          className={ leftAlignClass ? "small-column-vm-name action-col" : "small-column-vm-name" }>
          { columnName }
        </th>
      );
    });

    return tableHeaderHTML;
  }

  renderFooter() {
    return (<div>
      <Button
        id="_uiauto-auth-error-close"
        key="submit"
        type="primary"
        onClick={ (e) => this.props.closeIndividualVMPrep(e, true) }
      >
        Close
      </Button>
    </div>);
  }


  getVMActions = (vm, isAWSOrAzureSource) => {
    const {
      VMName,
      GuestPrepMode,
      UUID
    } = vm;
    return (
      <FlexLayout
        itemSpacing="10px"
        alignItems="right"
        className="vm-action-icons">
        <Tooltip
          content="Copy Scripts"
          popupPlacement="left"
          popupOffset="offsetByCaret"
        >
          <img
            disabled={ GuestPrepMode === "auto" }
            src={ SCRIPTS_ICON }
            className="presentation-img"
            role="presentation"
            id={ `_uiauto-indivdual-vm-details-${VMName}` }
            onClick={ () => this.handleActionClick(vm) }
          />
        </Tooltip>
        <Tooltip
          content="Edit Credentials"
          popupPlacement="left"
          popupOffset="offsetByCaret"
        >
          <EditIcon
            disabled={ GuestPrepMode === "manual" || isAWSOrAzureSource }
            id={ `_uiauto-indivdual-vm-edit-${VMName}` }
            onClick={ () => this.handleActionClick(vm) }
          />
        </Tooltip>
        <Tooltip
          content="Remove VM"
          popupPlacement="left"
          popupOffset="offsetByCaret"
        >
          <RemoveIcon
            id={ `_uiauto-indivdual-vm-delete-${VMName}` }
            onClick={ () => this.removeAddedVM(UUID, VMName) }
          />
        </Tooltip>
      </FlexLayout>
    );
  }


  sortVMsByUnmanagedFlag = (VMs, flag) => {
    return VMs.sort((a, b) => {
      return (a.AdditionalProperties[flag] > b.AdditionalProperties[flag])
        ? 1 : ((b.AdditionalProperties[flag] > a.AdditionalProperties[flag]) ? -1 : 0);
    });
  }


  getUnmanagedWarningClass = (vm, isAWSSource, isAzureSource) => {
    const {
      is_managed_by_ssm = false,
      is_managed_by_vmagent = false
    } = vm.AdditionalProperties || {};
    let warninClass = "";
    if (isAWSSource && is_managed_by_ssm !== "true") {
      warninClass = "has-row-warning";
    } else if (isAzureSource && is_managed_by_vmagent !== "true") {
      warninClass = "has-row-warning";
    }

    return warninClass;
  }

  closeChangeGuestPrepModal = () => {
    this.setState({
      showChangeGuestPrepModal: false
    });
  }

  closeBypassGuestOperationsModal = () => {
    this.setState({
      showBypassGuestOperationsModal: false
    });
  }

  confirmChangeGuestPrepModal = () => {
    const { changeGuestPrepVMID, sourceVMs } = this.state;
    const { selectedIPConfig } = this.props;
    const vms = sourceVMs.map((vm) => {
      if (vm.UUID === changeGuestPrepVMID) {
        vm.GuestPrepMode = "manual";
        vm.DisplayIPConfigurations = false;
        vm.IPConfigurations = selectedIPConfig;
      }
      return vm;
    });
    this.setState({
      showChangeGuestPrepModal: false,
      sourceVMs: vms
    }, () => {
      this.props.updateSelectedVMs(vms);
    });
  }

  confirmBypassGuestOperationsModal = () => {
    const { bypassGuestOperationsVMID, sourceVMs } = this.state;
    const vms = sourceVMs.map((vm) => {
      if (vm.UUID === bypassGuestOperationsVMID) {
        vm.bypass = !vm.bypass;
        vm.DisplayIPConfigurations = false;
      }
      return vm;
    });
    this.setState({
      showBypassGuestOperationsModal: false,
      sourceVMs: vms
    }, () => {
      this.props.updateSelectedVMs(vms);
    });
  }

  onAdvInputChange = (VMUUID, type, config) => {
    let {
      showChangeGuestPrepModal,
      changeGuestPrepVMID,
      showBypassGuestOperationsModal,
      bypassGuestOperationsVMID
    } = this.state;
    const vms = this.state.sourceVMs.map((vm) => {
      const showPopupChangeGuestMode = config === "manual" && !showChangeGuestPrepModal && vm.IPConfigurations.value === "Custom IP";
      const isIPConfigDHCP = vm.IPConfigurations && vm.IPConfigurations.value && vm.IPConfigurations.value === "DHCP";
      const showPopupBypassGuestOperation = type === "bypass" && !vm[type] && !showBypassGuestOperationsModal && !isIPConfigDHCP;
      if (vm.UUID === VMUUID) {
        if (type === "GuestPrepMode") {
          // if switching to manual mode and custom ip is active, we prompt warning first instead of changing mode.
          if (showPopupChangeGuestMode) {
            showChangeGuestPrepModal = true;
            changeGuestPrepVMID = VMUUID;
          } else {
            vm[type] = config;
          }
        } else if (type === "IPConfigurations") {
          vm[type] = config;
          if (config.value === "Custom IP") {
            vm.DisplayIPConfigurations = true;
          } else {
            vm.DisplayIPConfigurations = false;
          }
          if (config.value === "Retain Static IP") {
            vm.SkipIPRetention = false;
          } else {
            vm.SkipIPRetention = true;
          }
        } else if (showPopupBypassGuestOperation) {
          // if we check bypass guest operation, we prompt a warning first instead of checking.
          showBypassGuestOperationsModal = true;
          bypassGuestOperationsVMID = VMUUID;
        } else {
          if (type === "bypass" && vm.IPConfigurations.value === "Custom IP") {
            vm.DisplayIPConfigurations = true;
          }
          vm[type] = !vm[type];
        }
        if (type === "UninstallGuestTools" && vm.GuestPrepMode === "manual") {
          vm.updatedUninstallGuestTools = vm[type] !== this.props.planLevelUninstallTools;
        } else if (type === "SkipIPRetention" && vm.GuestPrepMode === "manual") {
          vm.updatedSkipIPRetention = vm[type] !== this.props.planLevelRetainIP;
        }
      }
      return vm;
    });
    if (this.searchText && this.searchText !== "") {
      const reg = new RegExp(this.searchText, "gi");
      const tableSourceVms = this.state.sourceVMs.map((record) => {
        const match = record.VMName.match(reg);
        if (!match) {
          return null;
        }
        return {
          ...record,
          VMName: (
            <span>
              {record.VMName.split(reg).map((text, i) => (
                i > 0 ? [<span className="highlight">{match[0]}</span>, text] : text
              ))}
            </span>
          )
        };
      }).filter(record => !!record);
      this.setState({
        tableSourceVms,
        sourceVMs: vms
      }, () => {
        this.props.updateSelectedVMs(vms);
      });
    } else {
      this.setState({
        showChangeGuestPrepModal,
        changeGuestPrepVMID,
        showBypassGuestOperationsModal,
        bypassGuestOperationsVMID,
        tableSourceVms: this.state.sourceVMs,
        sourceVMs: vms
      }, () => {
        this.props.updateSelectedVMs(vms);
      });
    }
  }

  // to handle change in VM IP Configurations
  onChangeVMTargetIPConfigurations = (event, selectedVM, sourceIPAddress, type) => {
    const {
      sourceVMs
    } = this.state;
    const selectedVMs = sourceVMs.map((vm) => {
      if (vm.UUID === selectedVM.UUID) {
        let ipConfigurationMap = [];
        ipConfigurationMap = vm.IPConfigurationsMapping.map((ipMap) => {
          if (ipMap.sourceIPAddress === sourceIPAddress) {
            if (type === "targetIPAddress") {
              ipMap.targetIPAddress = event.target.value;
            } else if (type === "dns") {
              ipMap.dns = event.target.value;
            } else if (type === "gateway") {
              ipMap.gateway = event.target.value;
            }
          }
          return ipMap;
        });
        vm.IPConfigurationsMapping = ipConfigurationMap;
        vm.IPConfigurationsData = vm.IPConfigurationsData.map((ipData) => {
          if (ipData.key === sourceIPAddress) {
            if (type === "targetIPAddress") {
              ipData.targetIPAddress = (<Input
                onChange={ (e) => this.onChangeVMTargetIPConfigurations(e, selectedVM, ipData.key, "targetIPAddress") }
                placeholder="eg: 10.0.0.1/24"
                value={ event.target.value }
              />);
            } else if (type === "gateway") {
              ipData.gateway = (<Input
                onChange={ (e) => this.onChangeVMTargetIPConfigurations(e, selectedVM, ipData.key, "gateway") }
                placeholder="eg: 10.0.0.1"
                value={ event.target.value }
              />);
            } else if (type === "dns") {
              ipData.dns = (<Input
                onChange={ (e) => this.onChangeVMTargetIPConfigurations(e, selectedVM, ipData.key, "dns") }
                placeholder="eg: 10.0.0.1, 10.0.0.2"
                value={ event.target.value }
              />);
            }
          }
          return ipData;
        });
      }
      return vm;
    });
    this.props.updateSelectedVMs(selectedVMs);
  }

  disableSaveIndividualPopup = () => {
    const {
      sourceVMs
    } = this.state;
    const {
      custom_ip
    } = resourceBundle.Common;
    return sourceVMs.some((vm) => {
      if (!vm.bypass && vm.IPConfigurations.value === custom_ip && vm.IPConfigurationsMapping) {
        return vm.IPConfigurationsMapping.some((ipMap) => {
          return ipMap.sourceIPAddress && (!ipMap.targetIPAddress || !ipMap.gateway);
        });
      }
    });
  }

  validateTargetIPField = (targetIP) => {
    // method to validate target ip address field
    const [ipAddress, prefixLength] = targetIP.split("/");
    // checking if the prefix length if number and in between 1 and 32
    const validatedPrefixLength = !isNaN(prefixLength) && parseInt(prefixLength, 10) >= 1 && parseInt(prefixLength, 10) <= 32;
    const validatedIPAddress = this.validateIPAddress(ipAddress) === "";
    return validatedIPAddress && validatedPrefixLength ? "" : targetIP;
  }

  validateDNSField = (dns) => {
    // method to validate dns
    const ipAddresses = dns.split(",");
    let validatedDns = true;
    ipAddresses.forEach((ipAddress) => {
      if (this.validateIPAddress(ipAddress) !== "") {
        validatedDns = false;
      }
    });
    return validatedDns ? "" : dns;
  }

  validateIPAddress = (ipAddress) => {
    // method to validate ip address
    if (ipAddress === "not_required") {
      return "";
    }
    const ipValues = ipAddress.split(".");
    let errorFound = false;
    if (ipValues.length === 4) {
      ipValues.forEach((val) => {
        // checking if each value is number and is between 0 and 255
        if (isNaN(val) || parseInt(val, 10) < 0 || parseInt(val, 10) > 255) {
          errorFound = true;
        }
      });
    } else {
      errorFound = true;
    }
    return errorFound ? ipAddress : "";
  }

  validateCustomIPFields = () => {
    const {
      sourceVMs
    } = this.state;
    const {
      custom_ip
    } = resourceBundle.Common;
    let failureFound = false;
    const selectedVMs = sourceVMs.map((vm) => {
      vm.VMFailedCustomIPValidations = [];
      if (!vm.bypass) {
        if (vm.IPConfigurations.value === custom_ip && vm.IPConfigurationsMapping) {
          vm.IPConfigurationsMapping.forEach((ipMap) => {
            const errorTargetIPAddress = this.validateTargetIPField(ipMap.targetIPAddress);
            if (errorTargetIPAddress !== "") {
              vm.VMFailedCustomIPValidations.push(errorTargetIPAddress);
              failureFound = true;
            }
            const errorGateway = this.validateIPAddress(ipMap.gateway);
            if (errorGateway !== "") {
              vm.VMFailedCustomIPValidations.push(errorGateway);
              failureFound = true;
            }
            const errorDns = !ipMap.dns ? "" : this.validateDNSField(ipMap.dns);
            if (errorDns !== "") {
              vm.VMFailedCustomIPValidations.push(errorDns);
              failureFound = true;
            }
          });
        }
      }
      return vm;
    });
    this.setState({
      sourceVMs: selectedVMs,
      showFailedValidations: failureFound
    }, () => {
      this.props.updateSelectedVMs(selectedVMs);
    });
    return failureFound;
  }

  // to get IP configurations HTML
  getIPConfigurationsHTML = (vm) => {
    const {
      showFailedValidations
    } = this.state;
    let ipConfigHTML = "";
    if (vm.Nics && vm.Nics.NicsInfo && vm.Nics.NicsInfo.length) {
      vm.Nics.NicsInfo.forEach((nic) => {
        if (nic.IpsConf && nic.IpsConf.length) {
          let nicGatewayNotRequired = false;
          nic.IpsConf.forEach((ipConf) => {
            if (ipConf.IpAddress) {
              let dataNotFound = true;
              let mapNotFound = true;
              let targetIPAddressValue = "";
              let gatewayValue = "";
              if (nicGatewayNotRequired) {
                gatewayValue = "not_required";
              }
              nicGatewayNotRequired = true;
              let dnsValue = "";
              vm.IPConfigurationsData.forEach((ipData) => {
                if (ipData.key === ipConf.IpAddress) {
                  dataNotFound = false;
                }
              });
              vm.IPConfigurationsMapping.forEach((ipMap) => {
                if (ipMap.sourceIPAddress === ipConf.IpAddress) {
                  mapNotFound = false;
                  targetIPAddressValue = ipMap.targetIPAddress;
                  gatewayValue = gatewayValue === "not_required" ? gatewayValue : ipMap.gateway;
                  dnsValue = ipMap.dns;
                }
              });
              const sourceIPDetails = {
                key: ipConf.IpAddress,
                network: nic.NetworkName || "",
                sourceIPAddress: ipConf.IpAddress,
                targetIPAddress: (<Input
                  onChange={ (e) => this.onChangeVMTargetIPConfigurations(e, vm, ipConf.IpAddress, "targetIPAddress") }
                  placeholder="eg: 10.0.0.1/24"
                  value={ targetIPAddressValue }
                />),
                gateway: (<Input
                  onChange={ (e) => this.onChangeVMTargetIPConfigurations(e, vm, ipConf.IpAddress, "gateway") }
                  placeholder="eg: 10.0.0.1"
                  value={ gatewayValue }
                />),
                dns: (<Input
                  onChange={ (e) => this.onChangeVMTargetIPConfigurations(e, vm, ipConf.IpAddress, "dns") }
                  placeholder="eg: 10.0.0.1, 10.0.0.2"
                  value={ dnsValue }
                />)
              };
              if (dataNotFound) {
                vm.IPConfigurationsData.push(sourceIPDetails);
              }
              if (mapNotFound) {
                vm.IPConfigurationsMapping.push({
                  sourceIPAddress:ipConf.IpAddress,
                  gateway: gatewayValue === "not_required" ? gatewayValue : ""
                });
              }
            }
          });
        }
      });
    }
    const columnData = [
      {
        title:resourceBundle.Common.source_details,
        label:resourceBundle.Common.source_details,
        key:"sourceDetails",
        group: [
          {
            title:resourceBundle.Common.network,
            label:resourceBundle.Common.network,
            key:"nic"
          },
          {
            title:resourceBundle.Common.source_details,
            label:resourceBundle.Common.source_details,
            key:"sourceIPAddress"
          }
        ]
      },
      {
        title:resourceBundle.Common.target_details,
        label:resourceBundle.Common.target_details,
        key:"targetDetails",
        group: [
          {
            title:resourceBundle.Common.target_ip_address,
            label:resourceBundle.Common.target_ip_address,
            key:"targetIPAddress"
          },
          {
            title:resourceBundle.Common.gateway,
            label:resourceBundle.Common.gateway,
            key:"gateway"
          },
          {
            title:resourceBundle.Common.dns,
            label:resourceBundle.Common.dns,
            key:"dns"
          }
        ]
      }
    ];
    let vmIPConfigurationsData = vm.IPConfigurationsData.sort(
      AppUtil.sortByProperty("network", false,
        function(net) {
          return net.toLowerCase();
        })
    );
    let previousNetwork = "";
    vmIPConfigurationsData = vmIPConfigurationsData.map((rowData) => {
      if (rowData.network === previousNetwork) {
        if (rowData.gateway.props && rowData.gateway.props.value === "not_required") {
          rowData.gateway = "";
        }
        rowData.nic = "";
      } else {
        previousNetwork = rowData.network;
        rowData.nic = rowData.network;
      }
      return rowData;
    });
    ipConfigHTML = (
      <StackingLayout>
        <TextLabel>
          IP Configurations - Static
        </TextLabel>
        <TextLabel>
          { resourceBundle.Information.static_ip_configuration_helptext }
        </TextLabel>
        <Alert
          message={ resourceBundle.Information.static_ip_configurations_dns_alert_message }
        />
        {
          showFailedValidations && vm.VMFailedCustomIPValidations.length
            ? <Alert
              type={ Alert.AlertTypes.ERROR }
              showCloseIcon={ false }
              message={ `Invalid entries : ${vm.VMFailedCustomIPValidations}` }
            />
            : ""
        }
        <Table
          columns={ columnData }
          dataSource={ vmIPConfigurationsData }
        >
        </Table>
      </StackingLayout>
    );
    return ipConfigHTML;
  }


  displayAddedVMs = () => {
    const {
      showBypassOption,
      showUninstallVMwareToolOption,
      searchText,
      showRetainIPOption
    } = this.state;
    let { sourceVMs } = this.state;
    const {
      sourceType,
      targetType,
      networkMapping
    } = this.props;
    const {
      retain_static_ip,
      dhcp,
      custom_ip
    } = resourceBundle.vm_level_ip_configurations;

    const rowData = [
      retain_static_ip,
      dhcp,
      custom_ip
    ];
    const rowDataManual = JSON.parse(JSON.stringify(rowData));
    rowDataManual[2].disabled = true;

    if (sourceVMs.length) {
      if (searchText !== "") {
        sourceVMs = sourceVMs.filter((vm) => {
          return vm.VMName.toLowerCase().includes(searchText.toLowerCase());
        });
      }
      const isAWSSource = resourceBundle.aws_providers_type.indexOf(sourceType) > -1;
      const isAzureSource = resourceBundle.azure_providers_type.indexOf(sourceType) > -1;
      const isAWSOrAzureSource = isAzureSource || isAWSSource;
      const isAHVtoAHV = resourceBundle.aos_providers_type.indexOf(sourceType) > -1 &&
      resourceBundle.aos_providers_type.indexOf(targetType) > -1;
      const isESXtoAHV = resourceBundle.vmware_providers_type.indexOf(sourceType) > -1 &&
      resourceBundle.aos_providers_type.indexOf(targetType) > -1;
      if (isAWSOrAzureSource) {
        const filterPropertyBySourceType = resourceBundle.unmanged_vm_filters[sourceType];
        sourceVMs = this.sortVMsByUnmanagedFlag(sourceVMs, filterPropertyBySourceType);
      }

      const addedVMsHTML = [];
      sourceVMs.forEach((vm, index) => {
        const vmIPRowData = JSON.parse(JSON.stringify(rowData));
        vmIPRowData[2].disabled = true;
        if (vm.Nics && vm.Nics.NicsInfo && vm.Nics.NicsInfo.length) {
          vm.Nics.NicsInfo.forEach((nic) => {
            if (nic.IpsConf && nic.IpsConf.length) {
              nic.IpsConf.forEach((ipConf) => {
                if (ipConf.IpAddress) {
                  vmIPRowData[2].disabled = false;
                }
              });
            }
          });
        }
        const rowWarningClass = isAWSOrAzureSource &&
          this.getUnmanagedWarningClass(vm, isAWSSource, isAzureSource);
        const enableReGenerteScriptWarning = (vm.updatedSkipIPRetention || vm.updatedUninstallGuestTools) && vm.GuestPrepMode === "manual";
        let unmangedWarningMsg = resourceBundle.Warnings.single_azure_unmanged_vms_warning;
        const selectedPrepMode = {
          label: resourceBundle.preparation_mode_acronyms[vm.GuestPrepMode],
          key: vm.GuestPrepMode
        };
        const isVPCTargetNetwork = networkMapping.find((netMap) => {
          if (netMap.TargetSubnetID || netMap.SubnetID) {
            return vm.Networks.find((network) => {
              return network.Name === netMap.SourceNetworkID || network.Name === netMap.SourceID;
            });
          }
        });
        if (isVPCTargetNetwork) {
          vm.SkipIPRetention = true;
          vmIPRowData[0].disabled = true;
          vmIPRowData[2].disabled = true;
          vm.IPConfigurations = dhcp;
        }

        if (isAWSSource) {
          unmangedWarningMsg = resourceBundle.Warnings.single_aws_unmanged_vms_warning;
        }
        let disabledPrepModeForUnmanagedVMs = false;
        let disabledPrepModeNGTUninstalled = false;
        const {
          is_managed_by_ssm = "true",
          is_managed_by_vmagent = "true",
          is_os_info_available = "false"
        } = vm.AdditionalProperties || {};

        if (isAzureSource || isAWSSource) {
          disabledPrepModeForUnmanagedVMs = (is_managed_by_ssm !== "true" || is_managed_by_vmagent !== "true");
        }
        if (isAHVtoAHV) {
          disabledPrepModeNGTUninstalled = (is_os_info_available !== "true");
        }
        addedVMsHTML.push((
          <tr key={ index } className={ rowWarningClass }>
            <td className="title" title={ vm.VMName || vm.VmID }>
              { rowWarningClass
                ? <span className="status-icon">
                  <StatusIcon
                    tooltipProps={ {
                      content: unmangedWarningMsg,
                      popupPlacement: "right"
                    } }
                    type="warning"
                  />
                </span> : ""
              }
              { enableReGenerteScriptWarning
                ? <StatusIcon
                  type="question"
                  tooltipProps={ {
                    content: resourceBundle.VM_Preparation.re_generate_script_vm_message,
                    popupPlacement: "right"
                  } }
                />
                : ""
              }
              { vm.VMName || vm.VmID }
            </td>
            <td className="title shared-cell action-table -editable">
              <Select
                id={ `_uiauto-indivdual-vm-guest-preparation-mode-${vm.VMName}` }
                onSelectedChange={ (event) =>
                  this.onAdvInputChange(vm.UUID, "GuestPrepMode", event.key)
                }
                rowsData={ resourceBundle.VM_Preparation.guest_prep_options }
                selectedRow={ selectedPrepMode }
                type="mini"
                disabled={ disabledPrepModeForUnmanagedVMs || disabledPrepModeNGTUninstalled }
                className={ classNames({ "disabled-input": disabledPrepModeForUnmanagedVMs }) }
                inputProps={ { appearance: "borderless" } }
              />
            </td>
            {
              showRetainIPOption &&
              <td className="title">
                <SelectWithLabel
                  id={ `_uiauto-indivdual-vm-ip-configurations-${vm.VMName}` }
                  selectedRow={ vm.IPConfigurations }
                  onSelectedChange={ (event) =>
                    this.onAdvInputChange(vm.UUID, "IPConfigurations", event)
                  }
                  disabled={ vm.GuestPrepMode === "auto" && vm.bypass }
                  rowsData={ vm.GuestPrepMode === "auto" && isESXtoAHV ? vmIPRowData : rowDataManual }
                  type="borderless"
                />
              </td>
            }
            { showBypassOption &&
              <td className="title">
                <Checkbox
                  id={ `_uiauto-indivdual-vm-bypass-guest-opration-${vm.VMName}` }
                  disabled={ vm.GuestPrepMode === "manual" }
                  onChange={ () => this.onAdvInputChange(vm.UUID, "bypass") }
                  checked={ !!vm.bypass && vm.GuestPrepMode === "auto" }
                />
              </td>
            }

            { showUninstallVMwareToolOption &&
              <td className="title">
                <Checkbox
                  id={ `_uiauto-indivdual-vm-uninstall-vmware-tools-${vm.VMName}` }
                  disabled={ !!vm.bypass && vm.GuestPrepMode === "auto" }
                  onChange={ () => this.onAdvInputChange(vm.UUID, "UninstallGuestTools") }
                  checked={ vm.UninstallGuestTools }
                />
              </td>
            }
            {
              <td className="title shared-cell -status-table">
                {
                  vm.message
                    ? <span className="status-icon -left">
                      <StatusIcon
                        type="alert"
                        tooltipProps={ {
                          content: vm.message,
                          className: "vm-tooltip",
                          popupPlacement: "right"
                        } }
                      />
                    </span>
                    : ""
                }
                <span title={ vm.VMStatus }>{ vm.VMStatus || "Pending" }</span>
              </td>
            }
            <td data-text-align="right" className="action-column">
              { this.getVMActions(vm, isAWSOrAzureSource) }
            </td>
          </tr>
        ));
        if (vm.DisplayIPConfigurations && vm.IPConfigurations.value === resourceBundle.Common.custom_ip) {
          const colSpan = showUninstallVMwareToolOption ? "7" : "6";
          addedVMsHTML.push((
            <tr key={ 100 + index } className={ rowWarningClass }>
              <td colSpan={ colSpan }>
                { this.getIPConfigurationsHTML(vm) }
              </td>
            </tr>
          ));
        }
      });
      return addedVMsHTML;
    }
  }

  onInputSearchChange = (event) => {
    this.setState({
      searchText: event.target.value
    });
  }

  clearSearch = () => {
    this.setState({
      searchText: ""
    });
  }

  displayRegenerateScriptsMessage = () => {
    let selectedVMs = [];
    selectedVMs = this.state.sourceVMs.filter(function(vm) {
      return vm.GuestPrepMode === "manual" && (vm.updatedUninstallGuestTools === true || vm.updatedSkipIPRetention);
    });
    if (selectedVMs.length) {
      return (
        <Alert
          message={ resourceBundle.VM_Preparation.re_generate_script_vm_global_message }
        />
      );
    }
    return "";
  }

  saveVMSettings = (vmDetails) => {
    const VMUpdatedMessage = `${vmDetails.VMName} has been updated.`;
    this.setState({
      VMUpdatedMessage
    }, () => {
      this.closeEditIndividulaVMPrepPopup(vmDetails);
    });
  }


  getFailedChecksDetails = () => {
    return this.props.topLevelFailedChecks.map((failedCheck, index) => {
      return <li key={ index }>{ failedCheck.Message }</li>;
    });
  }

  openMoveHelpPortal() {
    const isESXiTarget = this.props.checkESXiTarget();
    let prism_id = resourceBundle.help_prism_ids.mp_esxi_to_ahv;
    if (isESXiTarget) {
      prism_id = resourceBundle.help_prism_ids.mp_esxi_to_esxi;
    }
    const url = resourceBundle.help_url_pre + resourceBundle.move_version + resourceBundle.help_url_mid + prism_id;
    const win = window.open(url, "_blank");
    win.focus();
  }

  displayCustomIPHelpMessage = () => {
    const {
      sourceType,
      targetType
    } = this.props;
    const isESXtoAHV = resourceBundle.vmware_providers_type.indexOf(sourceType) > -1 &&
      resourceBundle.aos_providers_type.indexOf(targetType) > -1;
    if (isESXtoAHV) {
      return (
        <Alert
          message={
            <AlertLayout>
              <FlexLayout alignItems="center" itemSpacing="5px">
                <Paragraph>{ resourceBundle.VM_Preparation.custom_ip_help_prefix }</Paragraph>
                <Link
                  onClick={ () => this.openMoveHelpPortal() }
                >
                  qualified guest OS.
                </Link>
                <Paragraph>{ resourceBundle.VM_Preparation.custom_ip_help_suffix }</Paragraph>
              </FlexLayout>
            </AlertLayout>
          }
        />
      );
    }
    return "";
  }

  saveIndividualVMPrep = (e) => {
    if (!this.validateCustomIPFields()) {
      this.props.closeIndividualVMPrep(e);
    }
  }

  render() {
    const {
      closeIndividualVMPrep,
      sourceType,
      targetType
    } = this.props;
    const {
      searchText
    } = this.state;
    const {
      bypass_guest_warning,
      bypass_guest_nonESX_source_warning
    } = resourceBundle.Warnings;
    const isESXtoAHV = resourceBundle.vmware_providers_type.indexOf(sourceType) > -1 &&
      resourceBundle.aos_providers_type.indexOf(targetType) > -1;
    const bypassguestWarning = isESXtoAHV ? bypass_guest_warning : bypass_guest_nonESX_source_warning;

    return (
      <FullPageModal
        visible={ true }
        title="Manage Individual VM Preparation"
        footer={ this.renderFooter() }
        className="manage-vm-preparation"
        maskClosable={ false }
        onClose={ (e) => closeIndividualVMPrep(e, true) }
        headerActions={ [
          <CloseIcon
            onClick={ (e) => closeIndividualVMPrep(e, true) }
            key="close" />
        ] }
      >
        { this.displayRegenerateScriptsMessage() }
        { this.displayCustomIPHelpMessage() }
        <FlexLayout alignItems="center" justifyContent="center">
          <StackingLayout itemSpacing="0px">
            {
              this.state.VMRemovedMessage
                ? <Alert
                  type="error"
                  message={ this.state.VMRemovedMessage }
                /> : ""
            }

            {
              this.props.readinessFailedVMs.length
                ? <Alert
                  type="error"
                  message={ `${this.props.readinessFailedVMs.length} VM(s) are not
                  ready for migration.` }
                /> : ""
            }

            {
              this.props.topLevelFailedChecks.length
                ? <Alert
                  type="error"
                  message={ <ul>{ this.getFailedChecksDetails() }</ul> }
                /> : ""
            }
            <StackingLayout padding="20px" itemSpacing="0px">
              <Input
                appearance="borderless"
                border={ false }
                placeholder="e.g. VM Apache"
                onChange={ this.onInputSearchChange }
                value={ searchText }
                prefix={ <MagGlassIcon /> }
                suffix={ searchText
                  ? <Link onClick={ this.clearSearch }
                    type="info"
                  >
                    <CloseIcon />
                  </Link>
                  : ""
                }
              />
              <div className="ntnx ntnx-table" data-border="true" data-background="base">
                <div className="table-main-section">
                  <div className="table-header">
                    <table>
                      <thead>
                        <tr>
                          {
                            this.getTableHeaders()
                          }
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="table-body">
                    <table>
                      <tbody>
                        { this.displayAddedVMs() }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </StackingLayout>
          </StackingLayout>
        </FlexLayout>

        { this.state.showEditIndividualVMPrepPopup
          ? <EditIndividualVMPreparation
            guest_scripts={ this.state.guest_scripts }
            closeEditIndividualVMPrep={ this.closeEditIndividulaVMPrepPopup }
            sourceType={ this.props.sourceType }
            selectedVM={ this.state.selectedVM }
            saveVMSettings={ this.saveVMSettings }
            changeGuestPrepMode={ this.props.changeGuestPrepMode }
            migrationPlanID={ this.props.migrationPlanID }
            copyGuestScript={ this.props.copyGuestScript }
          />
          : ""
        }

        <ConfirmModal
          visible={ this.state.showConfirmVMDeletion }
          onCancel={ this.closeConfirmDeletePopUp }
          onConfirm={ this.redirectToDashboard }
          confirmButtonLabel="Remove"
          confirmButtonType="destructive"
          width={ 350 }
        >
          "No VMs in the migration plan. Do you want to remove the
          plan ? Your data entered in the plan will be lost."
        </ConfirmModal>

        <ConfirmModal
          visible={ this.state.showChangeGuestPrepModal }
          onCancel={ this.closeChangeGuestPrepModal }
          onConfirm={ this.confirmChangeGuestPrepModal }
          confirmButtonLabel="Confirm"
          confirmButtonProps={ {
            type: "destructive"
          } }
          width={ 350 }
        >
          { resourceBundle.Warnings.changeGuestPrepModeWarning }
        </ConfirmModal>

        <ConfirmModal
          visible={ this.state.showBypassGuestOperationsModal }
          onCancel={ this.closeBypassGuestOperationsModal }
          onConfirm={ this.confirmBypassGuestOperationsModal }
          confirmButtonLabel="Confirm"
          confirmButtonProps={ {
            type: "destructive"
          } }
          width={ 350 }
        >
          { bypassguestWarning}
        </ConfirmModal>

        <div className="migration-plan-footer">
          <FlexLayout justifyContent="flex-end">
            <Button
              id="_uiauto-close-indivdual-preparation"
              onClick={ this.saveIndividualVMPrep }
              disabled={ this.disableSaveIndividualPopup() }
            >
              Save
            </Button>
          </FlexLayout>
        </div>

      </FullPageModal>
    );
  }

}

export default ManageIndividualVMPreparation;
