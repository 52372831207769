
import React from "react";
import { StackingLayout, Link } from "@nutanix-ui/prism-reactjs";
import CommonCredentialsScriptModal from "../../presentational/common-credentials-script-modal/";
import AppUtil from "../../../tools/AppUtil";
import "./CustomVMPreprationBody.less";


const resourceBundle = AppUtil.getI18nJSONResourceBundle();


class CustomVMPreprationBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      commonCredentialsScriptModal: ""
    };
  }


  // Method which opens common credentials/scripts popup
  showCommonCredentialsScriptPopup = (flag) => {
    this.setState({
      commonCredentialsScriptModal: flag
    });
  }


  // Method which closes common credentials/scripts popup
  closeCommonCredentialsScriptPopup = () => {
    this.setState({
      commonCredentialsScriptModal: ""
    });
  }

  render() {
    const {
      sourceType,
      manualPrepModeVMs,
      automaticPrepModeVMs,
      disableScripts
    } = this.props;
    const {
      commonCredentialsScriptModal
    } = this.state;

    const isAWSOrAzureSource = resourceBundle.providers_with_regions.indexOf(sourceType) > -1;
    const autoVMPrepLinkLabel = isAWSOrAzureSource ? "" : resourceBundle.VM_Preparation.update_common_creds;

    return (
      <StackingLayout className="custom-vm-prep">
        <div className="-no-header ntnx ntnx-table" data-border="true">
          <div className="table-body">
            <table>
              <tbody>
                <tr>
                  <td>Automatic</td>
                  <td>
                    { automaticPrepModeVMs.length } VMs
                  </td>
                  <td className="action-col" data-text-align="right">
                    <Link
                      id="_uiauto-vm-preparation-update-common-creds"
                      onClick={ () => this.showCommonCredentialsScriptPopup(
                        resourceBundle.Common.auto
                      ) }
                    >
                      { autoVMPrepLinkLabel }
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Manual</td>
                  <td>
                    { manualPrepModeVMs.length } VMs
                  </td>
                  <td className="action-col" data-text-align="right">
                    <Link
                      id="_uiauto-vm-preparation-view-guest-scripts"
                      onClick={ () => this.showCommonCredentialsScriptPopup(
                        resourceBundle.Common.manual_small
                      ) }
                      disabled={ disableScripts }
                    >
                      { resourceBundle.VM_Preparation.view_guest_scripts }
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        { commonCredentialsScriptModal
          ? <CommonCredentialsScriptModal
            commonCredentialsScriptModal={ commonCredentialsScriptModal }
            onOk={ this.closeCommonCredentialsScriptPopup }
            { ...this.props }
          /> : ""
        }
      </StackingLayout>
    );
  }

}

export default CustomVMPreprationBody;
