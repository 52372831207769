import React from "react";
import classNames from "classnames";
import { Tooltip, Loader, TextLabel, Link, UnorderedList,
  FlexLayout, AlertTriangleIcon, Title, Paragraph } from "@nutanix-ui/prism-reactjs";
import MigrationStateBadge from "../migration-state-badge/";
import MigrationStatusTooltip from "../migration-status-tooltip/";
import AppUtil from "../../../tools/AppUtil";


const resourceBundle = AppUtil.getI18nJSONResourceBundle();
const plan_icon_set = {
  in_progress_icon: require("../../../assets/img/icons/inprogress.svg"),
  ready_to_cutover_icon: require("../../../assets/img/icons/cutover.svg"),
  failed_icon: require("../../../assets/img/icons/failed.svg"),
  paused_icon: require("../../../assets/img/icons/paused.svg"),
  completed_icon: require("../../../assets/img/icons/completed.svg"),
  not_started: require("../../../assets/img/icons/start.svg"),
  scheduled: require("../../../assets/img/icons/Schedule.svg"),
  cancelled: require("../../../assets/img/icons/cross_r.svg")
};

class MigrationPlanState extends React.Component {

  checkState = () => {
    const {
      plan
    } = this.props;
    const migrationPlanClass = classNames("mp-details-link", plan.mpState);
    const migrationStatesBadge = plan.VMStateCounts.ReadyToCutover ||
      plan.VMStateCounts.Failed || plan.VMStateCounts.Warning;
    const formatted_status_icon_img = plan_icon_set[
      resourceBundle.plan_status_to_icon_mappings[plan.mpState]
    ];

    if (["Failed", "In Progress", "Completed"].indexOf(plan.mpState) > -1) {
      const tooltipBody = this.planDetailedStateTooltipBody();
      const tooltipClassName = classNames(
        "migration-status-tooltip",
        { "hidden-tooltip": !tooltipBody }
      );

      return (
        <div className="migration-status-tooltip">
          <Tooltip
            className={ tooltipClassName }
            popupPlacement="bottom"
            content={ tooltipBody }
          >
            <span>
              <img
                src={ formatted_status_icon_img }
                className="status-presentation-icon"
                role="presentation"
              />

              <Link
                id={ `_uiauto-migration-plan-in-progress-${plan.index}` }
                className={ migrationPlanClass }
                onClick={ () => this.props.onClickState(plan.mpUUID) }
              >
                { plan.mpState }
              </Link>
              { migrationStatesBadge &&
                <MigrationStateBadge
                  VMStateCounts={ plan.VMStateCounts }
                />
              }
            </span>
          </Tooltip>
        </div>
      );
    } else if (["Deletion Failed", "Validation Failed"].indexOf(plan.mpState) > -1) {
      const {
        mpErrorMsgs = []
      } = plan;
      const tooltipBody = (
        <FlexLayout flexDirection="column">
          { mpErrorMsgs && mpErrorMsgs.length
            ? <span>
              <Title
                size="h3"
                className="dark-bg">
                <AlertTriangleIcon color="red"/>&nbsp;&nbsp;
                Error(s)
              </Title>
              <Paragraph
                type="secondary"
                className="dark-bg error-warning-paragraph">
                <UnorderedList
                  appearance="navigation"
                  data={ mpErrorMsgs }
                />
              </Paragraph>
            </span> : resourceBundle.Errors.failed_fetch_error_details
          }
        </FlexLayout>
      );
      return (
        <Tooltip
          popupPlacement="left"
          content={ tooltipBody }
          contentProps={ {
            style: {
              maxWidth: 350,
              maxHeight: 400,
              overflowY: "scroll"
            }
          } }
        >
          <div>
            <img
              src={ formatted_status_icon_img }
              className="status-presentation-icon"
              role="presentation"
            />
            <TextLabel type="error" className="mp-details-link Validation Failed">
              { plan.mpState }
            </TextLabel>
          </div>
        </Tooltip>
      );
    } else if (plan.mpState === "Scheduled") {
      return (
        <div>
          <img
            src={ formatted_status_icon_img }
            className="status-presentation-icon -grey"
            role="presentation"
          />

          <Link
            onClick={ () => this.props.onClickState(plan.mpUUID) }
            className={ migrationPlanClass }
          >
            {plan.mpState}
          </Link>
          <span className="plan-detailed-state">
            {`${plan.mpDate.toDateString()} ${plan.mpTime}`}
          </span>
        </div>
      );
    } else if (["Deleting Migration", "Validating Plan"].indexOf(plan.mpState) > -1) {
      return (
        <span>
          <Loader
            className="-margin-right"
            loading={ true }
            overlay={ false }
          />
          <TextLabel type="primary"> {plan.mpState} </TextLabel>
        </span>
      );
    }

    return (
      <div>
        {formatted_status_icon_img
          ? <img
            src={ formatted_status_icon_img }
            className="status-presentation-icon -grey"
            role="presentation"
          /> : ""
        }
        {plan.mpState === "Not Started"
          ? <TextLabel type="primary">{plan.mpState}</TextLabel>
          : <Link
            onClick={ () => this.props.onClickState(plan.mpUUID) }
            className="mp-details-link">
            {plan.mpState}
          </Link>
        }
      </div >
    );
  }

  planDetailedStateTooltipBody = () => {
    const {
      plan
    } = this.props;

    if (!plan.VMStateCounts.ReadyToCutover &&
      !plan.VMStateCounts.Failed && !plan.VMStateCounts.Warning) {
      // empty string return will disable the tooltip
      return "";
    }

    return (
      <MigrationStatusTooltip
        { ...plan }
        { ...this.props }
      />
    );
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    return (
      <div className="migration-status-block">
        {this.checkState()}
      </div>
    );
  }

}

export default MigrationPlanState;
