
import React from "react";
import { Modal, Button, StackingLayout } from "@nutanix-ui/prism-reactjs";
import AutoVMPreprationBody from "../../presentational/auto-vm-prepration-body/";
import ManualVMPreprationBody from "../../presentational/manual-vm-prepration-body/";
import AppUtil from "../../../tools/AppUtil";
import "../../containers/vm-preparation/VMPreparation.less";
import "./CommonCredentialsScriptModal.less";


const resourceBundle = AppUtil.getI18nJSONResourceBundle();

class CommonCredentialsScriptModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      popupTitle: resourceBundle.VM_Preparation.update_common_creds
    };
  }

  showCommonCredsBody = () => {
    return (
      <AutoVMPreprationBody
        { ...this.props }
      />
    );
  }

  showGuestScriptsBody = () => {
    return (
      <ManualVMPreprationBody
        { ...this.props }
      />
    );
  }

  render() {
    const {
      commonCredentialsScriptModal,
      onOk
    } = this.props;

    let popupTitle = this.state.popupTitle;
    let popupBody = this.showCommonCredsBody();
    if (commonCredentialsScriptModal ===
      resourceBundle.Common.manual_small) {
      popupTitle = resourceBundle.VM_Preparation.view_guest_scripts;
      popupBody = this.showGuestScriptsBody();
    }

    return (
      <Modal
        visible={ true }
        title={ popupTitle }
        onClose={ onOk }
        onOk={ onOk }
        width={ 500 }
        className="-common-scripts-credentials-popup"
        footer={
          <Button
            id="_uiauto-view-guestscripts-done"
            key="submit"
            type="primary"
            className="btn-close"
            onClick={ onOk }>
            { resourceBundle.Common.Done }
          </Button>
        }
      >
        <StackingLayout itemSpacing="20px" padding="20px">
          { popupBody }
        </StackingLayout>
      </Modal>
    );
  }

}

export default CommonCredentialsScriptModal;
