import React from "react";
import {
  Modal,
  Button,
  StackingLayout,
  FlexLayout,
  Title,
  TextLabel,
  Divider,
  FlexItem,
  Link,
  FormItemProvider,
  Alert,
  Input
} from "@nutanix-ui/prism-reactjs";
import AppUtil from "../../../tools/AppUtil";
import "./EditIndividualVMPreparation.less";
import SOURCE from "../../../RestAPI/source.js";

const InputPlusLabel = FormItemProvider(Input);

const resourceBundle = AppUtil.getI18nJSONResourceBundle();

class EditIndividualVMPreparation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      GuestPrepMode: "",
      showPassword: false,
      sourceType: "",
      prevGuestPrepMode: "",
      changedMode: false
    };
  }

  componentWillMount() {
    this.setState({
      selectedVM: this.props.selectedVM,
      GuestPrepMode: this.props.selectedVM.GuestPrepMode,
      sourceType: this.props.sourceType
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedVM: nextProps.selectedVM,
      GuestPrepMode: nextProps.selectedVM.GuestPrepMode,
      sourceType: nextProps.sourceType
    });
  }


  revertGuestPrepMode = () => {
    this.setState({
      GuestPrepMode: this.state.prevGuestPrepMode
    }, () => {
      const selectedVM = this.state.selectedVM;
      const updatedValue = this.state.prevGuestPrepMode;
      selectedVM.GuestPrepMode = updatedValue;
      this.setState({
        selectedVM
      });
    });
  }

  onAdvInputChange = (event, type) => {
    const selectedVM = this.state.selectedVM;
    let updatedValue = event.target.value;

    if (type === "UserName") {
      updatedValue = AppUtil.escapeBackslash(updatedValue);
    }
    selectedVM[type] = updatedValue;
    this.setState({
      selectedVM
    });
  }


  renderFooter() {
    return (<div>
      <Button
        id="_uiauto-edit-indivdual-done"
        key="submit"
        type="primary"
        onClick={ () => this.props.saveVMSettings(this.state.selectedVM) }
      >
        Done
      </Button>
    </div>);
  }

  toggleInputType = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  }


  displayManualPrepWarning = () => {
    let manualPrepWarning = "";
    const {
      sourceType,
      GuestPrepMode
    } = this.state;

    if (SOURCE.isSourceEsx(sourceType) && GuestPrepMode === "manual") {
      manualPrepWarning = (
        <Alert
          type="warning"
          showCloseIcon={ false }
          className="xtract-lite-message"
          message={ resourceBundle.Warnings.manual_preparation_warning }
        />
      );
    }

    return manualPrepWarning;
  }


  displayAWSAutoPrepWarning = (isAWSSource, isAzureSource) => {
    let AWSAutoPrepWarning = "";
    const {
      GuestPrepMode,
      selectedVM: {
        AdditionalProperties: {
          is_managed_by_ssm = "false",
          is_managed_by_vmagent = "false"
        }
      }
    } = this.state;

    if (GuestPrepMode === "auto" &&
      ((isAWSSource && is_managed_by_ssm !== "true") || (isAzureSource && is_managed_by_vmagent !== "true"))) {
      let helpText = resourceBundle.Warnings.single_azure_unmanged_vms_warning;
      if (isAWSSource) {
        helpText = resourceBundle.Warnings.single_aws_unmanged_vms_warning;
      }

      AWSAutoPrepWarning = (
        <Alert
          type="warning"
          showCloseIcon={ false }
          className="xtract-lite-message"
          message={ helpText }
        />
      );
    }

    return AWSAutoPrepWarning;
  }


  showCredentialsBlock = () => {
    const { showPassword } = this.state;

    return (
      <StackingLayout>
        <FlexLayout itemFlexBasis="100pc">
          <InputPlusLabel
            label="User Name"
            placeholder="Name"
            className="vm-credential-input"
            onChange={ (event) => { this.onAdvInputChange(event, "UserName"); } }
            value={ this.state.selectedVM.UserName }
          />
          <InputPlusLabel
            label="Password"
            placeholder="Enter Password"
            type={ showPassword ? "text" : "password" }
            className="vm-credential-input"
            onChange={ (event) => { this.onAdvInputChange(event, "Password"); } }
            value={ this.state.selectedVM.Password }
            suffix={
              <Link onClick={ () => this.toggleInputType() }>
                { showPassword ? "Hide" : "Show"}
              </Link>
            }
          />
        </FlexLayout>
      </StackingLayout>
    );
  }


  renderAutoPrepBody = (isAWSSource) => {
    let autoPrepBody = "";
    const isAWSOrAzureSource = resourceBundle.providers_with_regions.indexOf(this.props.sourceType) > -1;
    if (isAWSOrAzureSource) {
      if (isAWSSource) {
        autoPrepBody = resourceBundle.Information.aws_source_auto_prepration_message;
      } else {
        autoPrepBody = resourceBundle.Information.azure_source_auto_prepration_message;
      }
    } else {
      autoPrepBody = this.showCredentialsBlock();
    }

    return (
      <StackingLayout>
        { autoPrepBody }
      </StackingLayout>
    );
  }


  renderManualPrepBody = (selectedVM) => {
    let WindowsGuestScript = null;
    let LinuxGuestScript = null;
    if (!selectedVM.GuestScripts) {
      WindowsGuestScript = this.props.guest_scripts.WindowsGuestScript;
      LinuxGuestScript = this.props.guest_scripts.LinuxGuestScript;
    } else {
      WindowsGuestScript = selectedVM.GuestScripts.WindowsGuestScript;
      LinuxGuestScript = selectedVM.GuestScripts.LinuxGuestScript;
    }


    return (
      <StackingLayout>
        { WindowsGuestScript &&
          <StackingLayout>
            <Title size="h3" className="script-box-title">
              For Windows VMs
            </Title>
            <TextLabel>
              Run the following PowerShell command on the VMs that you have
              added to the migration plan. Make sure to run powershell as Administrator.
            </TextLabel>
            <StackingLayout className="script-box">
              <FlexLayout>
                <FlexItem flexGrow="1" className="flex-grow copy-script">
                  <TextLabel type="secondary">
                    { WindowsGuestScript[0] }
                  </TextLabel>
                </FlexItem>
                <StackingLayout style={ { width: "55px" } } className="copy-link-box">
                  <FlexLayout justifyContent="center" alignItems="center">
                    <Link
                      onClick={ () => this.props.copyGuestScript(WindowsGuestScript[0]) }>
                      Copy
                    </Link>
                  </FlexLayout>
                </StackingLayout>
              </FlexLayout>
            </StackingLayout>
          </StackingLayout>
        }

        { LinuxGuestScript &&
          <StackingLayout>
            <Title size="h3" className="script-box-title">
              For Linux VMs
            </Title>
            <TextLabel>
              Run the following command on the VMs that you have added to the
              migration plan. Make sure to log in to the VMs as a root user.
            </TextLabel>
            <StackingLayout className="script-box">
              <FlexLayout>
                <FlexItem flexGrow="1" className="flex-grow copy-script">
                  <TextLabel type="secondary">
                    { LinuxGuestScript[0] }
                  </TextLabel>
                </FlexItem>
                <StackingLayout style={ { width: "55px" } } className="copy-link-box">
                  <FlexLayout justifyContent="center" alignItems="center">
                    <Link
                      onClick={ () => this.props.copyGuestScript(LinuxGuestScript[0]) }>
                      Copy
                    </Link>
                  </FlexLayout>
                </StackingLayout>
              </FlexLayout>
            </StackingLayout>
          </StackingLayout>
        }
      </StackingLayout>
    );
  }

  render() {
    const {
      selectedVM,
      GuestPrepMode
    } = this.state;

    const {
      sourceType
    } = this.props;

    const isAWSSource = resourceBundle.aws_providers_type.indexOf(sourceType) > -1;
    const isAzureSource = resourceBundle.azure_providers_type.indexOf(sourceType) > -1;
    let modalTitle = "VM Preparation Credentials";
    if (GuestPrepMode !== "auto") {
      modalTitle = "VM Preparation Scripts";
    }

    // if (isAWSSource || isAzureSource) {
    //   if ((isAWSSource && selectedVM.AdditionalProperties.is_managed_by_ssm !== "true") ||
    //     (isAzureSource && selectedVM.AdditionalProperties.is_managed_by_vmagent !== "true")) {

    //     const tooltipTitle = isAWSSource ? resourceBundle.Warnings.single_aws_unmanged_vms_warning
    //       : resourceBundle.Warnings.single_azure_unmanged_vms_warning;

    //     autoPrepRadioTitle = (
    //       <span>
    //         { resourceBundle.Common.automatic }
    //         <Tooltip
    //           content={ tooltipTitle }
    //           popupPlacement="right">
    //           <span className="status-icon">
    //             <StatusIcon
    //               type="warning"
    //             />
    //           </span>
    //         </Tooltip>
    //       </span>
    //     );
    //   }
    // }

    return (
      <Modal
        afterClose={
          () => {
            if (this.state.changedMode) {
              this.revertGuestPrepMode();
            }
            this.props.closeEditIndividualVMPrep(selectedVM);
          }
        }
        visible={ true }
        title={ modalTitle }
        footer={ this.renderFooter() }
        className="edit-vm-preparation"
        maskClosable={ false }
        width={ 750 }
        onClose={ () => this.props.saveVMSettings(this.state.selectedVM) }
      >
        { this.displayManualPrepWarning() }
        { this.displayAWSAutoPrepWarning(isAWSSource, isAzureSource) }
        <StackingLayout padding="20px">
          <StackingLayout>
            <Title size="h3">{ selectedVM.VMName }</Title>
            {
              isAWSSource &&
              <TextLabel>Instance ID:  { selectedVM.VmID }</TextLabel>
            }
            <Divider isDeprecated={ false } />
          </StackingLayout>

          { GuestPrepMode === "auto"
            ? this.renderAutoPrepBody(isAWSSource) : this.renderManualPrepBody(selectedVM)
          }
        </StackingLayout>
      </Modal>
    );
  }

}

export default EditIndividualVMPreparation;
