import React from "react";
import { FormItemInput, Link, Button, Alert,
  FlexLayout, StackingLayout, PlusIcon, Table, CloseIcon, Tooltip, QuestionSquareIcon } from "@nutanix-ui/prism-reactjs";
import classNames from "classnames";
import AppUtil from "../../../tools/AppUtil";

import AWSPermissionPolicyJson from "../permission-policy-json/AWSPermissionPolicyJson";

import "./_add-update-aws-source.less";

const rb = AppUtil.getI18nJSONResourceBundle();

class AddUpdateAWSSource extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      togglePasswordLabel: "Show",
      sourceBody: this.props.sourceBody,
      showContextEntryDetails: false,
      ContextKeyName: "",
      ContextKeyType: "",
      ContextKeyValues: "",
      ContextKeyNameError: null,
      dataSource: [],
      rowKey: 0
    };
  }

  componentWillMount() {
    this.populateContextEntries();
  }

  populateContextEntries = () => {
    const { rowKey } = this.state;
    if (this.props.ContextEntries && this.props.ContextEntries.length) {
      const dataSource = [];
      this.props.ContextEntries.forEach((entry) => {
        dataSource.push(
          {
            key: rowKey,
            ContextKeyName: entry.ContextKeyName,
            ContextKeyType: entry.ContextKeyType,
            ContextKeyValues: entry.ContextKeyValues,
            action: this.deleteContextEntry(entry.ContextKeyName)
          }
        );
        this.setState({
          rowKey: rowKey + 1,
          showContextEntryDetails: true,
          dataSource
        });
      });
    }
  }

  togglePasswordField = () => {
    let labelValue = "";

    if (this.state.togglePasswordLabel === "Show") {
      labelValue = "Hide";
    } else {
      labelValue = "Show";
    }
    this.setState({ togglePasswordLabel: labelValue });
  }

  showAWSPermissionPolicy = () => {
    this.setState({
      showAWSPermissionPolicy: true
    });
  }

  closeAWSPermissionPolicy = () => {
    this.setState({
      showAWSPermissionPolicy: false
    });
  }

  showContextEntriesDetails = () => {
    this.setState({
      showContextEntryDetails: true
    });
  }

  hideContextEntriesDetails = () => {
    this.setState({
      showContextEntryDetails: false
    });
  }

  handleEntry = (event, eventType) => {
    if (eventType === "keyname") {
      this.setState({
        ContextKeyName  : event.target.value
      });
    } else if (eventType === "keytype") {
      this.setState({
        ContextKeyType  : event.target.value
      });
    } else {
      this.setState({
        ContextKeyValues  : event.target.value
      });
    }
    this.setState({
      ContextKeyNameError: null
    });
  }

  addContextEntry = () => {
    const {
      ContextKeyName,
      ContextKeyType,
      ContextKeyValues,
      rowKey,
      dataSource
    } = this.state;
    let entry_not_present = true;
    let valid_entry = true;
    let context_key_name_error = "";
    if (ContextKeyName.length < 5 || ContextKeyName.length > 256) {
      valid_entry = false;
      context_key_name_error = "ContextKeyName length should be in between 5 and 256 characters.";
    }
    dataSource.forEach((entry) => {
      if (entry.ContextKeyName.toLowerCase() === ContextKeyName.toLowerCase()) {
        entry_not_present = false;
        context_key_name_error = `ContextKeyName: ${ContextKeyName} Already Added.`;
      }
    });
    this.setState({
      ContextKeyNameError: context_key_name_error
    });
    if (entry_not_present && valid_entry) {
      dataSource.push(
        {
          key: rowKey,
          ContextKeyName,
          ContextKeyType,
          ContextKeyValues,
          action: this.deleteContextEntry(ContextKeyName)
        }
      );
      this.setState({
        ContextKeyName: "",
        ContextKeyType: "",
        ContextKeyValues: "",
        rowKey: rowKey + 1,
        dataSource
      });
      this.props.ContextEntries.push(
        {
          ContextKeyName,
          ContextKeyType,
          ContextKeyValues
        }
      );
    }
  }

  deleteContextEntry = (contextKeyName) => {
    return (
      <Link
        onClick={ () => {
          const {
            dataSource
          } = this.state;
          let deleteEntry = {};
          const dataSourceList = dataSource.filter((entry) => {
            return entry.ContextKeyName !== contextKeyName;
          });
          this.props.ContextEntries.forEach((entry) => {
            if (entry.ContextKeyName === contextKeyName) {
              deleteEntry = entry;
            }
          });
          const index = this.props.ContextEntries.indexOf(deleteEntry);
          if (index !== -1) {
            this.props.ContextEntries.splice(index, 1);
          }
          this.setState({
            dataSource: dataSourceList
          });
        } }
      >
        <CloseIcon color="black"/>
      </Link>
    );
  }

  getContextKeyValueLabel = () => {
    return (
      <FlexLayout
        itemSpacing="2px"
        alignItems="flex-end"
      >
        <p>ContextKeyValue</p>
        <Tooltip
          content={ rb.Information.aws_context_key_value_tooltip_info }
        >
          <QuestionSquareIcon/>
        </Tooltip>
      </FlexLayout>
    );
  }


  render() {
    const { formBody } = this.props;
    const {
      showContextEntryDetails,
      ContextKeyName,
      ContextKeyType,
      ContextKeyValues,
      dataSource,
      ContextKeyNameError
    } = this.state;
    const contextEntryHead = (
      <div>
        AWS Context Entries (Optional) {showContextEntryDetails ? ">" : "v"}
      </div>
    );
    const columns = [
      {
        title: "ContextKeyName",
        key: "ContextKeyName"
      },
      {
        key: "ContextKeyType",
        title: "ContextKeyType"
      },
      {
        key: "ContextKeyValues",
        title: "ContextKeyValues"
      },
      {
        key: "action"
      }
    ];

    const structure = {
      columnWidths: {
        action: "40px"
      }
    };

    return (
      <div className="aws-form-body">
        <StackingLayout
          itemSpacing="10px"
          itemDisplay="flex"
        >
          <Link
            id="_uiauto-aws-permission-policy-popup"
            onClick={ this.showAWSPermissionPolicy }
            className="aws-permission-policy permission-policy-link">
            AWS Permission Policy
          </Link>
        </StackingLayout>
        { formBody.map((formItem, index) => {
          let formInputType = formBody.inputType;
          let suffix = null;
          if (formItem.allowPasswordToggle) {
            suffix = (
              <Link
                id="_uiauto-aws-toggle-secret-key"
                className="toggle-password-suffix"
                onClick={ this.togglePasswordField }
              >
                { this.state.togglePasswordLabel }
              </Link>
            );
            if (this.state.togglePasswordLabel === "Show") {
              formInputType = "password";
            } else {
              formInputType = "text";
            }
          }
          return (
            <div
              className={ classNames({
                "last-input": index === 2
              }) }>
              <FormItemInput
                id={ formItem.key }
                label={ formItem.inputTitle }
                autoFocus={ !index }
                placeholder={ formItem.placeholder }
                type={ formInputType }
                suffix={ suffix }
                value={ formItem.value }
                onChange={ (e) => this.props.handleOnInputValueChange(e, index, formBody) }
              />
            </div>
          );
        })
        }
        <StackingLayout
          itemSpacing="10px"
        >
          <StackingLayout
            itemSpacing="10px"
            itemDisplay="flex"
          >
            <p></p>
            <p></p>
            <p></p>
            <Link
              id="_uiauto-aws-show-context-entry"
              type="info"
              className="context-entry-head"
              onClick={ showContextEntryDetails ? this.hideContextEntriesDetails : this.showContextEntriesDetails }
            >
              { contextEntryHead }
            </Link>
          </StackingLayout>
          {
            showContextEntryDetails
              ? <StackingLayout>
                {
                  ContextKeyNameError
                    ? <Alert
                      type={ Alert.AlertTypes.ERROR }
                      message={ ContextKeyNameError }
                    />
                    : ""
                }
                <FlexLayout
                  alignItems="flex-end"
                >
                  <FormItemInput
                    className="context-entry-keyname"
                    label="ContextKeyName"
                    value={ ContextKeyName }
                    onChange={ (event) => { this.handleEntry(event, "keyname"); } }
                  />
                  <FormItemInput
                    className="context-entry-keytype"
                    label="ContextKeyType"
                    value={ ContextKeyType }
                    onChange={ (event) => { this.handleEntry(event, "keytype"); } }
                  />
                  <FormItemInput
                    className="context-entry-value"
                    label={ this.getContextKeyValueLabel() }
                    value={ ContextKeyValues }
                    onChange={ (event) => { this.handleEntry(event, "keyvalue"); } }
                  />
                  <Button
                    disabled={ ContextKeyName === "" || ContextKeyType === "" || ContextKeyValues === "" }
                    onClick={ this.addContextEntry }
                    fullWidth={ true }
                  >
                    <PlusIcon size="small" />Add
                  </Button>
                </FlexLayout>
                {
                  dataSource.length !== 0
                    ? <Table
                      columns={ columns }
                      dataSource={ dataSource }
                      structure={ structure }
                    >
                    </Table>
                    : ""
                }
              </StackingLayout>
              : ""
          }
        </StackingLayout>
        <AWSPermissionPolicyJson
          visible={ this.state.showAWSPermissionPolicy }
          onCancel={ this.closeAWSPermissionPolicy }
        />

      </div>
    );
  }

}

export default AddUpdateAWSSource;
