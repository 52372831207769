import React from "react";
import moment from "moment";
import "moment-duration-format";
import { browserHistory } from "react-router";
import { FlexLayout, Title, TextLabel, Loader, Badge,
  CheckMarkIcon, CloseIcon, Tooltip, StatusIcon, StackingLayout,
  Link, OpenInNewWindowIcon, Paragraph, Progress } from "@nutanix-ui/prism-reactjs";
import AppUtil from "./AppUtil";
import conversion from "./conversion";

const resourceBundle = AppUtil.getI18nJSONResourceBundle();
const InProgressState = "In Progress";
const CompletedState = "Completed";
const FailedState = "Failed";
const PendingState = "Pending";
const SeedingDataState = "Seeding Data";
const PausedState = "Paused";
const Ready_To_Cutover_Static_Msg = "Periodic Data sync in Progress. Cutover anytime";
const Quiesce_Source_VM_Static_Msg = "Final data sync in progress";
const MigratedDataSizeInfoTitle = "Amount of data written to the target environment including any incremental changes.";
const SaticProgressDetailsStates = [
  "Quiesce Source VM",
  "Ready to Cutover"
];
const SubEventsStatesMap = {
  0: PendingState,
  1: InProgressState,
  2: FailedState,
  3: CompletedState
};

const LastPausedStateIndicator = {
  2: "Source VM preparation",
  3: "Target VM preparation",
  4: "Seeding Data",
  5: "Ready for Cutover"
};

const MigratedDataSizeColumnHeader = (
  <span>
    Migrated Data Size
    <StatusIcon
      type="question"
      className="status-tootltip"
      tooltipProps={ {
        content:MigratedDataSizeInfoTitle,
        popupPlacement:"right",
        contentProps: {
          style: {
            maxWidth: 300
          }
        }
      } }
    />
  </span>
);

const cutover_test_vm_icon = require("../assets/img/icons/cutover.svg");
const FailedIcon = require("../assets/img/icons/failed.svg");
const SeedingDataIcon = require("../assets/img/icons/migrate_icon.svg");
const completed_icon = require("../assets/img/icons/completed.svg");
const paused_icon = require("../assets/img/icons/paused.svg");
const cleanup_icon = require("../assets/img/icons/container.svg");
const in_progress_icon = require("../assets/img/icons/inprogress.svg");

const vm_share_status_icon_group = {
  Completed: completed_icon,
  Failed: FailedIcon,
  "Ready to Cutover": cutover_test_vm_icon,
  "Test VM Deployed": cutover_test_vm_icon,
  Paused: paused_icon,
  "Cleanup Failed": FailedIcon,
  "Queued for Migration": require("../assets/img/icons/Queued.svg"),
  "Scheduled for Migration": require("../assets/img/icons/Schedule.svg"),
  "Prepare Source VM": cleanup_icon,
  "Prepare Target Cluster": cleanup_icon,
  "Seeding Data": SeedingDataIcon,
  "Quiesce Source VM": require("../assets/img/icons/time.svg"),
  "Configure Target VM": require("../assets/img/icons/configure.svg"),
  "Source VM Cleanup": cleanup_icon,
  "Target Cluster Cleanup": cleanup_icon,
  Cancelled: require("../assets/img/icons/cross_r.svg"),
  "Cutover In Progress": in_progress_icon,
  Discarded: require("../assets/img/icons/discard.svg"),
  Cancelling: require("../assets/img/icons/cross_g.svg"),
  "Creating Test VM": require("../assets/img/icons/test.svg"),
  "Removing Test VM": require("../assets/img/icons/test.svg"),
  "Not Started": require("../assets/img/icons/start.svg"),
  "Incremental Data Seeding": SeedingDataIcon,
  "Prepare Source Share": SeedingDataIcon,
  "Prepare Target Share": SeedingDataIcon,
  "Cutover Last Sync": cutover_test_vm_icon,
  "Manual Sync in Progress": SeedingDataIcon,
  "Ready to Cutover with Failed Files": cutover_test_vm_icon,
  "Completed with Failed Files": completed_icon,
  "Preparing for Data Seeding": SeedingDataIcon,
  "Resume in Progress": paused_icon,
  "Source Cleanup": cleanup_icon,
  "Retrying Failed Files": in_progress_icon,
  "Pause in Progress": paused_icon,
  "Cancelling Current Iteration": SeedingDataIcon
};
const warning_icon = require("../assets/img/icons/warning_p.svg");
const warning_icon_circle = require("../assets/img/icons/warning_n.svg");


class MigrationPlanUtil extends React.Component {

  static formatMigrationPlanData(providersData, planData) {
    const formattedPlanData = planData;
    providersData.Entities.forEach((provider, index) => {
      const providerID = provider.MetaData.UUID;
      const {
        TargetInfo,
        SourceInfo
      } = formattedPlanData.Spec;
      let summary_page_provider_type = "";
      const providerType = provider.Spec.Type;

      if (providerID === SourceInfo.ProviderUUID) {
        if (provider.Spec.Type === resourceBundle.Common.VMware_ESXI_Host) {
          summary_page_provider_type = provider.Spec.Type;
        }
        formattedPlanData.Spec.source_type = providerType;
        formattedPlanData.Spec.summary_page_provider_type = summary_page_provider_type ||
          providerType;
        formattedPlanData.Spec.source_name = provider.Spec.Name;
        formattedPlanData.Spec.source_ip = provider.Spec[resourceBundle
          .provider_access_info_mapping[providerType]].IPorFQDN;
        formattedPlanData.Spec.source_id = providerID;
        formattedPlanData.Spec.CompatibleTargetTypes = provider.Spec.CompatibleTargetTypes;

        if (resourceBundle.providers_with_regions.indexOf(providerType) > -1) {
          const Provider_Properties = resourceBundle.provider_access_properties_mapping[providerType];
          const providerAvailableRegions = provider.Spec[Provider_Properties].AvailableRegions || [];
          formattedPlanData.Spec.source_regions = providerAvailableRegions;
          formattedPlanData.Spec.source_regions_with_instances = provider.Spec[Provider_Properties].RegionsWithInstances;
          providerAvailableRegions.forEach((region) => {
            if (region.ID === SourceInfo[resourceBundle.provider_attributes_mapping[providerType]].RegionID) {
              formattedPlanData.Spec.region_name = region.Name;
              formattedPlanData.Spec.region_id = region.ID;
            }
          });
        } else if (resourceBundle.aos_providers_type.indexOf(providerType) > -1) {
          const sourceClustersInfo = provider.Spec[resourceBundle
            .provider_access_properties_mapping[providerType]].Clusters;
          formattedPlanData.Spec.source_clusters = sourceClustersInfo;
          sourceClustersInfo.forEach((cluster) => {
            const sourceClusterUUID = SourceInfo[resourceBundle.provider_attributes_mapping[providerType]].ClusterUUID;
            if ([cluster.UUID, cluster.ID].indexOf(sourceClusterUUID) > -1) {
              formattedPlanData.Spec.source_cluster_id = sourceClusterUUID;
              formattedPlanData.Spec.source_cluster_name = cluster.Name;
              formattedPlanData.Spec.source_cluster_CompatibleTargetTypes = cluster.CompatibleTargetTypes;
              formattedPlanData.Spec.source_cluster_version = cluster.Version;
            }
          });
        }
      }
      if (providerID === TargetInfo.ProviderUUID) {
        let clustersInfo = null;
        formattedPlanData.Spec.target_name = provider.Spec.Name;
        formattedPlanData.Spec.target_ip = provider.Spec[resourceBundle
          .provider_access_info_mapping[providerType]].IPorFQDN;
        formattedPlanData.Spec.targetInventoryErrors = provider.Spec.InventoryErrors || [];
        if (resourceBundle.aws_providers_type.indexOf(providerType) > -1 ||
            resourceBundle.azure_providers_type.indexOf(providerType) > -1) {
          const AvailableRegions = provider.Spec[resourceBundle
            .provider_access_properties_mapping[providerType]].AvailableRegions;
          formattedPlanData.Spec.target_regions = AvailableRegions;
          formattedPlanData.Spec.target_security_groups = AvailableRegions;
          clustersInfo = AvailableRegions;
        } else {
          const AvailableClusters = provider.Spec[resourceBundle
            .provider_access_properties_mapping[providerType]].Clusters;
          clustersInfo = AvailableClusters;
          formattedPlanData.Spec.target_clusters = AvailableClusters;
        }
        if (resourceBundle.aos_providers_type.indexOf(providerType) > -1) {
          formattedPlanData.Spec.RegisteredVCAccessInfo = provider.Spec.AOSAccessInfo.RegisteredVCAccessInfo;
        }
        if (resourceBundle.common_aws_aos_providers.indexOf(providerType) > -1) {
          const targetAttrs = TargetInfo[resourceBundle.provider_attributes_mapping[providerType]];
          const targetClusterOrRegionID = targetAttrs.ClusterUUID || targetAttrs.RegionID;
          formattedPlanData.Spec.target_id = providerID;
          clustersInfo.forEach((cluster) => {
            const ClusterUUID = cluster.UUID || cluster.ID;
            if ([ClusterUUID].indexOf(targetClusterOrRegionID) > -1) {
              formattedPlanData.Spec.target_type = providerType;
              formattedPlanData.Spec.cluster_name = cluster.Name;
              formattedPlanData.Spec.cluster_networks = cluster.Networks;
              formattedPlanData.Spec.cluster_vpcs = cluster.Vpcs;
              formattedPlanData.Spec.cluster_containers = cluster.Containers;
              formattedPlanData.Spec.cluster_id = targetClusterOrRegionID;
              formattedPlanData.Spec.cluster_type = cluster.Type;
              formattedPlanData.Spec.region_networks = cluster.Networks;
              formattedPlanData.Spec.target_cluster_version = cluster.Version;
              formattedPlanData.Spec.target_pc_categories = provider.Spec[resourceBundle
                .provider_access_properties_mapping[providerType]].CategoriesMapping;
              if (resourceBundle.providers_with_regions.indexOf(providerType) > -1) {
                formattedPlanData.Spec.target_name = provider.Spec.Name;
                formattedPlanData.Spec.cluster_id = provider.MetaData.UUID;
                formattedPlanData.Spec.target_region_name = cluster.Name;
                formattedPlanData.Spec.target_availability_zone_name = targetAttrs.AvailabilityZone;
                formattedPlanData.Spec.target_region_id = cluster.ID;
                formattedPlanData.Spec.target_availability_zones_list = cluster.AvailabilityZones || [];
                formattedPlanData.Spec.target_resource_group = targetAttrs.ResourceGroup;
                formattedPlanData.Spec.target_resource_groups_list = cluster.ResourceGroups || [];
                formattedPlanData.Spec.target_agent_vpc_id = targetAttrs.AgentNetworkID;
                formattedPlanData.Spec.target_region_azure_security_groups = cluster.NetworkSecurityGroups || [];
                formattedPlanData.Spec.region_networks.forEach((VPCNetwork) => {
                  if (targetAttrs.AgentNetworkID === VPCNetwork.VpcId) {
                    formattedPlanData.Spec.target_agent_vpc_name = VPCNetwork.Name;
                  }
                });
              }
              if (cluster.Containers) {
                cluster.Containers.forEach((container) => {
                  if (container.UUID === targetAttrs.ContainerUUID) {
                    formattedPlanData.Spec.target_container_name = container.Name;
                    formattedPlanData.Spec.target_container_id = container.UUID;
                  }
                });
              }
            }
          });
        }
      }
    });

    return formattedPlanData;
  }

  static getVMStatusMsg(State) {
    switch (State) {
      // VMMigrationStateUninitialized
      case 0: return "Queued for Migration";
      // VMMigrationStateScheduled
      case 1: return "Scheduled for Migration";
      // VMMigrationStateSourcePreparation
      case 2: return "Prepare Source VM";
      // VMMigrationStateTargetPreparation
      case 3: return "Prepare Target Cluster";
      // VMMigrationStateDataSeeding
      case 4: return "Seeding Data";
      // VMMigrationStateReadyToCutover
      case 5: return "Ready to Cutover";
      // 6:VMMigrationStateCutoverLastSync
      // 9:VMMigrationStateSourceCutover
      case 6: case 9: return "Quiesce Source VM";
      // 7:VMMigrationStateCutoverTargetVMConfiguration
      // 10:VMMigrationStateTargetCutover
      case 7: case 10: return "Configure Target VM";
      // VMMigrationStatePaused
      case 8: return "Paused";
      // VMMigrationStateSourceCleanup
      case 11: return "Source VM Cleanup";
      // VMMigrationStateTargetCleanup
      case 12: return "Target Cluster Cleanup";
      // VMMigrationStateCancelled
      case 13: return "Cancelled";
      // VMMigrationStateCompleted
      case 14: return "Completed";
      // VMMigrationStateFailed
      case 15: return "Failed";
      // VMMigrationStateCutoverInProgress
      case 16: return "Cutover In Progress";
      // VMMigrationStateDiscarded
      case 17: return "Discarded";
      // VMMigrationStateAborting
      case 18: return "Cancelling";
      // 19:VMMigrationStateTestInProgress
      // 20:VMMigrationStateTestLastSync
      // 21:VMMigrationStateSourceTest
      // 22:VMMigrationStateTargetTest
      case 19: case 20: case 21: case 22: return "Creating Test VM";
      // VMMigrationStateTested
      case 23: return "Test VM Deployed";
      // 24:VMMigrationStateTargetTestCleanup
      // 25:VMMigrationStateUndoTestInProgress
      case 24: case 25: return "Removing Test VM";
      // VMMigrationStateCleanupFailed
      case 26: return "Cleanup Failed";
      default: return "Not Started";
    }
  }

  static showProgressIndicator(status) {
    switch (status) {
      case (InProgressState):
        return (
          <Loader loading={ true } />
        );
      case (CompletedState):
        return (
          <CheckMarkIcon color="green"/>
        );
      case (FailedState):
        return (
          <CloseIcon color="red" />
        );
      default:
        return "";
    }
  }

  // Function to generate HTML for subEvents/Subevents for VM and show
  // them in tooltip
  static formatSubEventsHTML(entity) {
    let subEventsHTML = null;
    let activeSubEvent = "";
    let activeSubEventStatus = "";
    if (entity.SubEvents) {
      subEventsHTML = (
        <FlexLayout flexDirection="column" itemSpacing="10px">
          {
            entity.SubEvents.map((subEvent, key) => {
              if ([1, 2].indexOf(subEvent.Status) > -1) {
                activeSubEvent = subEvent.Name;
                activeSubEventStatus = subEvent.Status;
              }
              const subEventStatus = SubEventsStatesMap[subEvent.Status];
              subEvent.formattedStatus = subEventStatus || "Pending";
              return (
                <span key={ key }>
                  <Title size="h3" className="sub-events-title-block">
                    <span className="progress-state-icon">
                      { MigrationPlanUtil.showProgressIndicator(subEvent.formattedStatus) }
                    </span>
                    <span
                      className={ `${subEvent.formattedStatus} sub-event-title` }
                      title={ subEvent.Name }>
                      {subEvent.Name}
                    </span>
                  </Title>
                  <TextLabel className={ `${subEvent.formattedStatus} sub-event-desciption` }>
                    {subEvent.Description}
                  </TextLabel>
                </span>
              );
            })
          }
        </FlexLayout>
      );
    }

    entity.subEventsHTML = subEventsHTML;
    entity.activeSubEvent = activeSubEvent;
    entity.activeSubEventStatus = activeSubEventStatus;

    return entity;
  }


  static vmProgressTableColumnData(extendedPlanDetails) {
    const COLUMNS = [
      {
        title: "VM Name",
        dataIndex: "name",
        key: "name",
        className: "title",
        sorter: (a, b) => {
          const nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          // sort alphanumeric string ascending
          return nameA.localeCompare(nameB, undefined, {
            numeric: true,
            sensitivity: "base"
          });
        },
        render(VMName, record) {
          return (
            <span
              id="_uiauto-migration-plan-vm-name"
              className="vm-name"
              title={ VMName }
            >
              {VMName}
            </span>
          );
        }
      },
      {
        title: MigratedDataSizeColumnHeader,
        dataIndex: "MigratedDataSizeInBytesKey",
        key: "MigratedDataSizeInBytesKey",
        sorter: (a, b) => {
          const sizeA = a.migratedDataSizeInBytes, sizeB = b.migratedDataSizeInBytes;
          // sort string ascending
          if (sizeA < sizeB) {
            return -1;
          }
          if (sizeA > sizeB) {
            return 1;
          }
          return 0; // default return value (no sorting)
        },
        render: (migratedDataSizeInBytes) => {
          const dataSize = migratedDataSizeInBytes
            ? conversion.unitsToSize(migratedDataSizeInBytes, 2) : "Not Available";
          return (
            <span
              title={ dataSize }
            >
              {dataSize}
            </span>
          );
        }
      },
      {
        title: "Migration Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => {
          const statusA = a.status.toLowerCase();
          const statusB = b.status.toLowerCase();
          // sort string ascending
          if (statusA < statusB) {
            return -1;
          }
          if (statusA > statusB) {
            return 1;
          }
          return 0; // default return value (no sorting)
        },
        render(migrationStatus, record, index) {
          const vm_status_icon = vm_share_status_icon_group[migrationStatus];
          const {
            ErrorMessage,
            WarningMessage,
            LastCutoverError,
            links,
            TestVmWarningMessage
          } = record;
          const showTestVMWarning = ["Test VM Deployed"].indexOf(migrationStatus) > -1 &&
            TestVmWarningMessage;
          let VMWarningMsgTooltip = "";
          if (WarningMessage || showTestVMWarning) {
            VMWarningMsgTooltip = (
              <Tooltip
                popupPlacement="right"
                contentProps={ {
                  style: {
                    maxWidth: 350
                  }
                } }
                content={ (
                  <FlexLayout flexDirection="column">
                    <span>
                      <Title
                        size="h3"
                        className="dark-bg">
                        <img
                          src={ warning_icon }
                          className="vm-progress-status-icon -post"
                          role="presentation"
                        />
                        Warning(s)
                      </Title>
                      <Paragraph
                        type="secondary"
                        className="dark-bg error-warning-paragraph">
                        {WarningMessage || TestVmWarningMessage}
                      </Paragraph>
                    </span>
                  </FlexLayout>
                ) }
              >
                <span>
                  <img
                    src={ warning_icon_circle }
                    className="vm-progress-status-icon -pre"
                    role="presentation"
                  />
                </span>
              </Tooltip>
            );
          }

          if (["Cleanup Failed", "Failed"].indexOf(migrationStatus) > -1) {
            let ErrorWarningTooltipBlockHTML = "";
            if (ErrorMessage || WarningMessage) {
              ErrorWarningTooltipBlockHTML = (
                <FlexLayout flexDirection="column">
                  {ErrorMessage &&
                    <span>
                      <Title
                        size="h3"
                        className="dark-bg">
                        <img
                          src={ vm_share_status_icon_group.Failed }
                          className="vm-progress-status-icon -post"
                          role="presentation"
                        />
                        Error(s)
                      </Title>
                      <Paragraph
                        type="secondary"
                        className="dark-bg error-warning-paragraph">
                        {ErrorMessage}
                      </Paragraph>
                    </span>
                  }
                  {WarningMessage &&
                    <span>
                      <Title
                        size="h3"
                        className="dark-bg">
                        <img
                          src={ warning_icon }
                          className="vm-progress-status-icon -post"
                          role="presentation"
                        />
                        Warning(s)
                      </Title>
                      <Paragraph
                        type="secondary"
                        className="dark-bg error-warning-paragraph">
                        {WarningMessage}
                      </Paragraph>
                    </span>
                  }
                </FlexLayout>
              );
            }
            return (
              <div className="migration-status -failed">
                <Tooltip
                  popupPlacement="right"
                  content={ ErrorWarningTooltipBlockHTML }
                  contentProps={ {
                    style: {
                      maxWidth: 350
                    }
                  } }
                >
                  <span>
                    <img
                      src={ vm_status_icon }
                      className="vm-progress-status-icon -post"
                      role="presentation"
                    />
                    <TextLabel
                      type="primary"
                      id={ `_uiauto-migration-plan-status-${index}` }
                      className="status-description"
                    >
                      {migrationStatus}
                      {WarningMessage && <img
                        src={ warning_icon_circle }
                        className="vm-progress-status-icon -pre"
                        role="presentation"
                      />}
                    </TextLabel>
                  </span>
                </Tooltip>
              </div>
            );
          } else if (migrationStatus === "Ready to Cutover" && LastCutoverError) {
            return (
              <div className="migration-status">
                <img
                  src={ vm_status_icon }
                  className="vm-progress-status-icon -post"
                  role="presentation"
                />
                <TextLabel
                  type="primary"
                  id={ `_uiauto-migration-plan-status-${index}` }
                  className="status-description"
                  title={ migrationStatus }
                >
                  {migrationStatus}
                </TextLabel>
                <span className="status-icon">
                  <StatusIcon
                    type="alert"
                    tooltipProps={ {
                      content: LastCutoverError,
                      popupPlacement: "right"
                    } }
                  />
                </span>
              </div>
            );
          } else if (["Test VM Deployed", "Ready to Cutover"].indexOf(migrationStatus) > -1) {
            return (
              <div className="migration-status">
                <img
                  src={ vm_status_icon }
                  className="vm-progress-status-icon -post"
                  role="presentation"
                />
                <TextLabel
                  type="primary"
                  id={ `_uiauto-migration-plan-status-${index}` }
                  className="status-description"
                  title="Ready to Cutover"
                >
                  Ready to Cutover
                  {VMWarningMsgTooltip}
                </TextLabel>
                {links.TestVmLink
                  ? <Link className="secondary-info"
                    target="_blank" href={ links.TestVmLink }>
                    View Test VM
                    <OpenInNewWindowIcon
                      size="small"
                      className="new-tab-icon"
                    />
                  </Link>
                  : ""
                }
              </div>
            );
          } else if (["Completed", "Paused"].indexOf(migrationStatus) > -1) {
            return (
              <div className="migration-status">
                <img
                  src={ vm_status_icon }
                  className="vm-progress-status-icon -post"
                  role="presentation"
                />
                <TextLabel
                  type="primary"
                  id={ `_uiauto-migration-plan-status-${index}` }
                >
                  { migrationStatus }
                  { VMWarningMsgTooltip }
                </TextLabel>
              </div>
            );
          }

          return (
            <div className="migration-status">
              <img
                src={ vm_status_icon }
                className="vm-progress-status-icon -post -grey"
                role="presentation"
              />
              <TextLabel
                type="primary"
                id={ `_uiauto-migration-plan-status-${index}` }
                title={ migrationStatus }
              >
                {migrationStatus}
              </TextLabel>
            </div>
          );
        }
      },
      {
        title: "Details",
        dataIndex: "time",
        key: "time",
        className: "action-col -time",
        render(percentage, record, index) {
          const {
            ETA,
            status,
            PausedStatus,
            subEventsHTML,
            links,
            displayProgress,
            activeSubEvent
          } = record;

          const isPausedState = status === PausedState;
          const progressBarStatus = isPausedState ? "warning" : "active";
          const showProgressBar = percentage && [SeedingDataState, PausedState].indexOf(status) > -1;
          let toolTipHTML = "";
          let completionColumnHTML = "-";

          const minutes = Math.floor((ETA / 60));
          let timeStampHelp = "";
          let lastPausedState = "";

          if (ETA && !isPausedState) {
            const etaTime = minutes;
            if (etaTime) {
              const tempTime = moment.duration(etaTime, "minutes")
                .format("d [day(s)] h [hour(s)] m [minute(s)]");
              timeStampHelp = `${tempTime} remaining`;
            } else {
              timeStampHelp = "Less than a minute remaining";
            }
          }

          if (isPausedState && PausedStatus.PausedTime) {
            timeStampHelp = `${moment(PausedStatus.PausedTime)
              .format("[Paused at ] h:mm a, DD/MM/YYYY")}`;
            lastPausedState = LastPausedStateIndicator[PausedStatus.State];
            completionColumnHTML = (
              <span>
                { lastPausedState &&
                  <TextLabel type="primary" className="paused-state">
                    { lastPausedState }
                  </TextLabel>
                }
                <TextLabel
                  className="estimated-cutover-time -break">
                  {timeStampHelp}
                </TextLabel>
              </span>
            );
          }

          const progressPercentageHTML = (
            <span id={ `_uiauto-progress-${index}` } className="progress-percentage">
              {["Test Success, Ready to Cutover", "Ready to Cutover"].indexOf(status) === -1
                ? <TextLabel size="small" type="primary">{percentage || "0"}%</TextLabel>
                : `Estimated Cutover Time: About ${minutes} minute(s)`
              }
            </span>
          );

          if (subEventsHTML) {
            toolTipHTML = (
              <StatusIcon
                type="question"
                className="help-tootlip-icon"
                tooltipProps={ {
                  className:"sub-events-tooltip",
                  content:subEventsHTML,
                  popupPlacement: "leftBottom",
                  theme:"light"
                } }
              />
            );
          }


          if (activeSubEvent && !showProgressBar && !links.VmLink) {
            let activeSubEventStatusIcon = "";
            if (record.activeSubEventStatus === 2) {
              activeSubEventStatusIcon = <CloseIcon color="red" />;
            } else {
              activeSubEventStatusIcon = <Loader loading={ true } />;
            }
            completionColumnHTML = (
              <div className="sub-events-status">
                {
                  SaticProgressDetailsStates.indexOf(status) < 0
                    ? <span>
                      {activeSubEventStatusIcon}

                      <TextLabel type="primary">
                        <span
                          className="sub-events-title-text"
                          title={ activeSubEvent }>
                          {activeSubEvent}
                        </span>
                        {toolTipHTML}
                      </TextLabel>
                    </span> : ""
                }
                {
                  ["Ready to Cutover"].indexOf(status) > -1
                    ? <span>
                      <TextLabel type="primary">
                        {progressPercentageHTML}
                        {toolTipHTML}
                      </TextLabel>
                      <TextLabel className="estimated-cutover-time">
                        {Ready_To_Cutover_Static_Msg}
                      </TextLabel>
                    </span> : ""
                }

                {
                  status === "Quiesce Source VM"
                    ? <TextLabel type="primary">
                      {Quiesce_Source_VM_Static_Msg}
                      {toolTipHTML}
                    </TextLabel> : ""
                }
              </div>
            );
          } else if (showProgressBar && displayProgress) {
            completionColumnHTML = (
              <div className="progress-bar">
                <Progress
                  status={ progressBarStatus }
                  percent={ percentage || "0" }
                  label={
                    <TextLabel type="primary">
                      { percentage || "0"}% { toolTipHTML }
                    </TextLabel>
                  }
                />
                <TextLabel className="estimated-time-remaining">{timeStampHelp}</TextLabel>
              </div>
            );
          } else if (links.VmLink) {
            completionColumnHTML = (
              <div className="migration-plan-links">
                <Link target="_blank" href={ links.VmLink }>View Target VM</Link>
                {toolTipHTML}
              </div>
            );
          }

          // When status is in Ready to Cutover remove progress bar and change text.
          return <div className="progress-wrapper vm-progress">
            { completionColumnHTML }
          </div>;
        }
      }
    ];

    if (extendedPlanDetails) {
      const planName = {
        title: "Migration Plan Name",
        dataIndex: "planName",
        key: "planName",
        className: "title",
        render: (name, vmData) => {
          return <Link
            className="plan-link"
            onClick={ () => MigrationPlanUtil.showPlanProgress(vmData) }
            title={ name }>
            {name}
          </Link>;
        }
      };
      COLUMNS.splice(1, 0, planName);
    }

    return COLUMNS;
  }


  static showPlanProgress(record, isFileMigration = false) {
    const planProgressPageLink = isFileMigration
      ? `/migration-progress/${record.planUUID}`
      : `/migrationprogress/${record.planUUID}`;
    browserHistory.push(planProgressPageLink);
  }


  static enableMigrationAction(actionName, selectedEntities) {
    let enableAction = true;

    if (!selectedEntities.length) {
      enableAction = false;
    }

    selectedEntities.map((selectedEntity) => {
      const selectedEntityActions = selectedEntity.Actions || [];
      if (selectedEntityActions.indexOf(actionName) !== -1 && enableAction) {
        return enableAction;
      }
      enableAction = false;
      return enableAction;
    });

    return enableAction;
  }


  static getSelectedEntitiesDetails(entities, selectedEntitiesID, key) {
    return entities.filter((entity) => {
      return selectedEntitiesID.find((entityUUID) => {
        return entity[key] === entityUUID;
      });
    });
  }

  static formatPCCategories(categories) {
    const formattedPCCategories = [];
    for (const [key, values] of Object.entries(categories)) {
      if (values.length) {
        values.forEach((value) => {
          if (value) {
            const formattedCategoryValuePair = {
              id: `${key}:${value}`,
              label: `${key}: ${value}`,
              key,
              value
            };
            formattedPCCategories.push(formattedCategoryValuePair);
          }
        });
      }
    }

    return formattedPCCategories;
  }


  // Method to format source inventory based on their roles and show warning
  // if not compatible
  static formattedSourceInventory(inventories, files = false) {
    if (!inventories.length) {
      return inventories;
    }
    const localParsedInventory = JSON.parse(JSON.stringify(inventories));
    const formattedSourceInventory = [];
    localParsedInventory.forEach((inventory) => {
      const disabledInventory = inventory.Roles &&
        inventory.Roles.indexOf("Source") === -1;
      if (disabledInventory) {
        let permissionErrorMsg = inventory.PermissionsError.ErrorMsg;
        if (files) {
          permissionErrorMsg = "This file server can not be used as source.";
        }
        inventory.disabled = true;
        inventory.subTitle = (
          <TextLabel type="info">
            { resourceBundle.provider_type[inventory.Type] }
            <StatusIcon
              className="-pre"
              type="warning"
              tooltipProps={ {
                content: permissionErrorMsg,
                popupPlacement: "right",
                contentProps: {
                  style: {
                    maxWidth: 400
                  }
                }
              } }
            />
          </TextLabel>
        );
      }
      formattedSourceInventory.push(inventory);
    });

    return formattedSourceInventory;
  }


  static filteredTargetInventory(inventories, files = false) {
    if (!inventories.length) {
      return inventories;
    }
    const localParsedInventory = JSON.parse(JSON.stringify(inventories));
    const filteredTargetInventory = localParsedInventory.filter((inventory) => {
      return inventory.Type !== "OTHER_FILES";
    });

    return filteredTargetInventory.sort(
      AppUtil.sortByProperty("title", false,
        function(title) {
          return title;
        }));
  }

  // Formats Select Input with custom layout and display
  static selectRowRenderer(rowItem) {
    return {
      rowsData: rowItem,
      rowRenderer: (row, isSelected) => {
        return (
          <FlexLayout
            alignItems="center"
            justifyContent="space-between"
          >
            <div title={ row.label }>{ row.label }</div>
            <div title={ row.subTitle }>{ row.subTitle }</div>
          </FlexLayout>
        );
      }
    };
  }

  static getSourceFileServerInventory = (selectedTarget, fileServersInventory) => {
    if (!fileServersInventory.length || !selectedTarget.UUID) {
      return [];
    }

    return fileServersInventory.filter(fileServer => {
      const spec = fileServer.Spec;
      const otherFilesAccessInfo = spec && spec.OtherFilesAccessInfo;
      const targetUUID = otherFilesAccessInfo && otherFilesAccessInfo.TargetUUID;

      return targetUUID === selectedTarget.UUID;
    }).sort(
      AppUtil.sortByProperty("title", false,
        function(title) {
          return title;
        }));
  }

  static filterSelectedSourceTargetConfig(providers, planSpec) {
    const sourceUUID = planSpec.SourceInfo.ProviderUUID;
    const targetUUID = planSpec.TargetInfo.ProviderUUID;

    const sourceConfig = providers.find(item => item.UUID === sourceUUID) || {};
    const targetConfig = providers.find(item => item.UUID === targetUUID) || {};

    const output = {
      sourceConfig,
      targetConfig
    };

    return output;
  }

  /**
   * Format an array of share data objects for display.
   *
   * @param {Object[]} shares - An array of share data objects to format.
   * @param {string} planName - The name of the migration plan.
   * @param {string} planID - The unique identifier of the migration plan.
   * @returns {Object[]} - An array of formatted share details with sub-events.
  */
  static formatShareData(shares = [], planName, planID) {
    const sharesDetails = shares.map((share) => {
      const { PausedStatus = {} } = share;
      share.formattedMigratedDataSize = conversion.unitsToSize(share.MigratedDataSizeInBytes || 0);
      share.planName = planName;
      share.planUUID = planID;
      share.formattedFilesScanned = conversion.formatLargeNumber(share.FilesScanned || 0);
      share.formattedCopiedFiles = conversion.formatLargeNumber(share.CopiedFiles || 0);
      share.formattedCopiedDirectories = conversion.formatLargeNumber(share.CopiedDirectories || 0);
      share.formattedFailedDirectories = conversion.formatLargeNumber(share.FailedDirectories || 0);
      share.formattedState = MigrationPlanUtil.getShareStatusMsg(share.State);
      share.formattedElapsedTime = share.ElapsedTime || {};
      share.ShareUniqID = `${share.SourcePath}${share.UUID}`;
      share.formattedCopiedDataSizeInBytes = conversion.unitsToSize(share.CopiedDataSizeInBytes);
      share.formattedTimeElapsedSinceLastSync = conversion.secondsToUnit(share.TimeElapsedSinceLastSync);
      share.Actions = share.Actions || [];

      const formattedLastFiveIterations = MigrationPlanUtil.formatIterations(share.IterationStatus || [], share, planID);
      share.formattedLastFiveIterations = formattedLastFiveIterations;
      share.formattedLastIterationState = "-";

      if (formattedLastFiveIterations.length >= 1) {
        const currentIterationDetails = formattedLastFiveIterations[0];
        if (["Seeding Data", "Not Started"].indexOf(currentIterationDetails.formattedIterationState) === -1) {
          share.formattedLastIterationState = currentIterationDetails.formattedIterationState;
        } else if (formattedLastFiveIterations.length >= 2) {
          share.formattedLastIterationState = formattedLastFiveIterations[1].formattedIterationState;
        }
        share.currentIterationDetails = currentIterationDetails;
      }

      if (PausedStatus.PausedTime) {
        const timeStampHelp = moment(PausedStatus.PausedTime).format("[Paused at] h:mm a, DD/MM/YYYY");
        share.formattedPausedIndicator = (
          <TextLabel className="estimated-cutover-time -break">
            {timeStampHelp}
          </TextLabel>
        );
      }

      const shareDetailsWithSubEvents = MigrationPlanUtil.formatSubEventsHTML(share);
      return shareDetailsWithSubEvents;
    });

    return sharesDetails;
  }


  static formatIterations(iterations, share, planID) {
    const formattedIterations = iterations.map((iteration) => {
      const {
        CopiedDataSizeInBytes = 0,
        CopiedFiles = 0,
        ScannedFiles = 0,
        SkippedFiles = 0,
        FailedFiles = 0,
        FailedDirectories = 0,
        ElapsedTime = {},
        State,
        StartTime,
        EndTime
      } = iteration;
      const { unix_nano_time_to_milliseconds } = resourceBundle.Constants;
      const {
        Days = "", Hours = "", Minutes = "", Seconds = ""
      } = ElapsedTime;
      let formattedStartTime = "-";
      let formattedEndTime = "-";
      let formattedElapsedTime = "-";
      const showDays = Days ? `${Days}d` : "";
      const showHours = Hours ? `${Hours}h` : "";
      const showMinutes = Minutes ? `${Minutes}m` : "";

      if (Days || Hours || Minutes || Seconds) {
        formattedElapsedTime = `${showDays} ${showHours} ${showMinutes} ${Seconds || 0}s`;
      }

      if (StartTime && StartTime > 0) {
        const startTime = moment(StartTime / unix_nano_time_to_milliseconds);
        formattedStartTime = moment(startTime).format("MMM Do, h:mm A");
      }

      if (EndTime && EndTime > 0) {
        const endTime = moment(EndTime / unix_nano_time_to_milliseconds);
        formattedEndTime = moment(endTime).format("MMM Do, h:mm A");
      }

      return {
        ...iteration,
        formattedCopiedDataSizeInBytes: conversion.unitsToSize(CopiedDataSizeInBytes),
        formattedCopiedFiles: conversion.formatLargeNumber(CopiedFiles),
        formattedFilesScanned: conversion.formatLargeNumber(ScannedFiles),
        formattedFilesFailed: conversion.formatLargeNumber(FailedFiles),
        formattedFilesSkipped: conversion.formatLargeNumber(SkippedFiles),
        formattedIterationState: MigrationPlanUtil.getShareStatusMsg(State),
        formattedTimeElapsedSinceLastSync: conversion.secondsToUnit(ElapsedTime.Seconds),
        formattedFailedDirectories: conversion.formatLargeNumber(FailedDirectories),
        SourcePath: share.SourcePath,
        TargetPath: share.TargetPath,
        formattedStartTime,
        formattedEndTime,
        formattedElapsedTime,
        shareID: share.UUID,
        showDownloadFailedFilesLink: (FailedDirectories || FailedFiles),
        planID
      };
    });

    return formattedIterations;
  }


  static getShareStatusMsg(State) {
    switch (State) {
      // FilesMigrationStateUninitialized
      case 0: return "Queued for Migration";
      // FilesMigrationStateScheduled
      case 1: return "Scheduled for Migration";
      // FilesMigrationStateSourcePreparation
      case 2: return "Prepare Source Share";
      // FilesMigrationStateTargetPreparation
      case 3: return "Prepare Target Share";
      // FilesMigrationStateDataSeeding
      case 4: return "Seeding Data";
      // FilesMigrationStateIncrementalDataSeeding
      case 5: return "Incremental Data Seeding";
      // FilesMigrationStateReadyToCutover
      case 6: return "Ready to Cutover";
      // 6:FilesMigrationStateReadyToCutoverWithFailedFiles
      case 7: return "Ready to Cutover with Failed Files";
      // FilesMigrationStatePauseInProgress
      case 8: return "Pause in Progress";
      // FilesMigrationStatePaused
      case 9: return "Paused";
      // FilesMigrationStateCutoverLastSync
      case 10: return "Cutover Last Sync";
      // FilesMigrationStateCutoverInProgress
      case 11: return "Cutover In Progress";
      // FilesMigrationStateSourceCleanup
      case 12: return "Source Cleanup";
      // FilesMigrationStateTargetCleanup
      case 13: return "Cleanup";
      // FilesMigrationStateAborting
      case 14: return "Cancelling";
      // FilesMigrationStateCancelled
      case 15: return "Cancelled";
      // FilesMigrationStateDiscarded
      case 16: return "Discarded";
      // FilesMigrationStateCompleted
      case 17: return "Completed";
      // FilesMigrationStatePartiallyCompleted
      case 18: return "Completed with Failed Files";
        // FilesMigrationStateFailed
      case 19: return "Failed";
        // FilesMigrationStateCleanupFailed
      case 20: return "Cleanup Failed";
        // FilesMigrationManualSync
      case 21: return "Manual Sync in Progress";
        // FilesMigrationStateStartDataSeeding
      case 22: return "Preparing for Data Seeding";
        // FilesMigrationStateResumeInProgress
      case 23: return "Resume in Progress";
      case 24: return "Retrying Failed Files";
      case 25: return "Cancelling Current Iteration";
      default: return "Not Started";
    }
  }

  static shareProgressTableColumnData(isDashboardView) {
    let columns = [{
      title: "Source",
      key: "SourcePath"
    }, {
      title: "Target",
      key: "TargetPath"
    }, {
      title: "Migration Plan",
      key: "planName",
      render: (name, share) => {
        return <Link
          className="plan-link"
          onClick={ () => MigrationPlanUtil.showPlanProgress(share, true) }
          title={ name }>
          { name }
        </Link>;
      }
    },
    {
      title: "File Transferred",
      key: "formattedCopiedFiles"
    }, {
      title: "Migrated Data Size",
      key: "formattedCopiedDataSizeInBytes"
    }, {
      title: "Last Sync Status",
      key: "formattedLastIterationState",
      render: (syncStatus) => {
        if (syncStatus !== "-") {
          const badge_color = resourceBundle.sync_iterations_color_coding[syncStatus];
          return (
            <Badge color={ badge_color } text={ syncStatus } />
          );
        }

        return syncStatus;
      }
    }, {
      title: "Status",
      key: "formattedState",
      render: (migrationStatus, share, index) => {
        const {
          ErrorMessage,
          WarningMessage,
          subEventsHTML = ""
        } = share;
        const status_icon = vm_share_status_icon_group[migrationStatus];
        const subEventsStatusIcon = subEventsHTML ? (
          <StatusIcon
            type="question"
            className="help-tootlip-icon"
            tooltipProps={ {
              className: "sub-events-tooltip",
              content: subEventsHTML,
              popupPlacement: "leftBottom",
              theme: "light"
            } }
          />
        ) : null;

        if (migrationStatus === "Incremental Data Seeding") {
          return (
            <TextLabel type="primary" className="status-with-sup-text">
              <img
                src={ cutover_test_vm_icon }
                className="vm-progress-status-icon -post"
                role="presentation"
              />
              Ready to Cutover { subEventsStatusIcon }

              <span className="sup-text">
                { migrationStatus }
              </span>
            </TextLabel>
          );
        }

        if (["Cleanup Failed", "Failed"].indexOf(migrationStatus) > -1) {
          let ErrorWarningTooltipBlockHTML = "";
          if (ErrorMessage || WarningMessage) {
            ErrorWarningTooltipBlockHTML = (
              <FlexLayout flexDirection="column">
                {ErrorMessage &&
                  <span>
                    <Title
                      size="h3"
                      className="dark-bg">
                      <img
                        src={ vm_share_status_icon_group.Failed }
                        className="vm-progress-status-icon -post"
                        role="presentation"
                      />
                      Error(s)
                    </Title>
                    <Paragraph
                      type="secondary"
                      className="dark-bg error-warning-paragraph">
                      {ErrorMessage}
                    </Paragraph>
                  </span>
                }
                {WarningMessage &&
                  <span>
                    <Title
                      size="h3"
                      className="dark-bg">
                      <img
                        src={ warning_icon }
                        className="vm-progress-status-icon -post"
                        role="presentation"
                      />
                      Warning(s)
                    </Title>
                    <Paragraph
                      type="secondary"
                      className="dark-bg error-warning-paragraph">
                      {WarningMessage}
                    </Paragraph>
                  </span>
                }
              </FlexLayout>
            );
          }
          return (
            <div className="migration-status -failed">
              <Tooltip
                popupPlacement="right"
                content={ ErrorWarningTooltipBlockHTML }
                contentProps={ {
                  style: {
                    maxWidth: 300
                  }
                } }
              >
                <span>
                  <img
                    src={ status_icon }
                    className="vm-progress-status-icon -post"
                    role="presentation"
                  />
                  <TextLabel
                    type="primary"
                    id={ `_uiauto-migration-plan-status-${index}` }
                    className="status-description"
                  >
                    {migrationStatus}
                    {WarningMessage && <img
                      src={ warning_icon_circle }
                      className="vm-progress-status-icon -pre"
                      role="presentation"
                    />}
                  </TextLabel>
                </span>
              </Tooltip>
            </div>
          );
        }
        return (
          <TextLabel type="primary">
            <img
              src={ status_icon }
              className="vm-progress-status-icon -post"
              role="presentation"
            />
            { migrationStatus } { subEventsStatusIcon }
          </TextLabel>
        );
      }
    }, {
      title: "Details",
      key: "formattedTimeElapsedSinceLastSync",
      render: (timeElapsed, share) => {
        const {
          formattedPausedIndicator = "",
          formattedState,
          activeSubEvent = "",
          currentIterationDetails = {}
        } = share;

        const {
          formattedFilesScanned = 0,
          formattedCopiedFiles = 0,
          formattedFilesSkipped = 0,
          formattedFilesFailed = 0
        } = currentIterationDetails;

        const createLastIterationTooltip = () => {
          return (
            <StackingLayout itemSpacing="10px">
              <Title size="h3">File Migration Statistics in Last Sync</Title>
              <FlexLayout justifyContent="space-between">
                <TextLabel type="secondary">Scanned</TextLabel>
                <TextLabel type="primary">{ formattedFilesScanned }</TextLabel>
              </FlexLayout>
              <FlexLayout justifyContent="space-between">
                <TextLabel type="secondary">Skipped</TextLabel>
                <TextLabel type="primary">{ formattedFilesSkipped }</TextLabel>
              </FlexLayout>
              <FlexLayout justifyContent="space-between">
                <TextLabel type="secondary">Copied</TextLabel>
                <TextLabel type="primary">{ formattedCopiedFiles }</TextLabel>
              </FlexLayout>
              <FlexLayout justifyContent="space-between">
                <TextLabel type="secondary">Failed</TextLabel>
                <TextLabel type="error">{ formattedFilesFailed }</TextLabel>
              </FlexLayout>
            </StackingLayout>
          );
        };

        const createTooltip = (content, tooltipTriggerBody) => (
          <Tooltip
            oldTooltip={ false }
            appearance="secondary"
            content={ content }
            placement="left"
            contentProps={ {
              style: {
                maxWidth: 300
              }
            } }
          >
            { tooltipTriggerBody }
          </Tooltip>
        );

        if (formattedPausedIndicator) {
          return formattedPausedIndicator;
        }

        if (formattedFilesFailed && formattedFilesFailed !== "-") {
          return createTooltip(createLastIterationTooltip(), (
            <Badge color="red" count={ formattedFilesFailed } />
          ));
        }

        if (["Seeding Data", "Incremental Data Seeding"].includes(formattedState)) {
          const copyingDataMsg = formattedState === "Incremental Data Seeding" ? "Copying Changes" : "Scanning and Copying Data";
          return createTooltip(createLastIterationTooltip(), (
            <TextLabel type="trigger">{ copyingDataMsg }</TextLabel>
          ));
        }

        if (activeSubEvent && ["Ready to Cutover", "Ready to Cutover with Failed Files", "Completed with Failed Files"].includes(formattedState)) {
          return createTooltip(createLastIterationTooltip(), (
            <TextLabel type="trigger">{ activeSubEvent }</TextLabel>
          ));
        }

        return "-";
      }
    }];

    if (!isDashboardView) {
      columns = columns.filter(column => {
        return column.key !== "planName";
      });
    }

    return columns;
  }

}

export default MigrationPlanUtil;
