/* global window */
import $http from "../api/Request-Interface/Request";
import { LOGIN } from "../data/DataURLConstants";


// Function which sets default creds to fetch initial creds
export function SetDefaultToken() {
  const formBody = {
    Spec: {
      username: "nutanix",
      password: "nutanix/4u"
    }
  };

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };

  return $http.post(LOGIN.loginV2, formBody, headers)
    .then((response) => {
      const token = response.Status.Token;
      sessionStorage.setItem("token", token);
      localStorage.setItem("token", token);
    });
}

