
import React from "react";
import { ConfirmModal, TextLabel, StackingLayout,
  Radio, FlexLayout } from "@nutanix-ui/prism-reactjs";
import classNames from "classnames";
import "./MigrationTypeSelectionModal.less";

const LOCALSTORAGE = window.localStorage;
const FILES_ICON = require("../../../assets/img/icons/files_migration.svg");
const VM_ICON = require("../../../assets/img/icons/vm_migration.svg");


class MigrationTypeSelectionModal extends React.Component {

  constructor(props) {
    const localMigType = LOCALSTORAGE.getItem("migrationType");
    const migrationType = ["Files", "VM"].indexOf(localMigType) > -1 ? localMigType : "VM";
    super(props);
    this.state = {
      migrationType
    };
  }

  handleOnMigrationTypeClick = (value) => {
    this.setState({
      migrationType: value
    });
  }

  render() {
    const {
      migrationType
    } = this.state;

    const {
      onOk,
      visible
    } = this.props;

    return (
      <ConfirmModal
        visible={ visible }
        onConfirm={ () => onOk(migrationType) }
        width={ 420 }
        className="migration-type-selection-modal"
        keyboard={ false }
        header="Select Migration Type"
        confirmButtonLabel="Continue"
        id="_uiauto-Migration-Type-Modal"
      >
        <StackingLayout itemSpacing="15px" className="main-container">
          <FlexLayout itemFlexBasis="100pc">
            <StackingLayout
              onClick={ () => this.handleOnMigrationTypeClick("VM") }
              className={ classNames("radio-selection-block",
                { active: migrationType === "VM" }) }>
              <Radio
                name="VM"
                value="VM"
                checked={ migrationType === "VM" }
                id="_uiauto-VM-Migration"
              />
              <img src={ VM_ICON } className="presentation-img" role="presentation" />
              <TextLabel> VM </TextLabel>
            </StackingLayout>
            <StackingLayout
              onClick={ () => this.handleOnMigrationTypeClick("Files") }
              className={ classNames("radio-selection-block",
                { active: migrationType === "Files" }) }
            >
              <Radio
                name="Files"
                value="Files"
                checked={ migrationType === "Files" }
                id="_uiauto-Files-Migration"
              />
              <img src={ FILES_ICON } className="presentation-img" role="presentation" />
              <TextLabel> Files </TextLabel>
            </StackingLayout>
          </FlexLayout>
        </StackingLayout>
      </ConfirmModal>
    );
  }

}

export default MigrationTypeSelectionModal;
