import { MIGRATION_PLAN, SOURCES } from "../data/DataURLConstants";
import FetchUtil from "../tools/FetchUtil";
import Request from "../api/Request-Interface/Request";


// TODO: Refactor Migration.js such that all API Requests are abstracted out
// into separate modules.
const MIGRATION = {
  createMigrationPlan(body) {
    return Request.post(MIGRATION_PLAN.plansV2, body, FetchUtil.headers());
  },

  updateMigrationPlan(body, id) {
    return Request.put(`${MIGRATION_PLAN.plansV2}/${id}`, body, FetchUtil.headers());
  },

  // Get migration plan with UUID of migration spec.
  getMigrationPlan(id) {
    return Request.get(`${MIGRATION_PLAN.plansV2}/${id}`, FetchUtil.headers());
  },

  getAllMigrationPlanSummary() {
    return Request.get(`${MIGRATION_PLAN.url}/summary`, FetchUtil.headers());
  },

  // Get migration plan summary of specific plan.
  getMigrationPlanSummary(id) {
    return Request.get(`${MIGRATION_PLAN.url}/${id}/summary`, FetchUtil.headers());
  },

  validate(body) {
    return Request.post(MIGRATION_PLAN.validate, body, FetchUtil.headers());
  },

  validateCreds(credsBody, planID) {
    return Request.post(`${MIGRATION_PLAN.plansV2}/${planID}/prepare`, credsBody, FetchUtil.headers());
  },

  checkVMReadiness(body, planID) {
    return Request.post(`${MIGRATION_PLAN.plansV2}/${planID}/readiness`, body, FetchUtil.headers());
  },

  installGuestTools(body, id) {
    return Request.post(`${SOURCES.url}/${id}/install_guest_tools`, body, FetchUtil.headers());
  },

  prePrepareMigrationPlan(planID, body) {
    return Request.post(`${MIGRATION_PLAN.plansV2}/${planID}/prepare`, body, FetchUtil.headers());
  },

  rePrepareMigraitonPlan(planID, uninstallTools, skipIP) {
    return Request.post(`${MIGRATION_PLAN.plansV2}/${planID}/prepare?SkipIPRetention=${skipIP}&UninstallGuestTools=${uninstallTools}`, null, FetchUtil.headers());
  },

  // Get migration plan with UUID of migration spec.
  getMigrationPlanV1(id) {
    return Request.get(`${MIGRATION_PLAN.url}/${id}`, FetchUtil.headers());
  },

  triggerMigrationWorkloadAction(planID, workloadID, actionType) {
    const formData = {
      Spec: {
        Action: actionType
      }
    };
    return Request.post(`${MIGRATION_PLAN.plansV2}/${planID}/workloads/${workloadID}/action`, formData,
      FetchUtil.headers());
  },

  getPlanWorkLoads(planID) {
    return Request.post(`${MIGRATION_PLAN.plansV2}/${planID}/workloads/list`, null, FetchUtil.headers());
  },

  deleteMigrationPlan(planID) {
    return Request.delete(`${MIGRATION_PLAN.plansV2}/${planID}`, null, FetchUtil.headers());
  },

  // Get all migration plans VMs details
  getAllMigrationPlansVMsDetails(entityType = "VM") {
    const formData = {
      EntityType: entityType
    };
    return Request.post(`${MIGRATION_PLAN.plansV2}/list?IncludeVMDetails=true`, formData, FetchUtil.headers());
  },

  // Bulk VMs migration action
  triggerBulkWorkloadAction(formData) {
    return Request.post(`${MIGRATION_PLAN.workloadAction}`, formData,
      FetchUtil.headers());
  },

  // Get all migration events details
  getAllEventsDetails(formData) {
    return Request.post("/move/v2/events", formData, FetchUtil.headers());
  },

  getVMProperties(planID, vmID) {
    return Request.get(`${MIGRATION_PLAN.plansV2}/${planID}/workloads/${vmID}/custom`,
      FetchUtil.headers());
  },

  refreshVMProperties(planID, vmID) {
    return Request.post(`${MIGRATION_PLAN.plansV2}/${planID}/workloads/${vmID}/refresh`, {},
      FetchUtil.headers());
  },

  updateVMProperties(planID, vmID, requestBody) {
    return Request.put(`${MIGRATION_PLAN.plansV2}/${planID}/workloads/${vmID}/custom`, requestBody,
      FetchUtil.headers());
  },

  fetchPlanShareMappingStatus(planID) {
    return Request.get(`${MIGRATION_PLAN.plansV2}/${planID}`,
      FetchUtil.headers());
  },

  // Get list of all migration plans
  getAllPlans(entityType = "VM") {
    const formData = {
      EntityType: entityType
    };
    return Request.post(`${MIGRATION_PLAN.plansListV2}`, formData,
      FetchUtil.headers());
  }
};

export default MIGRATION;
