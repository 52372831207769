import conversion from "../../../tools/conversion.js";

const powerStateMap = {
  PoweredOff : "Off",
  PoweredOn : "On",
  Saved : "Saved",
  Suspended : "Suspended",
  NotAvailable: "Not Available"
};

let alreadyMigratingVMs = null;
let alreadyMigratingShares = null;

class FormatSourceTable {

  static formatVM(tableData, tableType) {
    alreadyMigratingVMs = 0;
    const not_available = "Not Available";
    let formattedSourceVMsTable = [];
    formattedSourceVMsTable = tableData.map((vm) => {
      // flattening the categories
      vm.Categories = FormatSourceTable.flattenCategories(vm.Categories || []);
      // convert mb values to bytes
      vm.MemoryInBytes = vm.MemoryMB && vm.MemoryMB * 1048576;
      vm.MomoryInUnit = vm.MemoryInBytes && conversion.unitsToSize(vm.MemoryInBytes);
      vm.StorageUsedInBytes = vm.StorageUsedInBytes && conversion.unitsToSize(vm.StorageUsedInBytes, 2);
      vm.StorageAllocatedInGigaBytes = vm.StorageAllocatedInBytes &&
        conversion.convertByteToGigabyte(vm.StorageAllocatedInBytes);
      if (vm.PowerState) {
        vm.PowerState = powerStateMap[vm.PowerState];
      }
      vm.UUID = vm.VMUuid;
      vm.vm_title = vm.VMName;

      vm.VMReference = {
        UUID: vm.VMUuid,
        VMID: vm.VmID
      };

      const {
        AdditionalProperties = {}
      } = vm;

      if (AdditionalProperties.AlreadyMigratingUsing) {
        alreadyMigratingVMs += 1;
      }

      if (tableType === "AWS_EC2") {
        vm.vpc = vm.Networks[0] && vm.Networks[0].Name;
        vm.OS = vm.OSType;

        if (AdditionalProperties.instance_type) {
          vm.aws_vm_type = vm.AdditionalProperties.instance_type;
        }

        if (!vm.OSType) {
          vm.OS = not_available;
        }
      }

      if (tableType === "AZURE_VMS") {
        if (AdditionalProperties.instance_type) {
          vm.instance_type = AdditionalProperties.instance_type.replace(/_/g, " ");
          vm.resource_group = AdditionalProperties.resource_group;
          vm.vm_agent_version = AdditionalProperties.vm_agent_version;
        }
        vm.generation = AdditionalProperties.generation && `V${AdditionalProperties.generation}`;
      }

      return vm;
    });

    return formattedSourceVMsTable;
  }

  static formatShare(tableData, type) {
    alreadyMigratingShares = 0;
    let formattedSharesTableData = [];
    formattedSharesTableData = tableData.map((share, index) => {
      const shareUniqKey = share.ShareUuid + index;
      const { ProtocolTypes = [] } = share;
      const primaryProtocol = ProtocolTypes[0];
      share.name = share.ShareName;
      share.key = shareUniqKey;
      share.id = shareUniqKey;
      share.primaryProtocol = primaryProtocol || "";
      share.supportedProtocols = ProtocolTypes.map((protocol) => {
        return {
          label: protocol,
          key: protocol
        };
      });
      if (type === "source") {
        let allAvailableProtocols = "";
        if (ProtocolTypes.length === 2) {
          allAvailableProtocols = ProtocolTypes.join("/");
        }
        share.allAvailableProtocols = allAvailableProtocols;
        share.SourcePath = share.ShareName;
      } else {
        share.TargetPath = share.ShareName;
      }

      return share;
    });

    return formattedSharesTableData;
  }


  static getAlreadyMigratingVMs() {
    return alreadyMigratingVMs;
  }

  static getAlreadyMigratingShares() {
    return alreadyMigratingShares;
  }


  /**
   * Formats an object with categories into an array of formatted category strings.
   * @param {object} input - The input object to format.
   * @returns {array} - An array of formatted category strings.
  */
  static flattenCategories(categories) {
    return Object.entries(categories).flatMap(([category, items]) =>
      items.map(item => `${category}: ${item}`)
    );
  }


  static filterDetailsWithFormattedCategories(filterData) {
    if (!filterData.hasOwnProperty("Categories")) {
    // If the filterData object does not have a "Categories" key,
    // simply return the filterData object
      return filterData;
    }

    const { Categories, ...otherFilters } = filterData;
    // This function is modified to format the items under the Categories property
    // in the format of an array of objects that contain an "id" and "label" property.
    // This is done so Select component which expects an array of objects with an "id"
    // and "label" property.
    const categoriesTags = [];
    for (const [key, value] of Object.entries(Categories.Items)) {
      for (const item of value) {
        const tag = {
          id: `${key}: ${item}`,
          label: `${key}: ${item}`
        };
        // Check if the tag already exists in the array before adding it
        if (!categoriesTags.some((existingTag) => existingTag.id === tag.id)) {
          categoriesTags.push(tag);
        }
      }
    }

    return {
      ...otherFilters,
      Categories: {
        FilterName: Categories.FilterName,
        Items: categoriesTags
      }
    };
  }


  /**
 * Filters the given raw list based on the provided filter object.
 * @param {Array} sourceVMs - The raw list of items to filter.
 * @param {Object} selectedFilters - The filter object that specifies the filter criteria.
 * @returns {Array} - The filtered list of VMs.
 */
  static multiOptionVMFilter(sourceVMs, selectedFilters) {
    return sourceVMs.filter((vm) => {
    // Check if every key in the filter object matches with the corresponding property in the vm
      return Object.keys(selectedFilters).every((key) => {
        const filterValues = selectedFilters[key];
        // Convert the vm property to an array if it's not already one
        if (key in vm && key !== "SourceNetwork") {
          const vmValues = Array.isArray(vm[key]) ? vm[key] : [vm[key]];
          // Check if at least one of the filter values matches with the vm property value
          return vmValues.some((val) => filterValues.includes(val));
        } else if (key === "SourceNetwork" && vm.Networks && vm.Networks.length) {
          const sourceVMNetworks = vm.Networks.map((network) => {
            return network.Name;
          });
          return sourceVMNetworks.some((val) => filterValues.includes(val));
        }
        // If the vm property doesn't exist, return false
        return false;
      });
    });
  }

}

export default FormatSourceTable;
